import { applyMaskAmount, applyMaskAmountComdinheiro, applyMaskCnpj, getMonthName, getShortMonthName, getTitleCod, isValidCnpj, justNumbers, parseFloatComdinheiro } from '../components/utils/utils';
import API from './config'
import { REQ_COMDINHEIRO_TYPE, ReqsComdinheiroAPI } from './reqs_comdinheiro';

const ND = 'nd';

//Os cnpjs devem ser retornados no formato
//cnpj1+cnpj2+cnpj3...
function normalizeCnpjsToUrl(cnpjs) {

    if (!Array.isArray(cnpjs)) {
        return justNumbers(cnpjs);
    }

    let returnString = ""

    cnpjs.forEach(cnpj => {
        returnString += justNumbers(cnpj) + "+";
    });

    return returnString.slice(0, -1);

}

function normalizeBenchmarksToUrl(benchmarks) {

    //console.log('normalizeBenchmarksToUrl: ', benchmarks);

    let returnString = ""
    benchmarks.forEach(bench => {

        const comdinheiroName = bench.comdinheiro_name.includes("+") ? bench.comdinheiro_name.replace(/\+/g, "%BE") : bench.comdinheiro_name;
        returnString += comdinheiroName + "+";

    });

    return returnString.slice(0, -1);

}

function formatBenchmarkUrl(bench) {

    //Trocando + por %BE
    let finalText = bench.replace('+', '%BE');
    return finalText;
}

function normalizeSimpleBenchmarksToUrl(benchmarks) {


    let returnString = ""
    benchmarks.forEach(bench => {
        returnString += formatBenchmarkUrl(bench) + "+";
    });

    return returnString.slice(0, -1);

}

function normalizeCnpjsToUrlCotas(cnpjsAndFundIds) {

    if (!Array.isArray(cnpjsAndFundIds)) {
        //return justNumbers(cnpjsAndFundIds.cnpj);
        return cnpjsAndFundIds.cnpj;
    }

    let returnString = ""

    cnpjsAndFundIds.forEach(cnpjAndFundId => {

        //let justNumbersCnpj = justNumbers(cnpjAndFundId.cnpj);
        let justNumbersCnpj = cnpjAndFundId.cnpj;
        returnString += justNumbersCnpj + "+" + justNumbersCnpj + ".patrimonio" + "+";

    });

    return returnString.slice(0, -1);

}

function buildConfigComdinheiro(method, url) {

    var config = {
        method: method,
        url: 'https://www.comdinheiro.com.br/Clientes/API/EndPoint001.php?code=import_data',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;'
        },
        data: "username=uno.api&password=uno.api&format=json2&URL=" + encodeURIComponent(url)
    };

    return config;
}

function formatResponseInfosFundComdinehiroX(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }


    let listInfos = [];

    infos.forEach(infos => {

        let values = Object.values(infos);
        listInfos.push(!Array.isArray(values[1]) ? values[1] : null);

    });

    return {

        "cnpj": listInfos[0],
        "gestor": listInfos[1],
        "administrador": listInfos[2],
        "taxa_adm": listInfos[3],
        "taxa_adm_max": listInfos[4],
        "benchmark_comdinheiro": listInfos[5],
        "fund_init": listInfos[6],
        "fund_pl": listInfos[7],
        "aplicacao_maxima": listInfos[8],
        "aplicacao_minima": listInfos[9],
        "saldo_minimo": listInfos[10],
        "resgate_minimo": listInfos[11],
        "prazo_cotizacao_aplicacao": listInfos[12],
        "prazo_cotizacao_resgate": listInfos[13],
        "prazo_disponibilizacao_resgate": listInfos[14],

    }


}

function formatResponseInfosFundComdinehiro(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }


    let objectCNPJs = {};
    let listInfos = [];

    if (infos[0]) {

        Object.entries(infos[0]).map(([key, row]) => {

            // console.log("ROW KEY: ", key)
            // console.log("ROW INFOS: ", row)

            if (!Array.isArray(row)) {
                if (isValidCnpj(row)) {
                    objectCNPJs[key] = {
                        cnpj: row,
                        infos: [],
                    };
                }
            }

        })

    }

    console.log("MAP CNPJS: ", objectCNPJs);

    infos.forEach(infos => {


        Object.entries(infos).map(([key, row]) => {


            if (objectCNPJs[key]) {

                objectCNPJs[key].infos.push(!Array.isArray(row) ? row : null);
            }


        })


    });

    console.log("objectCNPJs: ", objectCNPJs);

    // 0:  CNPJ do Fundo', 
    // 1:  CNPJ do Gestor', 
    // 2:  Raz�o Social do Gestor', 
    // 3:  CNPJ do Administrador', 
    // 4:  Raz�o Social do Administrador', 
    // 5:  Taxa de Administracao', 
    // 6:  Taxa de Administracao M�xima', 
    // 7:  Bench do fundo segundo CVMticker Comdinheiro', 
    // 8:  Data do In�cio das atividades do fundo', 
    // 9:  Patrim�nioR$29/01/2021', 
    // 10:  Aplica��o M�nima', 
    // 11:  Aplica��o Adicional M�nima', 
    // 12:  Saldo M�nimo', 
    // 13:  Resgate M�nimo', 
    // 14:  Prazo cotiza��o aplica��o', 
    // 15:  Prazo cotiza��o resgate', 
    // 16:  Prazo disponibiliza��o resgate', 
    // 17:  Taxa de Performance', 
    // 18:  Número de cotistas', 

    //Formatando em CNPJ
    let cnpjObj = {};
    Object.entries(objectCNPJs).map(([key, row]) => {

        cnpjObj[row.cnpj] = {
            "dados_cadastrais": {
                "anbima_cnpj": justNumbers(row.infos[0]),
                "pl_anbima": parseFloatComdinheiro(row.infos[9]),
                "benchmark": row.infos[7] ? row.infos[7] : null,
                "fund_init": row.infos[8],
                "administrator_name": row.infos[4],
                "administrator_cnpj": row.infos[3],
                "administration_tax": row.infos[5] ? parseFloatComdinheiro(row.infos[5]) : null,
                "max_administration_tax": row.infos[6] ? parseFloatComdinheiro(row.infos[6]) : null,
                "performance_tax": row.infos[17],
                "min_application": row.infos[10],
                "min_rescue": row.infos[13],
                "aditional_application": row.infos[8] ? parseFloatComdinheiro(row.infos[10]) : null,
                "conversion_application_quota": row.infos[14],
                "conversion_rescue_quota": row.infos[15],
                "min_balance": row.infos[12],
                "disponibilization": row.infos[16],
                "manager_name": row.infos[2],
                "manager_cnpj": row.infos[1],
                "num_cotistas": parseInt(row.infos[18]),
                //"link_regulamento": "https://anbima-bucket-documents-prod.s3.amazonaws.com/FUNDOS/248975/248975_REGULAMENTO_19700101_000.pdf"
            },
            "benchmark_anbima": row.infos[7] ? row.infos[7] : null,
            "administrator_anbima": row.infos[4],
            "manager_anbima": row.infos[2],
        }


    })

    //console.log("cnpjObj: ", cnpjObj);

    return cnpjObj;


}

export async function getInfosFundComdinheiroByDate(referenceDate, cnpjs, req_type) {

    //LAMINA
    ReqsComdinheiroAPI.logReq(req_type ? req_type : REQ_COMDINHEIRO_TYPE.COLETA_SALDOS);

    const urlToCall = "ComparaFundos001.php?&datas="
        + referenceDate
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs)
        + "&indicadores=cnpj_fundo+cnpj_gestor+nome_gestor+cnpj_adm+nome_adm+taxa_administracao+taxa_administracao_max+benchmark_cvm+data_inicio_atividade+patrimonio+aplic_min+aplic_adic_min+saldo_min+resgate_min+prazo_conv_cota_aplicado+prazo_conv_cota_resgatado+prazo_disp_rec_resgatado+taxa_performance+cotistas+&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1"

    //URL TO TEST
    //ComparaFundos001.php?&datas=01/04/2022&cnpjs=03.660.879/0001-96&indicadores=cnpj_fundo+cnpj_gestor+nome_gestor+cnpj_adm+nome_adm+taxa_administracao+taxa_administracao_max+benchmark_cvm+data_inicio_atividade+patrimonio+aplic_min+aplic_adic_min+saldo_min+resgate_min+prazo_conv_cota_aplicado+prazo_conv_cota_resgatado+prazo_disp_rec_resgatado+taxa_performance+&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1
    console.log("URL TO CALL getInfosFundComdinheiroByDate: ", urlToCall);

    try {

        //console.log("CHAMANDO COMDINHEIRO: ");

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        let formattedResponse = null;
        console.log("RESPONSE INFOS COMDINHEIRO: ", response);

        if (response && response.data && response.data.resposta) {

            //console.log("RESPONSE INFOS COMDINHEIRO: ", response.data.resposta['tab-p0'].linha);
            //console.log("RESPONSE INFOS COMDINHEIRO CHARSET: ", (response.data.resposta['tab-p0'].linha[2].indicadores));

            formattedResponse = formatResponseInfosFundComdinehiro(response.data.resposta['tab-p0'].linha);

            console.log("RESPONSE INFOS: ", formattedResponse);
        }

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}

function formatResponseInfosAndRiskFundComdinehiro(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }


    let objectInfos = {};

    if (infos[0]) {

        Object.entries(infos[0]).map(([key, row]) => {

            // console.log("ROW KEY: ", key)
            // console.log("ROW INFOS: ", row)


            if (!Array.isArray(row)) {
                //if (isValidCnpj(row)) {
                objectInfos[key] = {
                    cnpj: row,
                    infos: [],
                };
                //}
            } else { //benchmark
                objectInfos[key] = {
                    benchmark: key,
                    infos: [],
                };
            }



        })

    }

    //console.log("MAP CNPJS: ", objectInfos);

    infos.forEach(infos => {


        Object.entries(infos).map(([key, row]) => {


            if (objectInfos[key]) {

                objectInfos[key].infos.push(!Array.isArray(row) ? row : row[0]);
            }


        })


    });

    console.log("objectInfos: ", objectInfos);

    // 0:  CNPJ do Fundo', 
    // 1:  CNPJ do Gestor', 
    // 2:  Raz�o Social do Gestor', 
    // 3:  CNPJ do Administrador', 
    // 4:  Raz�o Social do Administrador', 
    // 5:  Taxa de Administracao', 
    // 6:  Taxa de Administracao M�xima', 
    // 7:  Bench do fundo segundo CVMticker Comdinheiro', 
    // 8:  Data do In�cio das atividades do fundo', 
    // 9:  Patrim�nioR$29/01/2021', 
    // 10:  Aplica��o M�nima', 
    // 11:  Aplica��o Adicional M�nima', 
    // 12:  Saldo M�nimo', 
    // 13:  Resgate M�nimo', 
    // 14:  Prazo cotiza��o aplica��o', 
    // 15:  Prazo cotiza��o resgate', 
    // 16:  Prazo disponibiliza��o resgate', 
    // 17:  Taxa de Performance', 
    // 18:  Número de cotistas', 

    //Formatando em CNPJ
    let returnObj = {};
    Object.entries(objectInfos).map(([key, row]) => {

        returnObj[row.cnpj ? row.cnpj : row.benchmark] = {
            "dados_cadastrais": row.cnpj ? {
                "anbima_cnpj": justNumbers(row.infos[0]),
                "pl_anbima": parseFloatComdinheiro(row.infos[9]),
                "benchmark": row.infos[7] ? row.infos[7] : null,
                "fund_init": row.infos[8],
                "administrator_name": row.infos[4],
                "administrator_cnpj": row.infos[3],
                "administration_tax": row.infos[5] ? parseFloatComdinheiro(row.infos[5]) : null,
                "max_administration_tax": row.infos[6] ? parseFloatComdinheiro(row.infos[6]) : null,
                "performance_tax": row.infos[17],
                "min_application": row.infos[10],
                "min_rescue": row.infos[13],
                "aditional_application": row.infos[8] ? parseFloatComdinheiro(row.infos[10]) : null,
                "conversion_application_quota": row.infos[14],
                "conversion_rescue_quota": row.infos[15],
                "min_balance": row.infos[12],
                "disponibilization": row.infos[16],
                "manager_name": row.infos[2],
                "manager_cnpj": row.infos[1],
                "num_cotistas": parseInt(row.infos[18]),
                //"link_regulamento": "https://anbima-bucket-documents-prod.s3.amazonaws.com/FUNDOS/248975/248975_REGULAMENTO_19700101_000.pdf"
            } : null,
            "risco_retorno": {
                cota: row.infos[19],
                volatilidade: row.infos[20],
                perda_maxima: row.infos[21],
                var: row.infos[22],
                indice_sharpe: row.infos[23],
                retorno_maximo: row.infos[24],
                retorno_minimo: row.infos[26],
                retorno_medio: row.infos[28],
            },
            "benchmark_anbima": row.infos[7] ? row.infos[7] : null,
            "administrator_anbima": row.infos[4],
            "manager_anbima": row.infos[2],
        }


    })

    return returnObj;


}

export async function getInfosAndRiskFundComdinheiroByDate(referenceDate, cnpjs, benchmarks, type) {

    //DEMONSTRATIVO
    ReqsComdinheiroAPI.logReq(type ? type : REQ_COMDINHEIRO_TYPE.DEMONSTRATIVO);

    const urlToCall = "ComparaFundos001.php?&datas="
        + referenceDate
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs)
        + (benchmarks && benchmarks.length ? "+" + normalizeBenchmarksToUrl(benchmarks) : '')
        + "&indicadores=cnpj_fundo+cnpj_gestor+nome_gestor+cnpj_adm+nome_adm+taxa_administracao+taxa_administracao_max+benchmark_cvm+data_inicio_atividade+patrimonio+aplic_min+aplic_adic_min+saldo_min+resgate_min+prazo_conv_cota_aplicado+prazo_conv_cota_resgatado+prazo_disp_rec_resgatado+taxa_performance+cotistas+valor_cota+vol_ano_12m+MDD(d,12m,,mdd)+VAR_PAR(d,12m,,95,1000,0)+sharpe_12m+COMP2(m,12m,,ret_max,,)+COMP2(m,12m,,data_ret_max,,)+COMP2(m,12m,,ret_min,,)+COMP2(m,12m,,data_ret_min,,)+COMP2(m,12m,,ret_medio,,)"
        //Extra params
        + "+&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1"

    //URL TO TEST
    //ComparaFundos001.php?&datas=01/04/2022&cnpjs=03.660.879/0001-96&indicadores=cnpj_fundo+cnpj_gestor+nome_gestor+cnpj_adm+nome_adm+taxa_administracao+taxa_administracao_max+benchmark_cvm+data_inicio_atividade+patrimonio+aplic_min+aplic_adic_min+saldo_min+resgate_min+prazo_conv_cota_aplicado+prazo_conv_cota_resgatado+prazo_disp_rec_resgatado+taxa_performance+&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1
    console.log("URL TO CALL getInfosAndRiskFundComdinheiroByDate: ", urlToCall);

    try {

        //console.log("CHAMANDO COMDINHEIRO: ");

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        let formattedResponse = null;
        console.log("RESPONSE INFOS AND RISK COMDINHEIRO: ", response);

        if (response && response.data && response.data.resposta) {

            //console.log("RESPONSE INFOS COMDINHEIRO: ", response.data.resposta['tab-p0'].linha);
            //console.log("RESPONSE INFOS COMDINHEIRO CHARSET: ", (response.data.resposta['tab-p0'].linha[2].indicadores));

            formattedResponse = formatResponseInfosAndRiskFundComdinehiro(response.data.resposta['tab-p0'].linha);

            console.log("RESPONSE INFOS AND RISK COMDINHEIRO FORMATADO: ", formattedResponse);
        }

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}

function formatResponseReturnTableComdinheiro(infos, benchmark) {

    if (benchmark && !Array.isArray(infos)) {

        return null;
    }

    let infosToReturn = {
        fund: {},
        benchmark: {},
    }

    if (benchmark && infos.length == 2) {

        let formattedFundInfo = {};

        Object.entries(infos[0]).map(([key, row]) => {

            let newKey = key.toString().replace("__retorno_", "");
            newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);


            formattedFundInfo[newKey] = !Array.isArray(row) ? parseFloatComdinheiro(row) : "-";

        })

        infosToReturn.fund = formattedFundInfo;

        let formattedBenchmarkInfo = {};

        Object.entries(infos[1]).map(([key, row]) => {

            let newKey = key.toString().replace("__retorno_", "");
            newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
            formattedBenchmarkInfo[newKey] = !Array.isArray(row) ? parseFloatComdinheiro(row) : "-";

        })

        infosToReturn.benchmark = formattedBenchmarkInfo;

        // infosToReturn.fund = infos[0];
        // infosToReturn.benchmark = infos[1];

    } else {

        if (!benchmark && infos) {
            let formattedFundInfo = {};

            Object.entries(infos).map(([key, row]) => {

                let newKey = key.toString().replace("__retorno_", "");
                newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);

                formattedFundInfo[newKey] = !Array.isArray(row) ? parseFloatComdinheiro(row) : "-";

            })

            infosToReturn.fund = formattedFundInfo;
            infosToReturn.benchmark = {};
        } else {

            return null;
        }

    }

    return infosToReturn;

}

export async function getInfosReturnTableComdinheiroByDate(referenceDate, cnpjs, benchmark) {

    //LAMINA
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.LAMINA);

    const urlToCall = "ComparaFundos001.php?&datas="
        + referenceDate
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs)
        + (benchmark ? "+" + benchmark : '')
        + "&indicadores=explode(Dados_Retorno_API)&num_casas=2&pc=nome_fundo&flag_transpor=0&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1"

    console.log("URL TO CALL getInfosReturnTableComdinheiroByDate: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE RETURN TABLE: ", response);

        let formattedResponse = formatResponseReturnTableComdinheiro(response.data.resposta['tab-p0'].linha, benchmark);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            console.error("ERRO getInfosReturnTableComdinheiroByDate: ", response.data.resposta['tab-p0'].linha);

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatPatrimonyEntryToCnpj(key) {

    const response = applyMaskCnpj((justNumbers(key.substring(12, 27))));
    if (response != 'CNPJ mal formado') {
        return response;
    } else { return null }
}

function formatResponsePatrimonioComdinheiro(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }

    console.log("INFOS: ", infos);

    const referenceMap = {};
    //normalize infos por CNPJS
    const newInfosByDay = [];
    Object.keys(infos[0]).forEach((key, index) => {
        const cnpjFormatted = formatPatrimonyEntryToCnpj(key);
        if (cnpjFormatted) {
            referenceMap[cnpjFormatted] = index;
        }

    });

    console.log('referenceMap: ', referenceMap);

    const keysReferenceMap = Object.keys(referenceMap);
    console.log("keysReferenceMap: ", keysReferenceMap);
    const valuesReferenceMap = Object.values(referenceMap);
    console.log("valuesReferenceMap: ", valuesReferenceMap);
    infos.forEach(element => {

        const valuesOldInfo = Object.values(element);
        //console.log("valuesOldInfo: ", valuesOldInfo);
        const newInfo = {
            data: element.data,
            ...referenceMap,
        }

        valuesReferenceMap.forEach((referenceIndex, index) => {
            newInfo[keysReferenceMap[index]] = valuesOldInfo[referenceIndex];
        });

        newInfosByDay.push(newInfo)

    });

    console.log("newInfosByDay: ", newInfosByDay);

    const newInfosByMonth = [];

    const monthsAndYears = [];

    newInfosByDay.forEach(infos => {

        let monthYear = infos.data.substring(3);
        if (!monthsAndYears.includes(monthYear)) {

            monthsAndYears.push(monthYear);

            let pieces = monthYear.split("/");

            const newInfoByMonth = {
                data: getShortMonthName(parseInt(pieces[0])) + "/" + pieces[1],
                ...referenceMap,
            }

            valuesReferenceMap.forEach((referenceIndex, index) => {
                const referenceCnpj = keysReferenceMap[index];
                newInfoByMonth[referenceCnpj] = parseFloatComdinheiro(infos[referenceCnpj]);
            });

            newInfosByMonth.push(newInfoByMonth);

        }

    });

    // let listInfosDay = [];
    // let listInfosMonth = [];

    // let monthsAndYears2 = [];

    // infos.forEach(infos => {

    //     let values = Object.values(infos);

    //     let d = {
    //         data: values[0],
    //         patrimonio: parseFloat(values[1].toString().replace(',', '.'))
    //     }

    //     listInfosDay.push(d);

    //     let monthYear = d.data.substring(3);
    //     if (!monthsAndYears2.includes(monthYear)) {

    //         monthsAndYears2.push(monthYear);

    //         let pieces = monthYear.split("/");
    //         listInfosMonth.push({
    //             data: getShortMonthName(parseInt(pieces[0])) + "/" + pieces[1],
    //             patrimonio: d.patrimonio,
    //         });

    //     }

    // });

    return {
        //byDay2: listInfosDay,
        byDay: newInfosByDay,
        //byMonth2: listInfosMonth.reverse(),
        byMonth: newInfosByMonth.reverse(),
    };

}

export async function getTablePatrimonioComdinheiroByPeriod(initDate, endDate, cnpjs, benchmarks, req_type) {

    //LAMINA
    ReqsComdinheiroAPI.logReq(req_type ? req_type : REQ_COMDINHEIRO_TYPE.LAMINA);

    const urlToCall = "HistoricoIndicadoresFundos001.php?"
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs) + "+CDI"
        + "&data_ini=" + justNumbers(initDate)
        + "&data_fim=" + justNumbers(endDate)
        + "&indicadores=patrimonio&op01=tabela_h&num_casas=2&enviar_email=0&periodicidade=diaria&cabecalho_excel=modo2&transpor=0&asc_desc=desc&tipo_grafico=linha&relat_alias_automatico=cmd_alias_01"

    console.log("URL TO CALL getTablePatrimonioComdinheiroByPeriod: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));
        console.log("RESPONSE PATRIMONIO: ", response);

        let formattedResponse = formatResponsePatrimonioComdinheiro(response.data.resposta['tab-p0'].linha);

        //console.log("RESPONSE PATRIMONIO TABLE: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatResponseRiscoRetornoComdinheiro(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }

    let listInfos = [];

    infos.forEach(infos => {

        let values = Object.values(infos);
        listInfos.push({
            fund: !Array.isArray(values[1]) ? values[1] : null,
            benchmark: !Array.isArray(values[2]) ? values[2] : null,
        })

    });

    return {

        cota: listInfos[0],
        pl: listInfos[1],
        volatilidade: listInfos[2],
        perda_maxima: listInfos[3],
        var: listInfos[4],
        indice_sharpe: listInfos[5],
        retorno_maximo: listInfos[6],
        retorno_minimo: listInfos[8],
        retorno_medio: listInfos[10],

    };

}

export async function getTableRiscoRetornoComdinheiroByDate(referenceDate, cnpjs, benchmark) {

    //LAMINA
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.LAMINA);

    const urlToCall = "ComparaFundos001.php?&datas="
        + referenceDate
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs)
        + "+" + benchmark
        + "&indicadores=valor_cota+patrimonio+vol_ano_12m+MDD(d,12m,,mdd)+VAR_PAR(d,12m,,95,1000,0)+sharpe_12m+COMP2(m,12m,,ret_max,,)+COMP2(m,12m,,data_ret_max,,)+COMP2(m,12m,,ret_min,,)+COMP2(m,12m,,data_ret_min,,)+COMP2(m,12m,,ret_medio,,)&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01"

    console.log("URL TO CALL getTableRiscoRetornoComdinheiroByDate: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));
        console.log("RESPONSE RISCO RETORNO: ", response);

        let formattedResponse = formatResponseRiscoRetornoComdinheiro(response.data.resposta['tab-p0'].linha);

        //console.log("RESPONSE RISCO RETORNO: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatResponseAnalisysRiskMarket(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }

    const cnpjFundMap = infos.pop(); //importante garantir que o indicador de cnpj_fund seja o último indicador

    console.log("cnpjFundMap: ", cnpjFundMap);
    console.log("INFOS: ", infos);

    delete cnpjFundMap.indicadores;

    const formattedCnpjFundMap = {}

    Object.entries(cnpjFundMap).map(([key, row]) => {
        formattedCnpjFundMap[key] = {
            cnpj: row,
            infos: {
                cota: null,
                patrimonio: null,
                var_mes: null,
                var_12m: null,
                vol_mes: null,
                vol_12m: null,
                traynor_mes: null,
                traynor_12m: null,
                sharpe_mes: null,
                sharpe_12m: null,
                drawdown_mes: null,
                drawdown_12m: null,
            }
        }
    });

    infos.forEach((element, index) => {

        if (index == 0) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.cota = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null;
            });
        }
        if (index == 1) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.patrimonio = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(!Array.isArray(element[key])) : null
            });
        }
        if (index == 2) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {


                if (!Array.isArray(element[key])) {

                    const valueMonth = applyMaskAmount(parseFloatComdinheiro(element[key]) * Math.sqrt(21));
                    const valueYear = applyMaskAmount(parseFloatComdinheiro(element[key]) * Math.sqrt(252));
                    row.infos.var_mes = valueMonth;
                    row.infos.var_12m = valueYear;

                } else {

                    row.infos.var_mes = null;
                    row.infos.var_12m = null;

                }
            });
        }
        if (index == 3) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.vol_mes = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 4) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.vol_12m = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 5) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.traynor_mes = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 6) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.traynor_12m = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 7) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.sharpe_mes = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 8) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.sharpe_12m = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 9) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.drawdown_mes = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }
        if (index == 10) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.infos.drawdown_12m = !Array.isArray(element[key]) ? applyMaskAmountComdinheiro(element[key]) : null
            });
        }

    });

    console.log('formattedCnpjFundMap: ', formattedCnpjFundMap);

    const finalMap = {};

    Object.entries(formattedCnpjFundMap).map(([key, row]) => {
        finalMap[row.cnpj] = row.infos;
    });

    console.log('finalMap: ', finalMap);

    return finalMap;

}

function formatResponseGetVaRs(infos) {

    if (!Array.isArray(infos)) {

        return null;
    }

    const cnpjFundMap = infos.pop(); //importante garantir que o indicador de cnpj_fund seja o último indicador

    console.log("cnpjFundMap: ", cnpjFundMap);
    console.log("INFOS: ", infos);

    delete cnpjFundMap.indicadores;

    const formattedCnpjFundMap = {}

    Object.entries(cnpjFundMap).map(([key, row]) => {
        formattedCnpjFundMap[key] = {
            cnpj: row,
            var: null,
        }
    });

    infos.forEach((element, index) => {

        if (index == 0) {

            Object.entries(formattedCnpjFundMap).map(([key, row]) => {
                row.var = !Array.isArray(element[key]) ? parseFloatComdinheiro(element[key]) : null;
            });
        }

    });

    console.log('formattedCnpjFundMap: ', formattedCnpjFundMap);

    const finalMap = {};

    Object.entries(formattedCnpjFundMap).map(([key, row]) => {
        finalMap[row.cnpj] = row.var;
    });

    console.log('finalMap: ', finalMap);

    return finalMap;

}

export async function getAnalisysRiskMarket(referenceDate, cnpjs) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RISCO);


    const urlToCall = "ComparaFundos001.php?&datas="
        + referenceDate
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs)
        //+ "&indicadores=valor_cota+patrimonio+VAR_PAR(m,mes_atual,,95,,0)+VAR_PAR(d,12m,,95,1000,0)+VOL(m,mes_atual,,0,1)+VOL(m,12m,,0,1)+treynor_01m+treynor_12m+sharpe_mes_atual+sharpe_12m+MDD(m,mes_atual,,mdd)+MDD(m,12m,,mdd)+cnpj_fundo&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1"
        + "&indicadores=valor_cota+patrimonio+VAR_PAR(d,12m,,95,,0)+VOL(d,12m,,0,2)+VOL(d,12m,,0,1)+treynor_01m+treynor_12m+sharpe_mes_atual+sharpe_12m+MDD(m,mes_atual,,mdd)+MDD(m,12m,,mdd)+cnpj_fundo&num_casas=7&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1"

    console.log("URL TO CALL getAnalisysRiskMarket: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));
        console.log("RESPONSE getAnalisysRiskMarket: ", response);

        const formattedResponse = formatResponseAnalisysRiskMarket(response.data.resposta['tab-p0'].linha);

        console.log("RESPONSE RISCO RETORNO: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("Error: ", error);
        return {
            success: false,
            body: error.response
        };

    }

}

export async function getVaRs(referenceDate, cnpjs) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.CONTABIL);


    const urlToCall = "ComparaFundos001.php?&datas="
        + referenceDate
        + "&cnpjs="
        + normalizeCnpjsToUrl(cnpjs)
        + "&indicadores=VAR_PAR(d,36m,,95,,0)+cnpj_fundo&num_casas=7&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1"

    console.log("URL TO CALL getVaRs: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        const formattedResponse = formatResponseGetVaRs(response.data.resposta['tab-p0'].linha);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("Error: ", error);
        return {
            success: false,
            body: error.response
        };

    }

}

//Recebe o array de dias de cotas junto dos CNPJS e os valores
//Deve ser feita a formatação
function formatResponseListCotas(cnpjsAndFundIds, arrayCotas, month) {

    //Corrigir cota a maais 

    let formattedCotas = {};
    cnpjsAndFundIds.forEach(cnpjAndFundId => {

        //Cotas para cada CNPJ
        formattedCotas[cnpjAndFundId.cnpj] = {
            fund_id: cnpjAndFundId.fund_id,
            serie_historica: [],
        }

    });

    //"patrimonio_liquido":16771589579.61,"valor_cota":2.613391, "data_referencia":"2021-01-29"
    arrayCotas.forEach(dayCotas => {

        if (dayCotas.data.substring(3, 5) == month) {


            Object.entries(formattedCotas).map(([key, row]) => {

                if (row.serie_historica) {

                    let suffix = "";
                    if (key.includes('_')) { //indica que o fundo possui um sufixo
                        //deve ser guardado o sufixo para ser adicionado as keys poteriormente

                        const splittedCnpj = key.split('_');
                        key = splittedCnpj[0];
                        suffix = '_' + splittedCnpj[1];
                    }

                    //console.log("SUFIXO: ", suffix);
                    const possibleTicker = key.toString().toLocaleLowerCase();

                    key = justNumbers(key);
                    let keyValorCota = "p" + key.substring(0, 8) + "/" + key.substring(8) + suffix;
                    let keyPatrimonio = keyValorCota + "patrimonio";

                    //console.log("SUFIXO: ", keyValorCota);
                    //console.log("SUFIXO: ", keyPatrimonio);

                    let piecesDate = dayCotas.data.split("/");

                    //Fundos que não possuem cotas retornam "nd" nos valores
                    //Ex::
                    // data: "04/01/2021"
                    // p20886575/000160: "nd"
                    // p20886575/000160patrimonio: "nd"

                    const data_referencia = piecesDate[2] + "-" + piecesDate[1] + "-" + piecesDate[0];

                    if (dayCotas[possibleTicker]) {
                        row.serie_historica.push({

                            "valor_cota": parseFloatComdinheiro(dayCotas[possibleTicker]),
                            "patrimonio_liquido": 0,
                            "data_referencia": data_referencia,

                        })
                    } else {

                        if (dayCotas[keyValorCota] != "nd") {

                            //console.log("possibleTicker: ", possibleTicker);

                            row.serie_historica.push({

                                "valor_cota": parseFloatComdinheiro(dayCotas[keyValorCota]),
                                "patrimonio_liquido": parseFloatComdinheiro(dayCotas[keyPatrimonio]),
                                "data_referencia": data_referencia,

                            })

                        }
                    }


                }

            })
        }


    });

    return formattedCotas;
}


export async function getCotasDiariasPorPeríodo(cnpjsAndFundIds, initDate, endDate, noRepeatLastQuotaWhenNoQuota) {

    //COLETA DE SALDOS
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COLETA_SALDOS);

    console.log("cnpjsAndFundIds: ", cnpjsAndFundIds);

    const urlToCall = "HistoricoCotacao002.php?&x="
        + normalizeCnpjsToUrlCotas(cnpjsAndFundIds)
        + "&data_ini="
        + justNumbers(initDate)
        + "&data_fim="
        + justNumbers(endDate)
        + "&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=preco&retorno=discreto&tipo_data=du_br&num_casas=2&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=9ur54ut49vj&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line"
        //+ "&tipo_ajuste=todosajustes"
        + "&tipo_ajuste=semajuste" //Assumimos que os fundos podem vir sem os AJUSTES, caso do fundo CAXIA BRASIL 2024 IV TP FI RF
        + (noRepeatLastQuotaWhenNoQuota ? "" : "&preco_nd_ant=1") //Repetir a última quota quando o valor da cota for nd


    console.log("URL TO CALL COTAS: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));
        console.log("RESPONSE COTAS: ", response);

        let formattedResponse = formatResponseListCotas(cnpjsAndFundIds, response.data.resposta['tab-p1'].linha, initDate.substring(3, 5));

        console.log("RESPONSE COTAS: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function getNormalizeTitlesToUrl(titles) {

    let textToUrl = '';
    const alreadyIncluded = [];
    titles.forEach(title => {

        const codTitle = getTitleCod(title, true);

        if (!alreadyIncluded.includes(codTitle)) {

            alreadyIncluded.push(codTitle);
            textToUrl += ''
                //+ codTitle //código do título
                //+ '.pu_par+'

                + codTitle //código do título
                + '.pu_anb+'
        }

    });

    return textToUrl + 'IPCAdp+'; //IPCAdp

}

function getNormalizeTitlesPU_medToUrl(titles) {

    let textToUrl = '';
    const alreadyIncluded = [];
    titles.forEach(title => {

        const codTitle = getTitleCod(title, true);

        if (!alreadyIncluded.includes(codTitle)) {

            alreadyIncluded.push(codTitle);
            textToUrl += ''
                + codTitle //código do título
                + '.pu_med+'
        }

    });

    return textToUrl;

}

function formatResponseListTitlesCotas(titles, cotas) {

    const mapTitleCotas = {};
    const ipcaDp = [];
    let inputIpcdDp = true;
    const alreadyProcessedTitles = [];
    titles.forEach(titleLoop => {

        const titleCod = getTitleCod(titleLoop, true);

        if (!alreadyProcessedTitles.includes(titleCod)) {

            alreadyProcessedTitles.push(titleCod);

            if (!mapTitleCotas[titleCod]) {
                mapTitleCotas[titleCod] = []
            }

            cotas.forEach(cotaLoop => {

                mapTitleCotas[titleCod].push({
                    date_quota: cotaLoop.data,
                    pu_anb: parseFloatComdinheiro(cotaLoop[titleCod.toString().toLowerCase() + 'pu_anb'])
                })

                if (inputIpcdDp) {
                    ipcaDp.push({
                        date: cotaLoop.data,
                        ipca_dp: parseFloatComdinheiro(cotaLoop.ipcadp)
                    });
                }


            });

            inputIpcdDp = false;

        }



    });

    return {
        mapTitleCotas: mapTitleCotas,
        ipcaDp: ipcaDp
    }

}

export async function getTitlesInfos(titles, initDate, endDate) {

    //COLETA DE SALDOS
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COLETA_SALDOS);

    // const urlToCall = 'HistoricoCotacao002.php?&x='
    //     + 'NTNB_13102011_15082022' //código do título
    //     + '.pu_par+'
    //     + 'NTNB_13102011_15082022' //código do título
    //     + '.pu_anb+'
    //     + 'VNA_NTNB_P1+' //Codigo VNA
    //     + 'NTNB_13102011_15082022' //código do título
    //     + '.taxa_anb+&'
    //     + 'data_ini='
    //     + '11102021' //Data ini
    //     + '&data_fim='
    //     + '08112021' //Data fim
    //     + '&pagina=1&d=MOEDA_ORIGINAL&g=0&m=0&info_desejada=preco&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=4&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=18y67&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line'

    const urlToCall = 'HistoricoCotacao002.php?&x='
        + getNormalizeTitlesToUrl(titles)
        + '&data_ini='
        + initDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + '&pagina=1&d=MOEDA_ORIGINAL&g=0&m=0&info_desejada=preco&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=4&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=18y67&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line'

    console.log("URL TO CALL TITLES: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE TITLE: ", response);

        let formattedResponse = formatResponseListTitlesCotas(titles, response.data.resposta['tab-p1'].linha);
        console.log('formattedResponse titles: ', formattedResponse)

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatResponseCDI(infosCDI) {
    console.log("INFOS CDI: ", infosCDI);

    const arrayCDI = [];
    if (Array.isArray(infosCDI)) {
        infosCDI.forEach(element => {

            arrayCDI.push({
                date: element.data,
                value: parseFloatComdinheiro(element.cdi),
            });
        });

    }

    return arrayCDI;
}

function formatResponseIPCAdp(infosIPCAdp) {
    console.log("INFOS IPCAdp: ", infosIPCAdp);

    const arrayIPCAdp = [];
    if (Array.isArray(infosIPCAdp)) {
        infosIPCAdp.forEach(element => {

            arrayIPCAdp.push({
                date: element.data,
                value: parseFloatComdinheiro(element.ipcadp),
            });
        });

    }

    return arrayIPCAdp;
}

function formatResponseIBOVAndCDI(infosIBOVAndCDI) {
    console.log("INFOS IBOV+CDI: ", infosIBOVAndCDI);

    const array = [];
    if (Array.isArray(infosIBOVAndCDI)) {
        infosIBOVAndCDI.forEach(element => {

            array.push({
                date: element.data,
                valueIBOV: element.ibov != ND ? parseFloatComdinheiro(element.ibov) * 100 : null,
                valueCDI: element.ibov != ND ? parseFloatComdinheiro(element.cdi) * 100 : null,
            });
        });

    }

    return array;
}

function formatResponseIGPM(infosIGPM) {
    console.log("INFOS IGPM: ", infosIGPM);

    const arrayIGPM = [];
    if (Array.isArray(infosIGPM)) {
        infosIGPM.forEach(element => {

            arrayIGPM.push({
                date: element.data,
                value: parseFloatComdinheiro(element.igpmdp),
            });
        });

    }

    return arrayIGPM;
}

export async function getCDI(initDate, endDate, isAcum, logCod) {

    //COLETA DE SALDOS
    ReqsComdinheiroAPI.logReq(logCod ? logCod : REQ_COMDINHEIRO_TYPE.COLETA_SALDOS);

    const urlToCall = 'HistoricoCotacao002.php?&x=CDI'
        + '&data_ini='
        + initDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + `&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=retorno${isAcum ? '_acum' : ''}&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=2&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=4xdkkfek4xr&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line`

    console.log("URL TO CALL CDI: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE CDI: ", response);

        const formattedResponse = formatResponseCDI(response.data.resposta['tab-p1'].linha);

        console.log("RESPONSE ARRAY CDI: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

export async function getIPCAdp(initDate, endDate) {

    //COLETA DE SALDOS
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RELATORIO_PROVISORIO);

    const urlToCall = 'HistoricoCotacao002.php?&x=IPCAdp'
        + '&data_ini='
        + initDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + '&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=retorno&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=8&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=4xdkkfek4xr&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line'

    console.log("URL TO CALL IPCAdp: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE IPCAdp: ", response);

        const formattedResponse = formatResponseIPCAdp(response.data.resposta['tab-p1'].linha);

        console.log("RESPONSE ARRAY IPCAdp: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

export async function getIBOVAndCDI(initDate, endDate) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RISCO);

    const urlToCall = 'HistoricoCotacao002.php?&x=IBOV+CDI'
        + '&data_ini='
        + initDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + '&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=retorno&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=2&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=4xdkkfek4xr&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line'

    console.log("URL TO CALL getIBOVAndCDI: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getIBOVAndCDI: ", response);

        const formattedResponse = formatResponseIBOVAndCDI(response.data.resposta['tab-p1'].linha);

        console.log("RESPONSE ARRAY getIBOVAndCDI: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

export async function getIGPM(initDate, endDate) {


    //COLETA DE SALDOS
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COLETA_SALDOS);

    const urlToCall = 'HistoricoCotacao002.php?&x=IGPMdp'
        + '&data_ini='
        + initDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + '&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=retorno&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=2&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=4xdkkfek4xr&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line'

    console.log("URL TO CALL IGPM: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE IGPM: ", response);

        const formattedResponse = formatResponseIGPM(response.data.resposta['tab-p1'].linha);

        console.log("RESPONSE ARRAY IGPM: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatCompositionFunds(data) {

    console.log("DATA: ", data);

    const mapFunds = {};
    let totalProportion = 0;
    if (Array.isArray(data)) {

        data.forEach(element => {

            if (!mapFunds[element.cnpj_fundo]) {
                mapFunds[element.cnpj_fundo] = {}
            }

            const description = element.ativo_que_compoe_carteiradescricao;
            if (!mapFunds[element.cnpj_fundo][description]) {
                mapFunds[element.cnpj_fundo][description] = { composicao: [], proporcao: 0 };
            }

            const proportion = element["ativo_que_compoe_carteiraproporcao_na_carteira_(pct)"] ? parseFloatComdinheiro(element["ativo_que_compoe_carteiraproporcao_na_carteira_(pct)"]) : 0;
            mapFunds[element.cnpj_fundo][description].composicao.push({
                descricao: description,
                nome_fundo: !Array.isArray(element.nome_do_fundo_investido) ? element.nome_do_fundo_investido : null,
                ticker: element.ativo_que_compoe_carteiraticker,
                proporcao: proportion,
                data_da_carteira: element['data_da_carteira_(ultimo_dia_mes)'],
                razao_social_do_fundo: element.razao_social_do_fundo ? element.razao_social_do_fundo : '-',
                valor_final: element.ativo_que_compoe_carteiravalor_final ? parseFloatComdinheiro(element.ativo_que_compoe_carteiravalor_final) : null,
            })

            mapFunds[element.cnpj_fundo][description].proporcao += proportion;

            totalProportion += proportion;

        });

    }

    console.log('totalProportion: ', totalProportion);
    return mapFunds;

}

function formatFullCompositionFunds(graficoData, data) {

    console.log("graficoData: ", graficoData);
    console.log("DATA: ", data);

    const formattedResponse = {
        sectorChart: [],
        classChart: [],
        tableData: [],
    };

    const { sectorChart, classChart, tableData } = formattedResponse;

    Object.entries(graficoData).map(([key, row]) => {
        sectorChart.push({
            label: key.substring(3),
            value: parseFloat(parseFloatComdinheiro(row).toFixed(2)),
        })
    });

    let totalValue = 0;
    const mapClassChart = {};

    if (Array.isArray(data)) {


        data.forEach(element => {

            const valorFinal = element.final_valor ? parseFloatComdinheiro(element.final_valor) : null;
            const proportion = Array.isArray(element.percent) ? parseFloatComdinheiro(element.percent[0]) : null;

            if (!mapClassChart[element.desc_ativo]) {
                mapClassChart[element.desc_ativo] = 0;
            }

            mapClassChart[element.desc_ativo] += valorFinal;

            tableData.push({
                asset: element.ativocomdesc_ativo,
                description: element.desc_ativo,
                sector: element.descricao,
                proportion: proportion,
                value: valorFinal,
            })

            totalValue += valorFinal;

        });

    }

    //Obtendo proporção do classChart
    Object.entries(mapClassChart).map(([key, row]) => {

        const value = row / totalValue * 100;
        classChart.push({
            label: key + " (" + applyMaskAmount(value) + "%)",
            value: parseFloat(value.toFixed(2)),
        })
    });

    console.log('formattedResponse: ', formattedResponse);

    return formattedResponse;

}


export async function getCompositionFunds(initDate, endDate) {

    //COMPOSICAO FUNDO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COMPOSICAO_FUNDO);

    const urlToCall = 'FundScreener001.php?&relat=&data_rr=06/05/2022&data_cart=01/03/2022&variaveis=cnpj_fundo+nome_fundo+classe+ativo+desc_ativo+compras_valor+compras_quant+vendas_valor+vendas_quant+final_valor+percent+last_day(data_carteira)+&agrupar=&gr_classe=FI&cl_cvm=todos&cl_anb=todos&admin=&gestor=&situacao=4&cotas=7&quali=7&exclu=7&forma=3&largura=960&truncar=200&casas=2&salve=&filtro=cnpj_fundo+like+14.508.643~0001%BD55|07.442.078~0001%BD05|19.303.795~0001%BD35&join=inner&overwrite=0&minha_variavel=&transpor=0&salve_obs=&relat_alias_automatico=cmd_alias_01&flag_tab_carteira=0&periodos=0&periodicidade=mensal&formato_data=1&utf8_encode=1'

    console.log("URL TO CALL getCompositionFunds: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getCompositionFunds: ", response);

        const formattedResponse = formatCompositionFunds(response.data.resposta['tab-p0'].linha);

        console.log("RESPONSE ARRAY getCompositionFunds: ", formattedResponse);

        // // if (formattedResponse) {
        // if (formattedResponse) {

        //     return {
        //         success: true,
        //         body: formattedResponse
        //     };

        // } else {

        //     return {
        //         success: false,
        //     };

        // }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

export async function getFundCompositionByCnpj(cnpj) {

    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COMPOSICAO_FUNDO);

    const urlToCall = 'FundScreener001.php?&relat=&data_rr=dmenos2&data_cart=30/09/9999&variaveis=cnpj_fundo+nome_fundo+classe+ativo+ativo(nome_fundo)+desc_ativo+compras_valor+compras_quant+vendas_valor+vendas_quant+final_valor+percent+last_day(data_carteira)+&agrupar=&gr_classe=FI&cl_cvm=todos&cl_anb=todos&admin=&gestor=&situacao=4&cotas=7&quali=7&exclu=7&forma=3&largura=960&truncar=200&casas=2&salve=&filtro=cnpj_fundo+like+' + cnpj + '&join=inner&overwrite=0&minha_variavel=&transpor=0&salve_obs=&relat_alias_automatico=cmd_alias_01&flag_tab_carteira=0&periodos=0&periodicidade=mensal&formato_data=1&utf8_encode=1'

    console.log("URL TO CALL getCompositionFunds: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getCompositionFunds: ", response);

        const formattedResponse = formatCompositionFunds(response.data.resposta['tab-p0'].linha);

        console.log("RESPONSE ARRAY getCompositionFunds: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("Error: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}

export async function getFundFullCompositionByCnpj(cnpj) {

    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COMPOSICAO_FUNDO);

    const urlToCall = `CarteiraFundo002-${justNumbers(cnpj)}-89990101-setor-1-default-5-1---1-0-0-coluna-10&utf8_encode=1`

    console.log("URL TO CALL getFundFullCompositionByCnpj: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getFundFullCompositionByCnpj: ", response);

        const formattedResponse = formatFullCompositionFunds(response.data.resposta.grafico0, response.data.resposta['tab-p3'].linha);

        console.log("RESPONSE ARRAY getFundFullCompositionByCnpj: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("Error: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}

export async function testEndpoint() {

    //const urlToCall = `CarteiraFundo007.php?&cnpj_fundo=08070841000187+20886575000160&data_carteira=mais_recente&variaveis=ativo%20desc_ativo%20final_valor%20percent&meses=1&info_adicional=nenhuma`
    const urlToCall = `FundScreener001.php?&relat=&data_rr=30/04/2024&data_cart=01/04/2024&variaveis=cnpj_fundo+nome_fundo+CART_MAIS_RECENTE(maisrecente,A)&agrupar=&gr_classe=todos&cl_cvm=todos&cl_anb=todos&admin=&gestor=&situacao=f&cotas=7&quali=7&exclu=7&forma=3&bolsa=7&largura=960&truncar=200&casas=2&salve=&filtro=cnpj_fundo+like+20.886.575~0001%BD60|37.985.879~0001%BD88|38.421.502~0001%BD69|38.001.966~0001%BD16|28.578.936~0001%BD13|33.913.562~0001%BD85|35.002.482~0001%BD01|14.113.340~0001%BD33|17.335.646~0001%BD22|26.673.556~0001%BD32&join=left&overwrite=0&minha_variavel=&transpor=0&salve_obs=&relat_alias_automatico=cmd_alias_01&flag_tab_carteira=0&periodos=0&periodicidade=mensal&formato_data=1&linppag=100&ordenar=&limit_01=`

    console.log("URL TO CALL testEndpoint: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE testEndpoint: ", response);


    } catch (error) {

        console.log("Error: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}

function formatRankingManagers(managers) {

    const formattedMap = {};
    Object.entries(managers).map(([key, row]) => {

        if (row && row.razao_social_do_gestor && !Array.isArray(row.razao_social_do_gestor)) {

            formattedMap[row.razao_social_do_gestor] = parseFloatComdinheiro(row['sum(patrimonio)']);

        }
    })

    return formattedMap;

}

export async function getRankingManagers(referenceDate) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RISCO);

    //const urlToCall = `FundScreener001.php?&relat=&data_rr=${referenceDate}&data_cart=${referenceDate}&variaveis=nome_gestor+SUM(patrimonio)~1000&agrupar=cnpj_gestor&gr_classe=todos&cl_cvm=todos&cl_anb=todos&admin=&gestor=&situacao=f&cotas=2&quali=7&exclu=7&forma=3&largura=960&truncar=200&casas=2&salve=&filtro=&join=inner&overwrite=0&minha_variavel=&transpor=0&salve_obs=&relat_alias_automatico=cmd_alias_01&flag_tab_carteira=0&periodos=0&periodicidade=mensal&formato_data=1&utf8_encode=1`
    const urlToCall = `FundScreener001.php?&relat=&data_rr=${referenceDate}&data_cart=${referenceDate}&variaveis=nome_gestor+SUM(patrimonio)&agrupar=cnpj_gestor&gr_classe=todos&cl_cvm=todos&cl_anb=todos&admin=&gestor=&situacao=f&cotas=2&quali=7&exclu=7&forma=3&largura=960&truncar=200&casas=2&salve=&filtro=&join=inner&overwrite=0&minha_variavel=&transpor=0&salve_obs=&relat_alias_automatico=cmd_alias_01&flag_tab_carteira=0&periodos=0&periodicidade=mensal&formato_data=1&utf8_encode=1`

    console.log("URL TO CALL doGetRankingManagers: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE doGetRankingManagers: ", response);

        const formattedResponse = formatRankingManagers(response.data.resposta['tab-p0'].linha);

        console.log("RESPONSE ARRAY doGetRankingManagers: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatPU_med(titles, cotas) {

    const mapTitleCotas = {};
    const alreadyProcessedTitles = [];
    titles.forEach(titleLoop => {

        const titleCod = getTitleCod(titleLoop, true);

        if (!alreadyProcessedTitles.includes(titleCod)) {

            alreadyProcessedTitles.push(titleCod);

            if (!mapTitleCotas[titleCod]) {
                mapTitleCotas[titleCod] = []
            }

            cotas.forEach(cotaLoop => {

                mapTitleCotas[titleCod].push({
                    date_quota: cotaLoop.data,
                    pu_med: parseFloatComdinheiro(cotaLoop[titleCod.toString().toLowerCase() + 'pu_med'])
                })

            });

        }



    });

    return {
        mapTitleCotas: mapTitleCotas,
    }

}

export async function getPU_med(titles, startDate, endDate) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RELATORIO_PROVISORIO);

    const urlToCall = 'HistoricoCotacao002.php?&x='
        + getNormalizeTitlesPU_medToUrl(titles)
        + '&data_ini='
        + startDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + '&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=preco&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=4&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=8rc4kbdvss1r&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line&script=&tooltip=unica'

    console.log("URL TO CALL getPU_med: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getPU_med: ", response);

        const formattedResponse = formatPU_med(titles, response.data.resposta['tab-p1'].linha);

        console.log("RESPONSE ARRAY getPU_med: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatResponseSelicMeta(infosSelicMeta) {
    console.log("infosSelicMeta: ", infosSelicMeta);

    const array = [];
    if (Array.isArray(infosSelicMeta)) {
        infosSelicMeta.forEach(element => {

            array.push({
                date: element.data,
                value: parseFloatComdinheiro(element.selic_meta),
            });
        });

    }

    return array;
}

export async function getSelicMeta(initDate, endDate) {

    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RELATORIO_PROVISORIO);

    const urlToCall = 'HistoricoCotacao002.php?&x=SELIC_META'
        + '&data_ini='
        + initDate //Data ini
        + '&data_fim='
        + endDate //Data fim
        + '&pagina=1&d=MOEDA_ORIGINAL&g=1&m=0&info_desejada=retorno&retorno=discreto&tipo_data=du_br&tipo_ajuste=todosajustes&num_casas=2&enviar_email=0&ordem_legenda=1&cabecalho_excel=modo1&classes_ativos=4xdkkfek4xr&ordem_data=0&rent_acum=rent_acum&minY=&maxY=&deltaY=&preco_nd_ant=0&base_num_indice=100&flag_num_indice=0&eixo_x=Data&startX=0&max_list_size=20&line_width=2&titulo_grafico=&legenda_eixoy=&tipo_grafico=line'

    console.log("URL TO CALL SELIC_META: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE SELIC_META: ", response);

        const formattedResponse = formatResponseSelicMeta(response.data.resposta['tab-p1'].linha);

        console.log("RESPONSE ARRAY SELIC_META: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatRankingAdmins(admins) {

    const formattedMap = {};
    Object.entries(admins).map(([key, row]) => {

        if (row && row.razao_social_do_administrador
            && !Array.isArray(row.razao_social_do_administrador)) {

            formattedMap[row.razao_social_do_administrador] = parseFloatComdinheiro(row['sum(patrimonio)']);

        }
    })

    return formattedMap;

}

export async function getRankingAdmins(referenceDate) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RISCO);

    const urlToCall = `FundScreener001.php?&relat=&data_rr=${referenceDate}&data_cart=${referenceDate}&variaveis=nome_adm+SUM(patrimonio)&agrupar=cnpj_adm&gr_classe=todos&cl_cvm=todos&cl_anb=todos&admin=&gestor=&situacao=f&cotas=2&quali=7&exclu=7&forma=3&largura=960&truncar=200&casas=2&salve=&filtro=&join=inner&overwrite=0&minha_variavel=&transpor=0&salve_obs=&relat_alias_automatico=cmd_alias_01&flag_tab_carteira=0&periodos=0&periodicidade=mensal&formato_data=1&utf8_encode=1`

    console.log("URL TO CALL getRankingAdmins: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getRankingAdmins: ", response);

        const formattedResponse = formatRankingAdmins(response.data.resposta['tab-p0'].linha);

        console.log("RESPONSE ARRAY getRankingAdmins: ", formattedResponse);

        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response
        };

    }

}

function formatHistoricoByMonth(data, cnpjs, benchmarks) {

    //montando array referencia
    const arrayReference = [];
    cnpjs.forEach(element => {
        arrayReference.push(element);
    });
    benchmarks.forEach(element => {
        arrayReference.push(element.comdinheiro_name);
    });

    console.log('arrayReference: ', arrayReference);

    const mapRents = {};

    if (data && Array.isArray(data)) {


        //const cnpj = key.substring(16, 24) + key.substring(25, 31);
        data.forEach(monthRent => {
            const loopDate = monthRent.data;
            delete monthRent.data;
            mapRents[loopDate] = {};

            let index = 0;
            Object.entries(monthRent).map(([key, row]) => {
                mapRents[loopDate][arrayReference[index]] = parseFloatComdinheiro(row);
                index++;
            })
        });
    }


    console.log('mapRents: ', mapRents);

    const formatedArrayRents = [];
    Object.entries(mapRents).map(([key, row]) => {

        const splittedKey = key.split("/");
        const newKey = getShortMonthName(parseInt(splittedKey[1])) + '/' + splittedKey[2];
        formatedArrayRents.push({
            data: newKey,
            ...row,
        });

    })
    console.log('formatedArrayRents: ', formatedArrayRents);


    return formatedArrayRents.reverse();

}

export async function getHistoricoRentabilidadesPorPeriodo(cnpjs, benchmarks, initDate, endDate) {

    //COMPARADOR FUNDOS
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.COMPARADOR_FUNDOS);

    const urlToCall = 'HistoricoIndicadoresFundos001.php?&'
        + 'cnpjs=' + normalizeCnpjsToUrl(cnpjs)
        + (benchmarks && benchmarks.length ? "+" + normalizeBenchmarksToUrl(benchmarks) : '')
        + '&data_ini=' + initDate
        + '&data_fim=' + endDate
        + '&indicadores=ret_mes_atual+&op01=tabela_h&num_casas=2&enviar_email=0&periodicidade=mes&cabecalho_excel=modo2&transpor=0&asc_desc=desc&tipo_grafico=linha&relat_alias_automatico=cmd_alias_01&utf8_encode=1'

    console.log("URL TO CALL getHistoricoRentabilidadesPorPeriodo: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getHistoricoRentabilidadesPorPeriodo: ", response);

        const formattedResponse = formatHistoricoByMonth(response.data.resposta['tab-p0'].linha, cnpjs, benchmarks);

        console.log("RESPONSE ARRAY getCompositionFunds: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}

function formatRentsMes3m6m12m(data, cnpjs, benchmarks) {

    const mapBenchmarks = {};
    benchmarks.forEach(element => {
        mapBenchmarks[element.toString().toLowerCase()] = {
            rentMonth: null,
            rent3m: null,
            rent6m: null,
            rent12m: null,
        }
    });

    console.log('mapBenchmarks: ', mapBenchmarks);

    //data[0] mes atual
    Object.entries(data[0]).map(([key, value]) => {
        console.log('key: ', key);
        if (key != 'indicadores')
            mapBenchmarks[key].rentMonth = parseFloatComdinheiro(value);
    });
    Object.entries(data[1]).map(([key, value]) => {
        console.log('key: ', key);
        if (key != 'indicadores')
            mapBenchmarks[key].rent3m = parseFloatComdinheiro(value);
    });
    Object.entries(data[2]).map(([key, value]) => {
        console.log('key: ', key);
        if (key != 'indicadores')
            mapBenchmarks[key].rent6m = parseFloatComdinheiro(value);
    });
    Object.entries(data[3]).map(([key, value]) => {
        console.log('key: ', key);
        if (key != 'indicadores')
            mapBenchmarks[key].rent12m = parseFloatComdinheiro(value);
    });

    return {
        mapRentsBenchmarks: mapBenchmarks
    };

}


export async function getRentsMes3m6m12m(cnpjs, benchmarks, referenceDate) {

    //RISCO
    ReqsComdinheiroAPI.logReq(REQ_COMDINHEIRO_TYPE.RISCO);

    const urlToCall = 'ComparaFundos001.php?&datas='
        + referenceDate
        + '&cnpjs=' + (cnpjs && cnpjs.length ? normalizeCnpjsToUrl(cnpjs) : '')
        + (benchmarks && benchmarks.length ? "+" + normalizeSimpleBenchmarksToUrl(benchmarks) : '')
        + '&indicadores=ret_mes_atual+ret_03m+ret_06m+ret_12m&num_casas=2&pc=nome_fundo&flag_transpor=1&enviar_email=0&mostrar_da=0&op01=tabela&oculta_cabecalho_sup=0&relat_alias_automatico=cmd_alias_01&utf8_encode=1'

    console.log("URL TO CALL getRentsMes3m6m12m: ", urlToCall);

    try {

        const response = await API.request(buildConfigComdinheiro("post", urlToCall));

        console.log("RESPONSE getRentsMes3m6m12m: ", response);

        const formattedResponse = formatRentsMes3m6m12m(response.data.resposta['tab-p0'].linha, cnpjs, benchmarks);

        console.log("RESPONSE ARRAY getRentsMes3m6m12m: ", formattedResponse);

        // if (formattedResponse) {
        if (formattedResponse) {

            return {
                success: true,
                body: formattedResponse
            };

        } else {

            return {
                success: false,
            };

        }

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response
        };

    }

}




