import moment from 'moment';
import { getBalancesAndSumTransactionsPortfolioInMonthByPeriod, getClientAccountsBalancesValidationsByYear, getClientInvestmentsBalancesValidationsByYearAndRegime, getClientLastQuota, getClientTitlesBalancesValidationsByYearAndRegime, listAllClients } from '../API/client';
import { getAllClientAssetsByRegime, getAllClientFunds, getClientFundsRentsByPeriod, getFundsInfosByFundIds, getRankingFundsPerClient } from '../API/fund';
import { getClientInflationRates } from '../API/inflation_rate';
import { listLegislationArticlesByDate, listLegislationSegmentsByDate } from '../API/legislation_article';
import {
    getAprWithClientDataByIdTransaction,
    getAprsWithClientDataByDateRange,
    getClientAllTransactionByDateRangeAndRegime,
    getSingleAprWithClientDataByIdTransaction,
    getSinglesAprsWithClientDataByYear,
    sumTransactionsInMonthByPeriod,
    sumTransactionsInPeriodByFundAndOperation
} from '../API/transaction';
import {
    applyMaskAccount,
    applyMaskAmount,
    applyMaskAmountComdinheiro,
    applyMaskAmountComdinheiroFreeDecimals,
    applyMaskAmountFreeDecimals,
    applyMaskHideCpf,
    formatAnbimaDateToPt,
    formatPtStringDateToAnbimaDate,
    formatStringToDisplay,
    formatTargetsAndRentsToShow,
    formatTextForValidDisplay,
    getDayMonthYearByDate,
    getDayMonthYearByPtDate,
    getDayMonthYearByStringDate,
    getFirstDayYear,
    getHistoricRentFundInPortfolio,
    getInMonthRentFundInPortfolio,
    getLastDayInMonthByMonthAndYear,
    getLastPtDayOfYear,
    getMonthAgo,
    getMonthAndYearBefore,
    getMonthFundRentInMoney,
    getMonthName,
    getPeriodBefore,
    getPeriodRentFundInPortfolio,
    getTitleCod,
    getYearPeriod,
    getYearRentFundInPortfolio,
    groupArticlePolicys,
    groupByDistributionOptions,
    makeChartCompare,
    makeChartEvolutionPL,
    markArticlesInPortfolio,
    portfolioClosedToDate,
    sortArrayOrderBy,
    splitIn2Lines,
    stringPtDateToJsDate,
    timeStampToPtDate,
    truncateDecimals,
    var5
} from '../components/utils/utils';
import { formatBalancesAssetsValidationToShow, getAssetInfos, getSimpleNormalizedAssets, organizeAssetsAndTransactions } from './AssetsController';
import { formatBalancesRentsByRange, formatTargetsToShow, loadClientDiaryByRange, loadClientDiaryPlsByLimit } from './ClientController';
import { doGetFundsClientByPeriodRent, formatMyFundsToShow, getFundsDemonstrative } from './FundController';

import _ from 'lodash';
import {
    BalancesValidationsAPI
} from '../API/balances_validations';
import { REQ_COMDINHEIRO_TYPE } from '../API/reqs_comdinheiro';
import { disponibilizationDicionary } from '../components/utils/dicionarys';
import { checkFinalLabelDistribuitionTable, distributionOptionClassification, isOptionValidToJustTwoWords, order } from '../components/utils/distribution';
import { MARKING_MARKET } from '../components/utils/paramsToTitles';
import { doGetDisponilityByMonthAndYear, formatBalancesAccountsValidationToShow } from './AccountController';
import { doGetCDI, doGetIGPM, doGetIPCAdp, doGetPU_med, doSelicMeta, getCotasByListCnpjs } from './ComdinheiroController';
import { fillLocalInfosByMonthAndYear, getClientJsonQuotas } from './FundLocalQuotasController';
import { GENERIC_ASSETS, GENERIC_ASSET_TITLES_TYPE, PRIVATE_TITLES, PUBLIC_TITLES, calculateRentTitles, formatInfosToShow, getAnaliticTitlesByPeriod, getDueDateTitle } from './TitleController';

//Import excel
import XLSX from 'xlsx';
import { downloadBlob, s2ab } from "../controllers/ExcelController";
import { getClientAssetsTitles, getTitlesByIds } from '../API/title';
import { getLocalQuotasByFundsIdsAndYear } from '../API/fund_local_quotas';
import { getClientAccountsByRegimeAndDate } from '../API/account';

//Zip
import JSZip from 'jszip';

const { device } = distributionOptionClassification;

export const acumRentsTitlesBySums = (titleType, balancesNowSums, balancesBeforeSums, trasactionsSums) => {

    return {
        //rentabildiade financeira
        rt1: getSimpleRentMoneyTitle2(
            balancesNowSums.titles[titleType],
            trasactionsSums.mapTitles[titleType]?.applications ?? 0,
            trasactionsSums.mapTitles[titleType]?.rescues ?? 0,
            trasactionsSums.mapTitles[titleType]?.amortizations ?? 0,
            balancesBeforeSums.titles[titleType] ?? 0
        ),

        //rentabildiade %
        rt2: getSimpleRentTitle2(
            balancesNowSums.titles[titleType],
            trasactionsSums.mapTitles[titleType]?.applications ?? 0,
            trasactionsSums.mapTitles[titleType]?.rescues ?? 0,
            trasactionsSums.mapTitles[titleType]?.amortizations ?? 0,
            balancesBeforeSums.titles[titleType] ?? 0
        )
    }

}

/**
 * Retorna os dados a serem utilizados na montsgem do relatório de carteira
 */
export async function getDataToPortfolioReport(clientId, startDate, endDate,
    accountRegimeId,
    periodReport,
    extraPortfolioReportParams) {
    const { month, year } = getDayMonthYearByStringDate(endDate);

    const { isAssetAndAccountsReport, isAccumulatedTrasactionsReport } = extraPortfolioReportParams;

    console.log('startDate, endDate: ', startDate, endDate);

    const isSpecificPeriodReport = periodReport && periodReport != 'mensal'; //Checando se é um report trimestal ou semestral

    //Obtendo informações dos ativos
    const keepWholeRescuesAssets = isAccumulatedTrasactionsReport || isSpecificPeriodReport;
    const responseInfosAssets = await getAssetInfos(clientId, month, year, accountRegimeId, false, null, keepWholeRescuesAssets, keepWholeRescuesAssets ? startDate : null);

    console.log('responseInfosAssets: ', responseInfosAssets);

    console.log('periodReport in portfolioReport: ', periodReport);
    let lastDiaryMonth = await loadClientDiaryByRange(clientId,
        month,
        year, isSpecificPeriodReport // removendo no caso de 12 pois se refere ao relatório mensal
        ? periodReport : 1);

    const getPortfolioAssetAccountLine = (asset) => {
        const fund_name = `CC: ${applyMaskAccount(asset.number_account)} ${asset.agency ? '/ Ag: ' + asset.agency : ''}`;

        return {
            fund_name,
            balanceToDisplay: applyMaskAmount(asset.balance_now, true),
            participation: asset.participation + "%",
            dados_cadastrais: {
                disponibilization: '-'
            },
            article_to_show: '-',
            rt1: '-',
            rt2: '-',
            isSubAsset: true
        }
    }

    if (responseInfosAssets.success) {

        let finalResultFunds = null
        let finalResultTitles = null
        let finalFundsBalancesByPeriod = null
        let finalTitlesRentMoneyAcum = null
        let finalIntegralFundsBalancesMap = null;
        if (isSpecificPeriodReport || isAccumulatedTrasactionsReport) {

            const { resultFunds, resultTitles, fundsBalancesByPeriod, titlesRentMoneyAcum, integralFundsBalancesMap } = await rentsByPeriod(clientId, startDate, endDate, null, 0);
            finalResultFunds = resultFunds;
            finalResultTitles = resultTitles;
            finalFundsBalancesByPeriod = fundsBalancesByPeriod;
            finalTitlesRentMoneyAcum = titlesRentMoneyAcum;
            finalIntegralFundsBalancesMap = integralFundsBalancesMap;
            console.log('resultFunds xx: ', resultFunds);
            console.log('resultTitles: ', resultTitles);
            console.log('finalTitlesRentMoneyAcum: ', finalTitlesRentMoneyAcum);

        }

        let dataToReport = [];
        let totalBalance = 0.0;
        let totalRentMoney = 0.0;
        let totalRentPercent = 1;


        const { monthBefore, yearBefore } = getMonthAndYearBefore(startDate);

        const promisesSums = [];

        promisesSums.push(new Promise(async (resolve, reject) => {

            const response = await sumTransactionsInPeriodByFundAndOperation(clientId, startDate, endDate);
            response.success ? resolve(response.data) : resolve(false);

        }))

        promisesSums.push(new Promise(async (resolve, reject) => {

            const response = await BalancesValidationsAPI.getSumBalancesByMonthAndYear(clientId, month, year);
            response.success ? resolve(response.data) : resolve(false);

        }))

        promisesSums.push(new Promise(async (resolve, reject) => {

            const response = await BalancesValidationsAPI.getSumBalancesByMonthAndYear(clientId, monthBefore, yearBefore);
            response.success ? resolve(response.data) : resolve(false);

        }))

        const reponsePromises = await Promise.all(promisesSums);

        // const trasactionsSums = await sumTransactionsInPeriodByFundAndOperation(clientId, startDate, endDate);
        // const balancesBeforeSums = await BalancesValidationsAPI.getSumBalancesByMonthAndYear(clientId, monthBefore, yearBefore);
        console.log('reponsePromises: ', reponsePromises)

        const trasactionsSums = reponsePromises[0];
        const balancesNowSums = reponsePromises[1];
        const balancesBeforeSums = reponsePromises[2];

        const { rt1: rt1Public, rt2: rt2Public } = acumRentsTitlesBySums("PUBLICO", balancesNowSums, balancesBeforeSums, trasactionsSums)
        const { rt1: rt1Private, rt2: rt2Private } = acumRentsTitlesBySums("PRIVADO", balancesNowSums, balancesBeforeSums, trasactionsSums)
        const { rt1: rt1Generic, rt2: rt2Generic } = acumRentsTitlesBySums(GENERIC_ASSET_TITLES_TYPE, balancesNowSums, balancesBeforeSums, trasactionsSums)

        if (isAccumulatedTrasactionsReport) {

            if (trasactionsSums) {

                const { mapFunds, mapTitles, total } = trasactionsSums;

                let totalBalanceNow = 0.0;
                let totalBalanceBefore = 0.0;
                responseInfosAssets.assetsFormatted.forEach(asset => {


                    const assetPortfolioLine = {}
                    assetPortfolioLine.balanceNowToDisplay = applyMaskAmount(asset.balance_now, true);
                    assetPortfolioLine.totalApplications = applyMaskAmount(0, true);
                    assetPortfolioLine.totalRescues = applyMaskAmount(0, true);

                    let rentMoney = asset.rentMoneyInMonth;

                    //Normalizando nome do título para fundo
                    if (asset.title_id) {
                        assetPortfolioLine.fund_name = getTitleCod(asset);
                    }

                    if (asset.fund_name) {
                        assetPortfolioLine.fund_name = asset.fund_name;
                    }

                    assetPortfolioLine.balanceBeforeToDisplay = applyMaskAmount(0, true);
                    //FUNDOS
                    if (asset.fund_id) {
                        rentMoney = finalResultFunds[asset.fund_id].rentMoneyAcum;
                        assetPortfolioLine.rt2 = applyMaskAmount(finalResultFunds[asset.fund_id].rentAcum) + '%';
                    } else {
                        let rt2 = 0;
                        if (asset.fund_name == PUBLIC_TITLES) {
                            rentMoney = rt1Public;
                            rt2 = rt2Public;

                            if (balancesBeforeSums.titles["PUBLICO"]) {
                                assetPortfolioLine.balanceBeforeToDisplay = applyMaskAmount(balancesBeforeSums.titles["PUBLICO"], true);
                                totalBalanceBefore += balancesBeforeSums.titles["PUBLICO"];
                            }

                            if (mapTitles["PUBLICO"]) {
                                assetPortfolioLine.totalApplications = applyMaskAmount(mapTitles["PUBLICO"].applications, true);
                                assetPortfolioLine.totalRescues = applyMaskAmount(mapTitles["PUBLICO"].rescues, true);
                            }

                        } else if (asset.fund_name == PRIVATE_TITLES) {
                            rentMoney = rt1Private;
                            rt2 = rt2Private;

                            if (balancesBeforeSums.titles["PRIVADO"]) {
                                assetPortfolioLine.balanceBeforeToDisplay = applyMaskAmount(balancesBeforeSums.titles["PRIVADO"], true);
                                totalBalanceBefore += balancesBeforeSums.titles["PRIVADO"];
                            }

                            if (mapTitles["PRIVADO"]) {
                                assetPortfolioLine.totalApplications = applyMaskAmount(mapTitles["PRIVADO"].applications, true);
                                assetPortfolioLine.totalRescues = applyMaskAmount(mapTitles["PRIVADO"].rescues, true);
                            }

                        } else {
                            //rentMoney = rt1Generic;
                            rentMoney = '-';
                            //rt2 = rt2Generic;
                            rt2 = '-';
                        }

                        assetPortfolioLine.rt2 = applyMaskAmount(rt2) + '%';
                    }

                    assetPortfolioLine.rt1 = applyMaskAmount(rentMoney, true)

                    totalRentMoney += rentMoney;
                    totalBalanceNow += parseFloat(asset.balance_now);

                    if (mapFunds[asset.fund_id]) {

                        const { applications, rescues } = mapFunds[asset.fund_id];

                        assetPortfolioLine.totalApplications = applyMaskAmount(applications, true);
                        assetPortfolioLine.totalRescues = applyMaskAmount(rescues, true);

                    }

                    if (asset.fund_id) {
                        if (balancesBeforeSums.funds[asset.fund_id]) {
                            assetPortfolioLine.balanceBeforeToDisplay = applyMaskAmount(balancesBeforeSums.funds[asset.fund_id], true);
                            totalBalanceBefore += balancesBeforeSums.funds[asset.fund_id];
                        }
                    }

                    if (asset?.dados_cadastrais?.administration_tax) {
                        assetPortfolioLine.administration_tax = applyMaskAmount(asset.dados_cadastrais.administration_tax) + '%';
                    } else {
                        assetPortfolioLine.administration_tax = '-';
                    }

                    dataToReport.push(assetPortfolioLine)
                })

                dataToReport = removingNoDataAcumAssetsTrasactionsReport(dataToReport);

                const totalPortfolioPeriodRent = applyMaskAmount(lastDiaryMonth.arrayRents && lastDiaryMonth.arrayRents.length === 1 ?
                    lastDiaryMonth.arrayRents[0].rent
                    :
                    (lastDiaryMonth.arrayRents && lastDiaryMonth.arrayRents.length > 1
                        ?
                        lastDiaryMonth.arrayRents[lastDiaryMonth.arrayRents.length - 1].rentAcum
                        :
                        0
                    ))

                dataToReport.push({
                    fund_name: 'Total investimentos',
                    balanceBeforeToDisplay: applyMaskAmount(totalBalanceBefore, true),
                    balanceNowToDisplay: applyMaskAmount(totalBalanceNow, true),
                    totalApplications: applyMaskAmount(total.applications, true),
                    totalRescues: applyMaskAmount(total.rescues, true),
                    rt1: applyMaskAmount(finalFundsBalancesByPeriod.rentMoneyAcum + finalTitlesRentMoneyAcum, true),
                    rt2: accountRegimeId ? '-' : (totalPortfolioPeriodRent + '%'),
                });
            }


        } else {
            responseInfosAssets.assetsFormatted.forEach(asset => {

                const assetPortfolioLine = {
                    dados_cadastrais: {

                    }
                }

                assetPortfolioLine.balanceToDisplay = applyMaskAmount(asset.balance_now, true);

                //const rentFundMonth = getInMonthRentFundInPortfolio(asset);
                const rentFundMonth = asset.rentPercentInMonth;

                let rentMoney = asset.rentMoneyInMonth;

                if (isSpecificPeriodReport) {

                    //FUNDOS
                    if (asset.fund_id) {
                        //console.log("Lendo fundo: ", asset);

                        //rentMoney = finalResultFunds[asset.fund_id].rentMoneyAcum;
                        rentMoney = finalIntegralFundsBalancesMap[asset.fund_id].rentMoneyAcum;

                        //assetPortfolioLine.rt2 = applyMaskAmount(finalResultFunds[asset.fund_id].rentAcum) + '%';
                        assetPortfolioLine.rt2 = applyMaskAmount(finalIntegralFundsBalancesMap[asset.fund_id].rentAcum) + '%';
                    } else {
                        //console.log("Lendo titulos: ", asset);
                        let rt2 = 0;
                        if (asset.fund_name == PUBLIC_TITLES) {

                            rentMoney = rt1Public;
                            rt2 = rt2Public;
                        } else if (asset.fund_name == PRIVATE_TITLES) {

                            rentMoney = rt1Private;
                            rt2 = rt2Private;
                        } else {

                            // rentMoney = rt1Generic;
                            // rt2 = rt2Generic;
                            rentMoney = '-';
                            rt2 = '-';
                        }

                        assetPortfolioLine.rt2 = applyMaskAmount(rt2) + '%';
                    }


                } else {

                    //RENT %
                    assetPortfolioLine.rt2 = applyMaskAmount(rentFundMonth) + "%"

                }

                if (asset.subAssetsTitles?.length) {
                    delete asset.subAssetsTitles;
                }

                //RENT R$
                assetPortfolioLine.rt1 = applyMaskAmount(rentMoney, true)

                totalRentMoney += rentMoney;

                totalBalance += parseFloat(asset.balance_now);

                assetPortfolioLine.participation = applyMaskAmount(asset.participation) + "%";

                //Caso o fundo seja especial ele não possui dados cadatrais, então inicializa-se a disponibilização com vazio pois é um campo utilizado 
                //no portfolio_report e daria null pointer caso dados_cadastrais não existissem e se tentasse acessar o atributo "disponibilization"

                if (asset.local_liquidity) {
                    assetPortfolioLine.dados_cadastrais.disponibilization = disponibilizationDicionary(asset.local_liquidity).label;
                } else if (asset.dados_cadastrais && asset.dados_cadastrais.disponibilization) {

                    assetPortfolioLine.dados_cadastrais.disponibilization = disponibilizationDicionary(asset.dados_cadastrais.disponibilization).label;

                } else {

                    if (asset?.dados_cadastrais?.dados_cadastrais?.disponibilization) {
                        assetPortfolioLine.dados_cadastrais = {
                            disponibilization: disponibilizationDicionary(asset.dados_cadastrais.dados_cadastrais.disponibilization).label,
                        }
                    } else {
                        assetPortfolioLine.dados_cadastrais = {
                            disponibilization: "-",
                        }
                    }
                }

                assetPortfolioLine.resolutionName = responseInfosAssets.resolutionName;
                assetPortfolioLine.article_to_show = asset.device_abbreviation ? asset.device_abbreviation : '-';

                if (asset?.dados_cadastrais?.administration_tax != null) {

                    assetPortfolioLine.administration_tax = applyMaskAmount(asset.dados_cadastrais.administration_tax) + '%';
                } else {
                    if (asset?.dados_cadastrais?.dados_cadastrais?.administration_tax != null) {
                        assetPortfolioLine.administration_tax = applyMaskAmount(asset.dados_cadastrais.dados_cadastrais.administration_tax) + '%';
                    } else {
                        assetPortfolioLine.administration_tax = '-';
                    }
                }

                //Normalizando nome do título para fundo
                if (asset.title_id) {
                    assetPortfolioLine.fund_name = getTitleCod(asset);
                }

                if (asset.fund_name == PUBLIC_TITLES || asset.fund_name == PRIVATE_TITLES) {
                    assetPortfolioLine.isTitlesLine = true;
                    assetPortfolioLine.balanceNow = asset.balance_now;
                }

                if (asset.fund_name) {
                    //assetPortfolioLine.fund_name = formatStringToDisplay(asset.fund_name, 6, '...');
                    assetPortfolioLine.fund_name = asset.fund_name;
                }

                dataToReport.push(assetPortfolioLine);

                //Relatório de ativos e contas
                if (isAssetAndAccountsReport && asset.fund_id) {

                    assetPortfolioLine.cnpj = asset.cnpj;
                    if (asset.subAssets?.length) {
                        asset.subAssets.map((subAsset) => {
                            dataToReport.push(getPortfolioAssetAccountLine(subAsset))
                        });
                    } else {
                        dataToReport.push(getPortfolioAssetAccountLine(asset));
                    }
                }

            });

            dataToReport = removingNoDataAcumAssets(dataToReport);

            //corrigindo participação de linhas de título
            dataToReport.filter(el => el.isTitlesLine).forEach((titleLine) => {
                titleLine.participation = applyMaskAmount((titleLine.balanceNow / totalBalance) * 100) + "%";
            });

            const totalPortfolioPeriodRent = applyMaskAmount(lastDiaryMonth.arrayRents && lastDiaryMonth.arrayRents.length === 1 ?
                lastDiaryMonth.arrayRents[0].rent
                :
                (lastDiaryMonth.arrayRents && lastDiaryMonth.arrayRents.length > 1
                    ?
                    lastDiaryMonth.arrayRents[lastDiaryMonth.arrayRents.length - 1].rentAcum
                    :
                    0
                ))

            dataToReport.push({
                fund_name: 'Total investimentos',
                balanceToDisplay: applyMaskAmount(totalBalance, true),
                participation: '100.00%',
                rt1: isSpecificPeriodReport ? applyMaskAmount(finalFundsBalancesByPeriod.rentMoneyAcum + finalTitlesRentMoneyAcum, true) : applyMaskAmount(totalRentMoney, true),
                //retornando '-' para quando for filtro de segregação
                rt2: accountRegimeId ? '-' : (totalPortfolioPeriodRent + '%'),
                dados_cadastrais: {
                    disponibilization: "",
                }
            });

            dataToReport.push({
                fund_name: 'Disponibilidade',
                balanceToDisplay: applyMaskAmount(responseInfosAssets.totalDisponibilityInAccounts != null
                    ? responseInfosAssets.totalDisponibilityInAccounts.now : 0, true),
                participation: '-',
                rt1: '-',
                rt2: '-',
                dados_cadastrais: {
                    disponibilization: "",
                }
            });

            dataToReport.push({
                fund_name: 'Total patrimônio',
                balanceToDisplay: applyMaskAmount(totalBalance + (responseInfosAssets.totalDisponibilityInAccounts != null
                    ? responseInfosAssets.totalDisponibilityInAccounts.now : 0), true),
                participation: '100.00%',
                rt1: '-',
                rt2: '-',
                dados_cadastrais: {
                    disponibilization: "",
                }
            });

            //clean object to stream, retirando subassets e subAssetTitles
            dataToReport.forEach(element => {
                if (element.subAssetsTitles) {
                    delete element.subAssetsTitles
                }

                if (element.subAssets) {
                    delete element.subAssets
                    delete element.quotas_anbima
                    delete element.quotas_anbima_before
                }

                if (element.fund_name === GENERIC_ASSETS) {
                    element.rt1 = '-';
                    element.rt2 = '-';
                }
            });
        }

        console.log("dataToPortfolioReport: ", dataToReport);

        return {
            success: true,
            dataToPortfolioReport: dataToReport,
        }

    } else {
        return {
            success: false,
        }
    }

};

export async function getDataToFundsRankingReport() {

    let fundsRankingResponse = await getRankingFundsPerClient()
    let allClientsResponse = await listAllClients()

    if (fundsRankingResponse.success && allClientsResponse.success) {
        const totalClientsNumber = allClientsResponse.body.rowCount

        for (let i = 0; i < fundsRankingResponse.body.rowCount; i++) {
            const currentPercentage = applyMaskAmount((fundsRankingResponse.body.rows[i].number_of_clients / totalClientsNumber) * 100) + '%'
            fundsRankingResponse.body.rows[i].percentage = currentPercentage
        }

        return {
            isSuccessful: true,
            dataToFundsRankingReport: fundsRankingResponse.body.rows
        }
    } else {
        return {
            isSuccessful: false
        }
    }
};

export async function getDataToDemonstrativeReport(client, month, year) {

    const response = await getFundsDemonstrative(client.id, month, year)
    // const response = {}
    // response.success = true
    // response.funds = fundsTest;

    if (response.success) {

        //formatando
        response.funds.forEach(element => {

            element.totalApplication = applyMaskAmount(element.totalApplication);
            element.totalRescue = applyMaskAmount(element.totalRescue);
            element.participation = applyMaskAmount(element.participation) + '%';
            element.rentMoneyInMonth = applyMaskAmount(element.rentMoneyInMonth, true) + " (" + applyMaskAmount(element.rentPercentInMonth, null, true) + '%)';
            element.balance_now = applyMaskAmount(element.balance_now);
            element.quota_amount_now = applyMaskAmount(element.quota_amount_now);


            element.risco_retorno.cota = applyMaskAmountComdinheiroFreeDecimals(element.risco_retorno.cota);
            element.dados_cadastrais.pl_anbima = applyMaskAmountComdinheiro(element.dados_cadastrais.pl_anbima)
            element.dados_cadastrais.num_cotistas = applyMaskAmountComdinheiro(element.dados_cadastrais.num_cotistas)
            element.risco_retorno.volatilidade = applyMaskAmountComdinheiro(element.risco_retorno.volatilidade)
            element.risco_retorno.perda_maxima = applyMaskAmountComdinheiro(element.risco_retorno.perda_maxima)
            element.risco_retorno.var = applyMaskAmountComdinheiro(element.risco_retorno.var)
            element.risco_retorno.indice_sharpe = applyMaskAmountComdinheiro(element.risco_retorno.indice_sharpe)
            element.risco_retorno.retorno_maximo = applyMaskAmountComdinheiro(element.risco_retorno.retorno_maximo)
            element.risco_retorno.retorno_minimo = applyMaskAmountComdinheiro(element.risco_retorno.retorno_minimo)
            element.risco_retorno.retorno_medio = applyMaskAmountComdinheiro(element.risco_retorno.retorno_medio)

            element.benchmark = element.benchmark ? element.benchmark : "-";

            if (element.risco_retorno_benchmark) {
                element.risco_retorno_benchmark.volatilidade = element.risco_retorno_benchmark && element.risco_retorno_benchmark.volatilidade ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.volatilidade) : '-'
                element.risco_retorno_benchmark.perda_maxima = element.risco_retorno_benchmark && element.risco_retorno_benchmark.perda_maxima ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.perda_maxima) : '-'
                element.risco_retorno_benchmark.var = element.risco_retorno_benchmark && element.risco_retorno_benchmark.var ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.var) : '-'
                element.risco_retorno_benchmark.indice_sharpe = element.risco_retorno_benchmark && element.risco_retorno_benchmark.indice_sharpe ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.indice_sharpe) : '-'
                element.risco_retorno_benchmark.retorno_maximo = element.risco_retorno_benchmark && element.risco_retorno_benchmark.retorno_maximo ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.retorno_maximo) : '-'
                element.risco_retorno_benchmark.retorno_minimo = element.risco_retorno_benchmark && element.risco_retorno_benchmark.retorno_minimo ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.retorno_minimo) : '-'
                element.risco_retorno_benchmark.retorno_medio = element.risco_retorno_benchmark && element.risco_retorno_benchmark.retorno_medio ? applyMaskAmountComdinheiro(element.risco_retorno_benchmark.retorno_medio) : '-'
            } else {

                element.risco_retorno_benchmark = {};
                element.risco_retorno_benchmark.volatilidade = '-'
                element.risco_retorno_benchmark.perda_maxima = '-'
                element.risco_retorno_benchmark.var = '-'
                element.risco_retorno_benchmark.indice_sharpe = '-'
                element.risco_retorno_benchmark.retorno_maximo = '-'
                element.risco_retorno_benchmark.retorno_minimo = '-'
                element.risco_retorno_benchmark.retorno_medio = '-'
            }


        });

        response.funds.push(response.funds[response.funds.length - 1])

        return {
            success: true,
            dataToReport: response.funds,
        }

    } else {
        return {
            success: false,
        }
    }
};

function removingNoDataAcumAssetsTrasactionsReport(assets) {

    const newAssets = [];
    const zeroString = "R$ 0,00";

    assets.forEach(element => {
        const {
            balanceNowToDisplay,
            balanceBeforeToDisplay,
            totalApplications,
            totalRescues,
            rt1
        } = element;

        if (balanceNowToDisplay === zeroString
            && balanceBeforeToDisplay === zeroString
            && totalApplications === zeroString
            && totalRescues === zeroString
            && rt1 === zeroString
        ) {
            return;
        } else {
            newAssets.push(element)
        }
    });

    return newAssets;

}

function removingNoDataAcumAssets(assets) {

    const newAssets = [];
    const zeroAmountString = "R$ 0,00";
    const zeroPercentString = "0,00%";


    assets.forEach(element => {

        const {
            balanceToDisplay,
            rt1,
            rt2,
            participation
        } = element;

        if (balanceToDisplay === zeroAmountString
            && participation === zeroPercentString
            && rt1 === zeroAmountString
            && rt2 === zeroPercentString
        ) {
            return;
        } else {
            newAssets.push(element)
        }
    });

    return newAssets;

}

function compareSortByPercent(a, b) {
    if (a.percent < b.percent)
        return 1;
    if (a.percent > b.percent)
        return -1;
    return 0;
}

//export async function getDataToDistribuitionReport(clientId, month, year) {
/**
 *
 * @param {number} clientId
 * @param {string} startDate
 * @param {string} endDate
 * @typedef {{
*  dataToTable: {
    *  c1: string;
    *  c2: string;
    *  c3: string;
    *  c4: string;
    *  percent: number;
    * }[];
    *  dataToChart: {
    *  datasets: import('chart.js').ChartDataSets[];
    *  labels: string[];
    *  }
    * }} DataToReport
    * @returns {Promise<{
       success: boolean;
       dataToDistributionReport?: DataToReport[];
     }}
    */
export async function getDataToDistribuitionReport(
    clientId,
    startDate,
    endDate
) {

    const { month, year } = getDayMonthYearByStringDate(endDate);

    let responseInfosAssets = await getSimpleNormalizedAssets(clientId,
        month,
        year,
        null)

    if (responseInfosAssets.success) {

        let dataToDistributionReport = [];
        Object.entries(distributionOptionClassification).forEach(optionArray => {
            let selectedDistributionOptionLabel = optionArray[1];

            let JUST_TWO_WORDS = isOptionValidToJustTwoWords(selectedDistributionOptionLabel);

            //Acrescentando para não quebrar relatório
            if (selectedDistributionOptionLabel == distributionOptionClassification.manager_anbima
                || selectedDistributionOptionLabel == distributionOptionClassification.administrator_anbima) {
                JUST_TWO_WORDS = true;
            }

            let distributionData = groupByDistributionOptions(responseInfosAssets.assets, selectedDistributionOptionLabel, JUST_TWO_WORDS, null, stringPtDateToJsDate(endDate));

            let getFormatedDistributionDataToReport = () => {
                let generateTableRows = () => {
                    let indexColor = 0;
                    Object.entries(assetClasses).map(([label, row]) => {
                        let formatedLabel = JUST_TWO_WORDS ? formatTextForValidDisplay(label, 2) : label;

                        dataToTable.push({
                            c1: checkFinalLabelDistribuitionTable(formatedLabel),
                            percent: row.percent,
                            c2: applyMaskAmount(row.percent),
                            c3: applyMaskAmount(row.amount, true),
                            c4: 'legColor chartColor' + indexColor, //Ao alterar as cores no unoTheme também deve-se alterar as classes no header lá do servidor de relatórios
                        });
                        indexColor++;
                    });
                };

                let addTotalRow = () => {
                    dataToTable.push({
                        c1: 'totalLine',
                        c2: '100,00',
                        c3: applyMaskAmount(totalAmount, true),
                    });
                };

                let setDefaultSortToTable = () => {
                    let isSelectedDistributionOptionDevice = selectedDistributionOptionLabel == device;

                    if (isSelectedDistributionOptionDevice) {
                        dataToTable = sortArrayOrderBy(dataToTable, 'label', order.DESC);
                    } else {
                        dataToTable = sortArrayOrderBy(dataToTable, 'valuePercent', order.ASC);
                    }
                };

                let updateDistributionDataObjectFormat = () => {
                    distributionData.dataToTable.data = dataToTable;
                    delete distributionData.dataToFormat;
                }

                let { assetClasses, totalAmount } = distributionData.dataToFormat;
                let dataToTable = [];

                generateTableRows();

                //Ordenar dataToTale por percentual 
                dataToTable.sort(compareSortByPercent)

                addTotalRow();
                setDefaultSortToTable();
                updateDistributionDataObjectFormat();

                return distributionData;
            };

            let dataToReport = getFormatedDistributionDataToReport();

            dataToDistributionReport.push(dataToReport);
        });

        return {
            success: true,
            dataToDistributionReport,
        }

    } else {
        return {
            success: false,
        }
    }

};

/**
 * Retorna os dados a serem utilizados na montagem do relatório de metas
 */
//export async function getDataToTargetsReport(clientId, month, year) {
export async function getDataToTargetsReport(client, startDate, endDate, periodReport) {

    const clientId = client.id

    const { month, year } = getDayMonthYearByStringDate(endDate);
    const response = await getClientInflationRates(clientId);
    if (response.success) {


        //Novo método balance
        let diarys = await loadClientDiaryByRange(clientId,
            month,
            year, periodReport == 'mensal' ? null : periodReport); //null para trazer tudo desde o inicio

        console.log("DIARIOS: ", diarys.rentMonths);

        const responseClientTransactions = await getClientAllTransactionByDateRangeAndRegime(
            clientId,
            timeStampToPtDate(client.portfolio_init),
            timeStampToPtDate(portfolioClosedToDate(client.portfolio_closed))
        );
        let clientTransactions = null;
        if (responseClientTransactions.success) {
            clientTransactions = responseClientTransactions.body.rows;
        }

        let dataToReport = formatTargetsAndRentsToShow(response.body.rows, diarys.rentMonths, diarys.mapAccountsBalances, diarys.mapAssetsBalances, clientTransactions);

        //formatando para relatório
        let arrayToReport = [];
        dataToReport.forEach(yearTarget => {

            if (yearTarget.finalTargetsToShow && yearTarget.finalTargetsToShow.length > 0) {

                //Linha do ano
                arrayToReport.push({
                    period: yearTarget.year,
                    balance_before: "year_line",
                    balance_now: "",
                    target: yearTarget.use_ipca ? 'IPCA + ' + yearTarget.tax + "% a.a." : 'INPC + ' + yearTarget.tax + "% a.a.",
                    rent: "",
                    gap: "",
                })


                //Linhas dos meses
                yearTarget.finalTargetsToShow.forEach(monthTarget => {
                    arrayToReport.push({
                        period: getMonthName(monthTarget.month),
                        balance_before: applyMaskAmount(monthTarget.balance_before, true),
                        balance_now: applyMaskAmount(monthTarget.balance_now, true),
                        target: monthTarget.use_ipca ? applyMaskAmount(monthTarget.taxMonthIpca) + "%" : applyMaskAmount(monthTarget.taxMonthInpc) + "%",
                        rent_money: applyMaskAmount(monthTarget.rentMonthMoney, true),
                        rent_percent: applyMaskAmount(monthTarget.rentPercent) + "%",
                        gap: monthTarget.use_ipca ?
                            applyMaskAmount(monthTarget.rentPercent - monthTarget.taxMonthIpca) + "p.p."
                            :
                            applyMaskAmount(monthTarget.rentPercent - monthTarget.taxMonthInpc) + "p.p.",
                    })
                });

                //Linha total dos meses do ano
                arrayToReport.push({
                    period: 'Total',
                    balance_before: applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].balance_before, true),
                    balance_now: applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].balance_now, true),
                    target: yearTarget.use_ipca ? applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumIpcaYear) + "%" : applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumInpcYear) + "%",
                    //rent_acum: applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcum) + "%",
                    rent_money: applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentMoneyAcumYear, true),
                    rent_percent: applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcumYear) + "%",
                    //gap: yearTarget.use_ipca ? applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcum - yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumIpca) + "%" : applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcum - yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumIpca) + "%",
                    //gap: yearTarget.use_ipca ? applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumIpcaYear - yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcumYear) + "%" : applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumInpcYear - yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcumYear) + "%",
                    gap: yearTarget.use_ipca ?
                        applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcumYear - yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumIpcaYear) + "p.p."
                        :
                        applyMaskAmount(yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].rentAcumYear - yearTarget.finalTargetsToShow[yearTarget.finalTargetsToShow.length - 1].taxAcumInpcYear) + "p.p.",
                })
            }



        });
        console.log("DATA TO REPORT TARGETS: ", arrayToReport);

        return {
            success: true,
            dataToReport: arrayToReport,
        }

    } else {
        console.log("RESPONSE ERROR AO GERAR RELATÓRIO DE METAS: ", response.error)
        return {
            success: false,
        }
    }

};

/**
 * Retorna os dados a serem utilizados na montagem do relatório de meus fundos
 */
//export async function getDataToDashboardReport(clientId, month, year, periodReport) {
export async function getDataToDashboardReport(clientId, startDate, endDate, periodReport) {

    console.log('periodReportxxx: ', periodReport);

    const { month, year } = getDayMonthYearByStringDate(endDate);

    let oficialPeriodReport = null;
    if (periodReport == 24 || periodReport == 36) {
        oficialPeriodReport = 12;
    } else if (periodReport == 'mensal') {
        oficialPeriodReport = 'ano';
    } else {
        oficialPeriodReport = periodReport; //ano
    }

    let diarys = await loadClientDiaryByRange(clientId,
        month,
        year, oficialPeriodReport);

    const rentsToVar = await loadClientDiaryPlsByLimit(clientId, month, year, 252); //LimitToVar

    const dataToChartEvolution = makeChartEvolutionPL(diarys.mapAssetsBalances, diarys.mapAccountsBalances, diarys.periodToRemove);

    //Obtendo o valor máximo para utilizar nos ticks do gráfico
    let maxValue = 0;
    if (dataToChartEvolution && dataToChartEvolution.datasets && dataToChartEvolution.datasets.length == 1) {
        dataToChartEvolution.datasets[0].data.forEach(element => {

            if (element.y > maxValue) {
                maxValue = element.y;
            }

        });
    }


    const rentMonthActual = diarys.rentMonths[month + "/" + year];
    console.log('rentMonthActual: ', rentMonthActual);

    const responseInflates = await getClientInflationRates(clientId);

    let formattedTargets = formatTargetsToShow(responseInflates.body.rows, diarys.rentMonths);
    formattedTargets.reverse();

    //setRentsPeriod(diarys.arrayRents);
    //setTargetsPeriod(formattedTargets);

    const dataToChartCompare = makeChartCompare(diarys.arrayRents, formattedTargets, 0); //terceiro parametros o type do gráfico 0 para mensal e 1 para acumulado        

    let targetActualMonth = null;
    for (let i = 0; i < formattedTargets.length; i++) {
        let target = formattedTargets[i];
        if (target.month == month && target.year == year) {
            targetActualMonth = target;
            break;
        }
    }


    let keyActualMonth = month + "/" + year;
    // console.log('diarys.mapAccountsBalances: ', diarys.mapAccountsBalances);
    // console.log('keyActualMonth: ', keyActualMonth);
    // console.log('diarys.mapAccountsBalances[keyActualMonth]: ', diarys.mapAccountsBalances[keyActualMonth]);
    // console.log('NEW PL: ', diarys.rentMonths[keyActualMonth].diarys[diarys.rentMonths[keyActualMonth].diarys.length - 1].new_pl);

    const { mapAssetsBalances } = diarys;
    //informações inciais
    let topData = {
        //plMonth: applyMaskAmount(parseFloat(diarys.rentMonths[keyActualMonth].diarys[diarys.rentMonths[keyActualMonth].diarys.length - 1].new_pl) + (diarys.mapAccountsBalances[keyActualMonth] ? diarys.mapAccountsBalances[keyActualMonth] : 0), true),
        plMonth: applyMaskAmount(mapAssetsBalances[keyActualMonth]
            + (diarys.mapAccountsBalances[keyActualMonth] ? diarys.mapAccountsBalances[keyActualMonth] : 0), true),
        rentMonth: rentMonthActual.rent,
        rentPorcentMonth: rentMonthActual.rent,
        rentPorcentAcum: rentMonthActual.rentAcum,

        targetMonth: targetActualMonth ? (targetActualMonth.use_ipca ? targetActualMonth.taxMonthIpca : targetActualMonth.taxMonthInpc) : null,
        targetAccumulated: targetActualMonth ? (targetActualMonth.use_ipca ? targetActualMonth.taxAcumIpca : targetActualMonth.taxAcumInpc) : null,
    }

    topData = {
        ...topData,
        rentPorcentMonth: applyMaskAmount(topData.rentPorcentMonth) + "%",
        rentPorcentAcum: applyMaskAmount(topData.rentPorcentAcum) + "%",
        targetMonth: topData.targetMonth ? applyMaskAmount(topData.targetMonth) + "%" : '-',
        targetAccumulated: topData.targetAccumulated ? applyMaskAmount(topData.targetAccumulated) + "%" : '-',
        gapMonth: applyMaskAmount(topData.rentMonth - topData.targetMonth) + "p.p.",
        gapAccumulated: applyMaskAmount(topData.rentPorcentAcum - topData.targetAccumulated) + "p.p.",
        var: rentsToVar ? applyMaskAmount(var5(rentsToVar)) + '%' : '-',

        subIndicatorValue1: (topData.rentMonth >= 0 ? "+ " : '') + applyMaskAmount(topData.rentMonth) + "%",
        subIndicatorValue2: '-',

        //labelLast12months: oficialPeriodReport == 12, //Caso o periodo seja de 12 meses colocar o label ao lado dos títulos dos gráficos na dashboard
        labelLast12months: false, //removido pós mudança na forma de gerar o report mensal
    }

    console.log("TOP DATA: ", topData);

    return {
        success: true,
        maxValue: maxValue, //utilizado para aumentar o range dos ticks, evitar que o valor da barra seja cortado
        dataToReport: topData,
        dataToChartEvolution: dataToChartEvolution,
        dataToChartCompare: dataToChartCompare,
    }

};

/**
 * Retorna os dados a serem utilizados na montagem do relatório de meus fundos
 */
//export async function getDataToMyFundsReport(client, month, year, segmentId) {
export async function getDataToMyFundsReport(client, startDate, endDate, segmentId, periodReport) {

    const { month, year } = getDayMonthYearByStringDate(endDate);
    //const responseFunds = await getAllClientFunds(clientId);

    //const responseFunds = await doGetFullFundsClientByPeriodRent(client, month, year, segmentId, '')
    const monthYearStartDate = getDayMonthYearByStringDate(startDate)
    const monthYearEndDate = getDayMonthYearByStringDate(endDate)

    const theYear = monthYearStartDate.year;

    //Mensal, default
    if (periodReport == 'mensal') {

        startDate = moment.utc(client.portfolio_init).format('DD/MM/YYYY');

    }

    const currentPeriod = month + '/' + year;
    const { month: initMonth, year: initYear } = getDayMonthYearByDate(moment.utc(client.portfolio_init));
    const responseRents = await rentsByPeriod(client.id, startDate, endDate, null, 0);

    if (responseRents.integralFundsBalancesMap) {

        const responseFundsInfos = await getFundsInfosByFundIds(Object.keys(responseRents.integralFundsBalancesMap));

        if (responseFundsInfos.success) {

            const mapFundsInfos = {};
            responseFundsInfos.body.rows.forEach(el => {
                mapFundsInfos[el.id] = el;
            })

            const fundsToShow = formatMyFundsToShow(responseRents.integralFundsBalancesMap, currentPeriod, mapFundsInfos, periodReport);

            let arrayToReport = [];

            //if (periodReport == 12) {
            if (periodReport == 'mensal') {

                fundsToShow.forEach(fund => {
                    arrayToReport.push({
                        fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                        cnpj: fund.cnpj,
                        rent_month: fund.rentMonth ? applyMaskAmount(fund.rentMonth, null, true) + "%" : '-',
                        rent_year: fund.rentYear ? applyMaskAmount(fund.rentYear, null, true) + "%" : '-',
                        rent_12m: fund.rent12m ? applyMaskAmount(fund.rent12m, null, true) + "%" : '-',
                        rent_24m: fund.rent24m ? applyMaskAmount(fund.rent24m, null, true) + "%" : '-',
                        rent_since_init: fund.rentHistoric ? applyMaskAmount(fund.rentHistoric, null, true) + "%" : '-',
                    })
                });
            } else if (periodReport == 3) {

                console.log('fundsToShow: ', fundsToShow)

                fundsToShow.forEach(fund => {

                    arrayToReport.push({
                        fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                        cnpj: fund.cnpj,
                        rent_m1: fund.rentM1 ? applyMaskAmount(fund.rentM1, null, true) + "%" : '-',
                        rent_m2: fund.rentM2 ? applyMaskAmount(fund.rentM2, null, true) + "%" : '-',
                        rent_m3: fund.rentM3 ? applyMaskAmount(fund.rentM3, null, true) + "%" : '-',
                        rent_tri: fund.rentHistoric ? applyMaskAmount(fund.rentHistoric, null, true) + "%" : '-',
                    })

                });

            } else if (periodReport == 6) {


                fundsToShow.forEach(fund => {

                    arrayToReport.push({
                        fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                        cnpj: fund.cnpj,
                        rent_tri1: fund.rentTri1 ? applyMaskAmount(fund.rentTri1, null, true) + "%" : '-',
                        rent_tri2: fund.rentTri2 ? applyMaskAmount(fund.rentTri2, null, true) + "%" : '-',
                        rent_semester: fund.rentHistoric ? applyMaskAmount(fund.rentHistoric, null, true) + "%" : '-',
                    })

                });
            } else if (periodReport == 'ano') {

                fundsToShow.forEach(fund => {

                    arrayToReport.push({
                        fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                        cnpj: fund.cnpj,
                        rent_sem1: fund.rentSem1 ? applyMaskAmount(fund.rentSem1, null, true) + "%" : '-',
                        rent_sem2: fund.rentSem2 ? applyMaskAmount(fund.rentSem2, null, true) + "%" : '-',
                        rent_year: fund.rentHistoric ? applyMaskAmount(fund.rentHistoric, null, true) + "%" : '-',
                    })

                });
            }

            return {
                success: true,
                dataToReport: arrayToReport,
            }

        }

        return {
            success: false,
        }


    } else {

        console.log("RESPONSE ERROR AO GERAR RELATÓRIO DE FUNDOS")
        return {
            success: false,
        }
    }

};

/**
 * Retorna os dados a serem utilizados na montagem do relatório de meus fundos
 */
//export async function getDataToMyFundsReport(client, month, year, segmentId) {
export async function getDataToMyFundsReportOld(client, startDate, endDate, segmentId, periodReport) {

    const { month, year } = getDayMonthYearByStringDate(endDate);
    //const responseFunds = await getAllClientFunds(clientId);

    //const responseFunds = await doGetFullFundsClientByPeriodRent(client, month, year, segmentId, '')
    const monthYearStartDate = getDayMonthYearByStringDate(startDate)
    const monthYearEndDate = getDayMonthYearByStringDate(endDate)

    //Mensal, default
    if (periodReport == 'mensal') {

        startDate = moment.utc(client.portfolio_init).format('DD/MM/YYYY');

    }

    const responseFunds = await doGetFundsClientByPeriodRent(client, startDate, endDate, '')
    if (responseFunds.success) {

        console.log("FUNDS: ", responseFunds.data);
        let arrayToReport = [];
        console.log("DATA TO REPORT: ", arrayToReport);

        //const responseFundsData = responseFunds.body.rows;
        const responseFundsData = responseFunds.data;

        //await formatBalancesRentsByRange(funds, clientId, 24);
        //await formatRentsFundsByRange(responseFundsData, clientId);

        //if (periodReport == 12) {
        if (periodReport == 'mensal') {
            responseFundsData.forEach(fund => {

                const rentMonth = getInMonthRentFundInPortfolio(fund);
                const rentYear = getYearRentFundInPortfolio(fund.rentsFunds, year);
                const rent12m = getPeriodRentFundInPortfolio(fund.rentsFunds, 12);
                const rent24m = getPeriodRentFundInPortfolio(fund.rentsFunds, 24);
                const rentSinceInit = getHistoricRentFundInPortfolio(fund);

                arrayToReport.push({
                    fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                    cnpj: fund.cnpj,
                    rent_month: rentMonth ? applyMaskAmount(rentMonth, null, true) + "%" : '-',
                    rent_year: rentYear ? applyMaskAmount(rentYear, null, true) + "%" : '-',
                    rent_12m: rent12m ? applyMaskAmount(rent12m, null, true) + "%" : '-',
                    rent_24m: rent24m ? applyMaskAmount(rent24m, null, true) + "%" : '-',
                    rent_since_init: rentSinceInit ? applyMaskAmount(rentSinceInit, null, true) + "%" : '-',
                })

            });
        } else if (periodReport == 3) {

            const m1 = monthYearStartDate.month;
            const m2 = m1 + 1;
            const m3 = m2 + 1;
            responseFundsData.forEach(fund => {

                const mapMonthRentFund = {};
                fund.rentsFunds.forEach(element => {
                    //console.log("ELEMENT: ", element);
                    mapMonthRentFund[element.month] = element;
                });
                //console.log('mapMonthRentFund: ', mapMonthRentFund);

                const rentM1 = mapMonthRentFund[m1] ? getInMonthRentFundInPortfolio(mapMonthRentFund[m1]) : null;
                const rentM2 = mapMonthRentFund[m2] ? getInMonthRentFundInPortfolio(mapMonthRentFund[m2]) : null;
                const rentM3 = mapMonthRentFund[m3] ? getInMonthRentFundInPortfolio(mapMonthRentFund[m3]) : null;
                const rentTri = getPeriodRentFundInPortfolio(fund.rentsFunds, 3, true);

                arrayToReport.push({
                    fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                    cnpj: fund.cnpj,
                    rent_m1: rentM1 ? applyMaskAmount(rentM1, null, true) + "%" : '-',
                    rent_m2: rentM2 ? applyMaskAmount(rentM2, null, true) + "%" : '-',
                    rent_m3: rentM3 ? applyMaskAmount(rentM3, null, true) + "%" : '-',
                    rent_tri: rentTri ? applyMaskAmount(rentTri, null, true) + "%" : '-',
                })

            });
        } else if (periodReport == 6) {

            const m1 = monthYearStartDate.month;
            const m2 = m1 + 1;
            const m3 = m2 + 1;
            const m4 = m3 + 1;
            const m5 = m4 + 1;
            const m6 = m5 + 1;

            responseFundsData.forEach(fund => {

                const mapMonthRentFund = {};
                fund.rentsFunds.forEach(element => {
                    //console.log("ELEMENT: ", element);
                    mapMonthRentFund[element.month] = element;
                });


                const tri1RentsFund = [mapMonthRentFund[m1], mapMonthRentFund[m2], mapMonthRentFund[m3]];
                const tri2RentsFund = [mapMonthRentFund[m4], mapMonthRentFund[m5], mapMonthRentFund[m6]];

                const rentTri1 = getPeriodRentFundInPortfolio(tri1RentsFund, 3, true);
                const rentTri2 = getPeriodRentFundInPortfolio(tri2RentsFund, 3, true);
                const rentSemester = getPeriodRentFundInPortfolio(fund.rentsFunds, 6, true);

                arrayToReport.push({
                    fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                    cnpj: fund.cnpj,
                    rent_tri1: rentTri1 ? applyMaskAmount(rentTri1, null, true) + "%" : '-',
                    rent_tri2: rentTri2 ? applyMaskAmount(rentTri2, null, true) + "%" : '-',
                    rent_semester: rentSemester ? applyMaskAmount(rentSemester, null, true) + "%" : '-',
                })

            });
        } else if (periodReport == 'ano') {

            responseFundsData.forEach(fund => {

                const mapMonthRentFund = {};
                fund.rentsFunds.forEach(element => {
                    //console.log("ELEMENT: ", element);
                    mapMonthRentFund[element.month] = element;
                });


                const sem1RentsFund = [
                    mapMonthRentFund[1],
                    mapMonthRentFund[2],
                    mapMonthRentFund[3],
                    mapMonthRentFund[4],
                    mapMonthRentFund[5],
                    mapMonthRentFund[6]
                ];

                const sem2RentsFund = [
                    mapMonthRentFund[7],
                    mapMonthRentFund[8],
                    mapMonthRentFund[9],
                    mapMonthRentFund[10],
                    mapMonthRentFund[11],
                    mapMonthRentFund[12]
                ];

                const rentSem1 = sem1RentsFund.length ? getPeriodRentFundInPortfolio(sem1RentsFund, 6, true) : null;
                const rentSem2 = sem2RentsFund.length ? getPeriodRentFundInPortfolio(sem2RentsFund, 6, true) : null;
                const rentYear = getPeriodRentFundInPortfolio(fund.rentsFunds, 12, true);

                arrayToReport.push({
                    fund_name: fund.fund_name ? formatStringToDisplay(fund.fund_name, 6, '...') : '-',
                    cnpj: fund.cnpj,
                    rent_sem1: rentSem1 ? applyMaskAmount(rentSem1, null, true) + "%" : '-',
                    rent_sem2: rentSem2 ? applyMaskAmount(rentSem2, null, true) + "%" : '-',
                    rent_year: rentYear ? applyMaskAmount(rentYear, null, true) + "%" : '-',
                })

            });
        }



        return {
            success: true,
            dataToReport: arrayToReport,
        }

    } else {
        console.log("RESPONSE ERROR AO GERAR RELATÓRIO DE FUNDOS: ", responseFunds.error)
        return {
            success: false,
        }
    }

};

/**
 * Retorna os dados a serem utilizados na montagem do relatório de meus fundos
 */
//export async function getDataToMyTitlesReport(clientId, month, year) {
export async function getDataToMyTitlesReport(clientId, startDate, endDate) {

    const { month, year } = getDayMonthYearByStringDate(endDate);

    //const { allTitles, totals, finalTotalsPublic, finalTotalsPrivate } = await getAnaliticTitles(clientId, startDate, endDate, month, year);
    const { allTitles, totalsPublic, totalsPrivate, totals } = await getAnaliticTitlesByPeriod(clientId, startDate, endDate, month, year);

    if (allTitles) {
        const arrayToReport = [];

        allTitles.forEach(row => {

            const { line1, line2 } = splitIn2Lines(row.title_name, 2);

            arrayToReport.push({
                title_p1: line1,
                title_p2: line2,
                due_date: moment.utc(getDueDateTitle(row)).format('DD/MM/YY'),
                purchase_date: moment.utc(row.purchase_date).format('DD/MM/YY'),
                quantity_purchased: row.quantity_purchased,
                purchase_pu: applyMaskAmount(row.price_title, null),
                now_pu: row.balance_now && row.quota_amount_now ?
                    applyMaskAmount(parseFloat(row.balance_now) / parseFloat(row.quota_amount_now), null) : applyMaskAmount(0, true),
                marking1: row.marking,
                marking2: (row.pre_tax && row.marking != MARKING_MARKET ? applyMaskAmount(row.pre_tax) + "%" : ''),
                purchase_value: applyMaskAmount(row.price_title * row.quantity_purchased, true),
                now_value: applyMaskAmount(row.balance_now, true),
                rent: applyMaskAmount(row.rentMoneyPeriod, true) + " ( " + applyMaskAmount(row.rentPeriod) + "%" + " )",
                sector: row.sector
            });
        });

        console.log("arrayToReport: ", arrayToReport);

        arrayToReport.push({
            title: 'Total Públicos',
            due_date: '',
            purchase_date: '',
            quantity_purchased: '',
            purchase_pu: '',
            now_pu: '',
            marking: '',
            purchase_value: applyMaskAmount(totalsPublic.purchaseAmount, true),
            now_value: applyMaskAmount(totalsPublic.currentAmount, true),
            rent: applyMaskAmount(totalsPublic.rentMoneyPeriod, true) + " ( " + applyMaskAmount(totalsPublic.rentPeriod) + "% )",
        });

        arrayToReport.push({
            title: 'Total Privados',
            due_date: '',
            purchase_date: '',
            quantity_purchased: '',
            purchase_pu: '',
            now_pu: '',
            marking: '',
            purchase_value: applyMaskAmount(totalsPrivate.purchaseAmount, true),
            now_value: applyMaskAmount(totalsPrivate.currentAmount, true),
            rent: applyMaskAmount(totalsPrivate.rentMoneyPeriod, true) + " ( " + applyMaskAmount(totalsPrivate.rentPeriod) + "% )",
        });

        arrayToReport.push({
            title: 'Total Global',
            due_date: '',
            purchase_date: '',
            quantity_purchased: '',
            purchase_pu: '',
            now_pu: '',
            marking: '',
            purchase_value: applyMaskAmount(totals.purchaseAmount, true),
            now_value: applyMaskAmount(totals.currentAmount, true),
            rent: applyMaskAmount(totals.rentMoneyPeriod, true) + " ( " + applyMaskAmount(totals.rentPeriod) + "% )",
        });

        console.log('arrayToReport_titles: ', arrayToReport);

        return {
            success: true,
            dataToReport: arrayToReport,
        }

    } else {
        console.log("RESPONSE ERROR AO GERAR RELATÓRIO DE TITULOS")
        return {
            success: false,
        }
    }

};



export async function getDataToMyFundsReportOLD(clientId, month, year) {

    const responseFunds = await getAllClientFunds(clientId);
    if (responseFunds.success) {

        console.log("FUNDS: ", responseFunds.body.rows);
        let arrayToReport = [];
        console.log("DATA TO REPORT: ", arrayToReport);

        let funds = responseFunds.body.rows;

        await formatBalancesRentsByRange(funds, clientId, 24);

        funds.forEach(fund => {

            arrayToReport.push({
                fund_name: fund.fund_name,
                cnpj: fund.cnpj,
                rent_year: fund.rentAcumYear ? applyMaskAmount(fund.rentAcumYear) + "%" : '-',
                rent_12m: fund.rentAcum12m ? applyMaskAmount(fund.rentAcum12m) + "%" : '-',
                rent_24m: fund.rentAcum24m ? applyMaskAmount(fund.rentAcum24m) + "%" : '-',
            })

        });

        return {
            success: true,
            dataToReport: arrayToReport,
        }

    } else {
        console.log("RESPONSE ERROR AO GERAR RELATÓRIO DE METAS: ", responseFunds.error)
        return {
            success: false,
        }
    }

};

export async function getDataToMyClientReturns(clientId, month, year) {

    let responseReturns = await loadClientDiaryByRange(clientId, month, year, 1);

    if (responseReturns.isSuccessful) {
        const clientReturns = [];

        let diarys = responseReturns.arrayRents[0].diarys.reverse();

        diarys.forEach(diary => {

            clientReturns.push({
                date_quota: moment.utc(diary.date_quota).format('DD/MM/YYYY'),
                previous_pl: applyMaskAmount(diary.pl, true),
                result_app_rescues: applyMaskAmount(diary.result_app_rescues, true),
                additional_quotas: applyMaskAmount(diary.add_quotas),
                rent_day: applyMaskAmountFreeDecimals(diary.rent_day),
                new_pl: applyMaskAmount(diary.new_pl, true),
            })

        });

        return {
            isSuccessful: true,
            clientReturns,
        }

    } else {
        console.error("ERRO AO RESGATAR RETORNOS DO CLIENTE: ", responseReturns.error)
        return {
            isSuccessful: false,
        }
    }

};

/**
 * Retorna os dados a serem utilizados na montsgem do relatório de enquadramentos
 */
//export async function getDataToArticleReport(clientId, month, year) {
export async function getDataToArticleReport(client, startDate, endDate) {

    const clientId = client.id;

    const { month, year } = getDayMonthYearByStringDate(endDate);

    //Obtendo segmentos de resolução
    //let responseLegislationSegments = await listLegislationSegments();
    const lastDayInMonth = getLastDayInMonthByMonthAndYear(month, year);
    let responseLegislationSegments = await listLegislationSegmentsByDate(lastDayInMonth);
    //listLegislationSegmentsByDate
    const segments = responseLegislationSegments.body.rows;

    let desenquadrados = {};

    if (responseLegislationSegments.success) {

        //Como o filtro é feito apenas pelo ano os registros de artigo podem vir duplicados pois
        //podem existir mais de uma politica para cada artigo
        //a diferença está no mês        
        //let responseLegislationArticles = await listLegislationArticles(null, clientId, year, lastDayInMonth);
        let responseLegislationArticles = await listLegislationArticlesByDate(null, clientId, year, lastDayInMonth);

        console.log("ARTICLES COM POLITICAS: ", responseLegislationArticles.body.rows);

        let formattedArticles = groupArticlePolicys(responseLegislationArticles.body.rows);

        //let responseAssets = await getClientAllAssetsByDate(clientOn.client.id, moment.utc(lastDay).format("DD/MM/YYYY"));
        let responseAssets = await getSimpleNormalizedAssets(clientId,
            month,
            year,
            null)

        if (responseAssets.success) {

            console.log("ASSETS: ", responseAssets.assets);
            const responseGrup = markArticlesInPortfolio(formattedArticles,
                responseAssets.assets, segments, [], client.promanagement_code);

            //Sem os desenquadrados
            let newArticles = [];
            responseGrup.articles.forEach(element => {

                if (element.asset == "Desenquadrado") {
                    desenquadrados[element.legislation_segment_id] = element;
                } else {
                    newArticles.push(element);
                }

            });

            //Realizar formatação dos dados a fim de exibir no relatório em tabela

            //Criar linhas de total abaixo da última linha
            let groupWithTotalLines = {};
            newArticles.forEach(element => {

                if (!groupWithTotalLines[element.legislation_segment_id]) {

                    groupWithTotalLines[element.legislation_segment_id] = [];

                }

                //formatando informações    

                element.asset = element.device_abbreviation && element.device_abbreviation != "" ? element.asset + " - " + element.device_abbreviation : element.asset;

                element.valuePercentInPortfolio = parseFloat(
                    element.totalArticleValue * 100
                    / responseGrup.totalPortfolioValue
                ).toFixed(2)

                element.totalArticleValue = applyMaskAmount(element.totalArticleValue, true)

                element.lower_target = element.lower_target ? applyMaskAmount(element.lower_target) + "%" : "-";
                element.target = element.target ? applyMaskAmount(element.target) + "%" : "-";
                element.top_target = element.top_target ? applyMaskAmount(element.top_target) + "%" : "-";

                groupWithTotalLines[element.legislation_segment_id].push(element);

            });


            Object.entries(responseGrup.mapSegmentArticlesTotal).map(([key, row]) => {

                //Caso exista desenquadrado ele entra na tabela, caso contrário não entra
                if (desenquadrados[key] && desenquadrados[key].isInPortfolio) {
                    groupWithTotalLines[key].push({
                        ...desenquadrados[key],
                        valuePercentInPortfolio: parseFloat(
                            desenquadrados[key].totalArticleValue * 100
                            / responseGrup.totalPortfolioValue
                        ).toFixed(2),
                        totalArticleValue: applyMaskAmount(desenquadrados[key].totalArticleValue, true),
                    });
                }

                groupWithTotalLines[key].push({
                    asset: 'Total',
                    totalArticleValue: applyMaskAmount(row, true),
                    valuePercentInPortfolio: applyMaskAmount(
                        responseGrup.mapSegmentArticlesTotal[key] * 100
                        / responseGrup.totalPortfolioValue)
                });

                let idTest = segments[segments.length - 1].id;
                if (segments.length > 3) {
                    idTest = segments[segments.length - 2].id //Em caso de V2 que possui segmento de imóveis
                }

                if (key == idTest) {

                    groupWithTotalLines[key].push({
                        asset: 'Total Geral',
                        totalArticleValue: applyMaskAmount(responseGrup.totalPortfolioValue, true),
                        valuePercentInPortfolio: applyMaskAmount(100),
                    });

                }

            })

            console.log("FINAL DATA: ", groupWithTotalLines);
            return {
                success: true,
                dataToArticlesReport: groupWithTotalLines,
            }

        }


    } else {
        return {
            success: false,
        }
    }

};

function getInfosQuotasByDay(quotas, date) {

    console.log("Quotas: ", quotas);
    console.log("date: ", date);

    if (quotas) {
        for (let i = 0; i < quotas.length; i++) {

            const element = quotas[i];
            if (element.data_referencia == moment.utc(date).format('YYYY-MM-DD')) {

                return {
                    pl_in_trasaction_day: applyMaskAmount(element.patrimonio_liquido, true),
                    quota_amount_in_trasaction_day: applyMaskAmountFreeDecimals(element.valor_cota),
                    quota_qtd_in_trasaction_day: applyMaskAmountFreeDecimals(element.patrimonio_liquido / element.valor_cota),
                }

            }

        }
    }


    return {
        pl_in_trasaction_day: 0,
        quota_amount_in_trasaction_day: 0,
        quota_qtd_in_trasaction_day: 0,
    };

}

function normalizeToApr(transactions, hideCpf) {

    transactions.forEach(apr => {

        apr.client_apr_data = {
            certification_validity: moment.utc(apr.certification_validity).format("DD/MM/YYYY"),
            name_preponent: apr.name_preponent,
            cpf_preponent: hideCpf ? applyMaskHideCpf(apr.cpf_preponent) : apr.cpf_preponent,
            name_responsable: apr.name_responsable,
            cpf_responsable: hideCpf ? applyMaskHideCpf(apr.cpf_responsable) : apr.cpf_responsable,
            name_manager: apr.name_manager,
            cpf_manager: hideCpf ? applyMaskHideCpf(apr.cpf_manager) : apr.cpf_manager,
            certification_name: apr.certification_entity + " - " + apr.certification_name,
        }

        if (apr.apr_description_id != null) { //No caso de ser uma apr pré definida a descrição vem no campo apr_description2 a partir da consulta

            apr.apr_description = apr.apr_description2

        }

        apr.account = applyMaskAccount(apr.account);

        let jsonQuotas = apr.json_quotas ? JSON.parse(apr.json_quotas) : null;
        let jsonInfos = apr.json_fund_infos ? JSON.parse(apr.json_fund_infos) : null;

        const objQuotas = jsonQuotas ? getInfosQuotasByDay(jsonQuotas, apr.transaction_date) : null;

        apr.asset = {
            name: apr.fund_name,
            cnpj: apr.fund_cnpj,
            administrator: jsonInfos ? jsonInfos.dados_cadastrais.administrator_name : "-",
            cnpj_administrator: jsonInfos ? jsonInfos.dados_cadastrais.administrator_cnpj : "-",
            manager: jsonInfos ? jsonInfos.dados_cadastrais.manager_name : "-",
            cnpj_manager: jsonInfos ? jsonInfos.dados_cadastrais.manager_cnpj : "-",
            disponibility: jsonInfos ? jsonInfos.dados_cadastrais.disponibilization : "-",
            date_init: jsonInfos ? jsonInfos.dados_cadastrais.fund_init : "-",
            administration_tax: jsonInfos ? applyMaskAmount(jsonInfos.dados_cadastrais.administration_tax) + "%" : "-",
            performance_tax: jsonInfos ? (jsonInfos.dados_cadastrais.performance_tax ? (jsonInfos.dados_cadastrais.performance_tax == "N" ? "Não possui" : jsonInfos.dados_cadastrais.performance_tax) : "Não possui") : "-",
            //benchmark: jsonInfos ? jsonInfos.dados_cadastrais.benchmark : "-",
            distributor_name: apr.distributor_exception_name ? apr.distributor_exception_name : apr.distributor_name,
            distributor_cnpj: apr.distributor_exception_cnpj ? apr.distributor_exception_cnpj : apr.distributor_cnpj,
            benchmark: apr.benchmark ? apr.benchmark : "-",
            pl_in_trasaction_day: objQuotas ? objQuotas.pl_in_trasaction_day : '-',
            quota_amount_in_trasaction_day: objQuotas ? objQuotas.quota_amount_in_trasaction_day : '-',
            quota_qtd_in_trasaction_day: objQuotas ? objQuotas.quota_qtd_in_trasaction_day : '-',
        }

        apr.transaction_validated = {
            number_apr: apr.number_apr,
            date: moment.utc(apr.transaction_date).format('DD/MM/YYYY'),
            value: applyMaskAmount(apr.amount, false),
            operation: apr.operation,
            resolution: apr.article,
            description: apr.apr_description
        }

        apr.amount = applyMaskAmount(apr.amount, false)

        apr.asset_validated = {
            fund_name: apr.asset.name,
            cnpj: apr.asset.cnpj,
            administrator: apr.asset.administrator,
            cnpj_administrator: apr.asset.cnpj_administrator,
            manager: apr.asset.manager,
            cnpj_manager: apr.asset.cnpj_manager,
            disponibility: apr.asset.disponibility,
            date_init: apr.asset.date_init,
            administration_tax: apr.asset.administration_tax,
            benchmark: apr.asset.benchmark,
            performance_tax: apr.asset.performance_tax,
            //prev_return:apr.asset.prev_return,
            pl_in_trasaction_day: apr.asset.pl_in_trasaction_day,
            quota_amount_in_trasaction_day: apr.asset.quota_amount_in_trasaction_day,
            quota_qtd_in_trasaction_day: apr.asset.quota_qtd_in_trasaction_day,
        }
        apr.preponent_validated = {
            name: apr.client_apr_data.name_preponent,
            cpf: apr.client_apr_data.cpf_preponent,
        }
        apr.manager_validated = {
            name: apr.client_apr_data.name_manager,
            cpf: apr.client_apr_data.cpf_manager,
            certification: apr.client_apr_data.certification_name != "null - null" ? apr.client_apr_data.certification_name : "-",
            validity: apr.client_apr_data.certification_name != "null - null" ? apr.client_apr_data.certification_validity : "-",
        }
        apr.operation_validated = {
            name: apr.client_apr_data.name_responsable,
            cpf: apr.client_apr_data.cpf_responsable
        }

    });

}

function normalizeToSingleApr(transactions, hideCpf) {

    transactions.forEach(apr => {

        apr.client_apr_data = {
            certification_validity: moment.utc(apr.certification_validity).format("DD/MM/YYYY"),
            name_preponent: apr.name_preponent,
            cpf_preponent: hideCpf ? applyMaskHideCpf(apr.cpf_preponent) : apr.cpf_preponent,
            name_responsable: apr.name_responsable,
            cpf_responsable: hideCpf ? applyMaskHideCpf(apr.cpf_responsable) : apr.cpf_responsable,
            name_manager: apr.name_manager,
            cpf_manager: hideCpf ? applyMaskHideCpf(apr.cpf_manager) : apr.cpf_manager,
            certification_name: apr.certification_entity + " - " + apr.certification_name,
        }

        if (apr.apr_description_id != null) { //No caso de ser uma apr pré definida a descrição vem no campo apr_description2 a partir da consulta

            apr.apr_description = apr.apr_description2

        }

        let jsonQuotas = JSON.parse(apr.json_quotas);
        let jsonInfos = JSON.parse(apr.json_fund_infos);

        let objQuotas = getInfosQuotasByDay(jsonQuotas, apr.transaction_date);

        apr.asset = {
            name: apr.fund_name,
            cnpj: apr.fund_cnpj,
            administrator: jsonInfos ? jsonInfos.dados_cadastrais.administrator_name : "-",
            cnpj_administrator: jsonInfos ? jsonInfos.dados_cadastrais.administrator_cnpj : "-",
            manager: jsonInfos ? jsonInfos.dados_cadastrais.manager_name : "-",
            cnpj_manager: jsonInfos ? jsonInfos.dados_cadastrais.manager_cnpj : "-",
            disponibility: jsonInfos ? jsonInfos.dados_cadastrais.disponibilization : "-",
            date_init: jsonInfos ? jsonInfos.dados_cadastrais.fund_init : "-",
            administration_tax: jsonInfos ? applyMaskAmount(jsonInfos.dados_cadastrais.administration_tax) + "%" : "-",
            performance_tax: jsonInfos ? (jsonInfos.dados_cadastrais.performance_tax ? (jsonInfos.dados_cadastrais.performance_tax == "N" ? "Não possui" : jsonInfos.dados_cadastrais.performance_tax) : "Não possui") : "-",
            //benchmark: jsonInfos ? jsonInfos.dados_cadastrais.benchmark : "-",
            benchmark: apr.benchmark ? apr.benchmark : "-",
            pl_in_trasaction_day: objQuotas.pl_in_trasaction_day,
            quota_amount_in_trasaction_day: objQuotas.quota_amount_in_trasaction_day,
            quota_qtd_in_trasaction_day: objQuotas.quota_qtd_in_trasaction_day,
        }

        apr.transaction_validated = {
            number_apr: apr.number_apr,
            date: moment.utc(apr.transaction_date).format('DD/MM/YYYY'),
            value: applyMaskAmount(apr.amount, false),
            operation: apr.operation,
            resolution: apr.article,
            description: apr.apr_description
        }

        apr.amount = applyMaskAmount(apr.amount, false)

        apr.asset_validated = {
            fund_name: apr.asset.name,
            cnpj: apr.asset.cnpj,
            administrator: apr.asset.administrator,
            cnpj_administrator: apr.asset.cnpj_administrator,
            manager: apr.asset.manager,
            cnpj_manager: apr.asset.cnpj_manager,
            disponibility: apr.asset.disponibility,
            date_init: apr.asset.date_init,
            administration_tax: apr.asset.administration_tax,
            benchmark: apr.asset.benchmark,
            performance_tax: apr.asset.performance_tax,
            //prev_return:apr.asset.prev_return,
            pl_in_trasaction_day: apr.asset.pl_in_trasaction_day,
            quota_amount_in_trasaction_day: apr.asset.quota_amount_in_trasaction_day,
            quota_qtd_in_trasaction_day: apr.asset.quota_qtd_in_trasaction_day,
        }
        apr.preponent_validated = {
            name: apr.client_apr_data.name_preponent,
            cpf: apr.client_apr_data.cpf_preponent,
        }
        apr.manager_validated = {
            name: apr.client_apr_data.name_manager,
            cpf: apr.client_apr_data.cpf_manager,
            certification: apr.client_apr_data.certification_name != "null - null" ? apr.client_apr_data.certification_name : "-",
            validity: apr.client_apr_data.certification_name != "null - null" ? apr.client_apr_data.certification_validity : "-",
        }
        apr.operation_validated = {
            name: apr.client_apr_data.name_responsable,
            cpf: apr.client_apr_data.cpf_responsable
        }

    });

}

/**
 * Retorna os dados a serem utilizados na montagem do relatório de lote de APRS
 */
export async function getDataToAprReport(transactionId, month, year, hideCpf) {

    let response = await getAprWithClientDataByIdTransaction(transactionId, month, year);

    console.log("RESPONSE getAprWithClientDataByIdTransaction: ", response);

    if (response.success) {

        console.log("REPORTS: ", response.body.rows);

        let transactions = response.body.rows;
        if (transactions.length == 1) {

            //Formatando informações
            normalizeToApr(transactions, hideCpf);

            return {
                success: true,
                dataToReport: transactions[0],
            };

        } else {
            return {
                success: false,
            };
        }




    }

};
/**
 * Retorna os dados a serem utilizados na montagem do relatório de single APR
 */
export async function getDataToSingleAprReport(transactionId, month, year, hideCpf) {

    let response = await getSingleAprWithClientDataByIdTransaction(transactionId, month, year);

    if (response.success) {

        console.log("REPORTS: ", response.body.rows);

        let transactions = response.body.rows;
        if (transactions.length == 1) {

            //Formatando informações
            normalizeToSingleApr(transactions, hideCpf);

            return {
                success: true,
                dataToReport: transactions[0],
            };

        } else {
            return {
                success: false,
            };
        }




    }

};

/**
 * Retorna os dados a serem utilizados na montagem do relatório de lote de APRS
 */
export async function getDataToBatchAprsReport(clientId, month, year, regimeId, order, selectedYearSingleAPRS, hideCpf) {

    let firstDay = new Date(year, parseInt(month) - 1, 1);
    var lastDay = new Date(year, parseInt(month), 0);

    //Verificando se existem contaas
    if (firstDay != null
        && lastDay != undefined) {

        let response = null;
        if (!selectedYearSingleAPRS) { //requisição convencional de aprs por período mensal

            response = await getAprsWithClientDataByDateRange(clientId,
                moment.utc(firstDay).format("DD/MM/YYYY"),
                moment.utc(lastDay).format("DD/MM/YYYY"), regimeId, order);

        } else {

            response = await getSinglesAprsWithClientDataByYear(clientId,
                selectedYearSingleAPRS,
                regimeId,
                order);

        }

        if (response.success) {

            console.log("REPORTS: ", response.body.rows);

            let transactions = response.body.rows;

            //Formatando informações
            normalizeToApr(transactions, hideCpf);

            return {
                success: true,
                dataToReport: transactions,
            };

        } else {

            return {
                success: false,
            }
        }
    }


};

export function getSimpleRentMoneyTitle(obj) {
    return obj.balance_now - obj.totalApplicationsAmount
        + (obj.totalRescuesAmount + obj.totalAmortizationAmount) - obj.balance_before
}

export function getSimpleRentMoneyTitle2(balanceNow, applications, rescues, amortizations, balanceBefore) {
    return balanceNow - applications
        + (rescues + amortizations) - balanceBefore
}

export function getSimpleRentTitle(obj) {
    return (((obj.balance_now + (obj.totalRescuesAmount + obj.totalAmortizationAmount))
        / (obj.balance_before + obj.totalApplicationsAmount)) - 1) * 100
}

export function getSimpleRentTitle2(balanceNow, applications, rescues, amortizations, balanceBefore) {
    return (((balanceNow + (rescues + amortizations))
        / (balanceBefore + applications)) - 1) * 100
}

export function groupTitleSumTransactions(sumTitlesTransactions, year, groupBy = 'title_id') {

    const sumTrasactionsTitlesMap = {};
    sumTitlesTransactions.forEach(element => {

        if (!sumTrasactionsTitlesMap[element[groupBy]]) {
            sumTrasactionsTitlesMap[element[groupBy]] = {};
        }

        const period = element.month + '/' + year;
        console.log('element title: ', element);
        if (!sumTrasactionsTitlesMap[element[groupBy]][period]) {
            sumTrasactionsTitlesMap[element[groupBy]][period] = {
                1: 0, //aplicações
                2: 0, //resgates
                3: 0, //amortizações
            };
        }

        sumTrasactionsTitlesMap[element[groupBy]][period][element.operation_id] += element.amount;

    });

    return sumTrasactionsTitlesMap;
}

export async function rentsByPeriod(clientId, startDate, endDate, search, segmentId) {

    console.log('rentsByPeriod ' + startDate + ' a ' + endDate);

    const { month: monthStartdate, year: yearStartDate } = getDayMonthYearByStringDate(startDate);
    const { month, year } = getDayMonthYearByStringDate(endDate);

    //Obter infos das cotas médias dos fundos referentes ao mês anterior ao período
    const responseLastAverageQuotas = await getClientFundsRentsByPeriod(clientId,
        startDate,
        endDate, search, segmentId)


    if (responseLastAverageQuotas.success) {

        console.log("responseLastAverageQuotas: ", responseLastAverageQuotas);

        const startDateBefore = getMonthAgo(monthStartdate, yearStartDate, 2);

        const { month: monthBefore, year: yearBefore } = getDayMonthYearByPtDate(startDateBefore);
        const periodBefore = monthBefore + '/' + yearBefore;

        const responseBalances = await getBalancesAndSumTransactionsPortfolioInMonthByPeriod(clientId, startDateBefore, endDate, search, segmentId);
        const responseSumTransactions = await sumTransactionsInMonthByPeriod(clientId, startDate, endDate, search, segmentId);

        //formatando resposta de cotas médias dos fundos
        const mapAveragePeriodQuotas = {};
        responseLastAverageQuotas.body.rows.forEach(element => {
            if (!mapAveragePeriodQuotas[element.fund_id]) {
                mapAveragePeriodQuotas[element.fund_id] = {
                    averageQuotasByMonth: [],
                    is_poupanca: element.is_poupanca
                }
            }
            mapAveragePeriodQuotas[element.fund_id].averageQuotasByMonth.push({
                period: element.period,
                average_quota_now: JSON.parse(element.json_average_quotas),
                quotas_anbima: getClientJsonQuotas(JSON.parse(element.quotas_anbima), clientId),
            });
        });

        //Formatando averageQuotaByMonth
        Object.entries(mapAveragePeriodQuotas).map(([fundId, row]) => {
            if (row) {
                row.averageQuotasByMonthMap = {};
                row.averageQuotasByMonth.forEach((element, index) => {
                    row.averageQuotasByMonthMap[element.period] = element;
                });
            }
        })

        //formatando sumTransactions
        const sumTrasactionsFundsMap = {};

        // console.log("responseSumTransactions: ", responseSumTransactions);
        responseSumTransactions.body.funds.forEach(element => {
            if (!sumTrasactionsFundsMap[element.fund_id]) {
                sumTrasactionsFundsMap[element.fund_id] = {};
            }

            const period = element.month + '/' + element.year;
            if (!sumTrasactionsFundsMap[element.fund_id][period]) {
                sumTrasactionsFundsMap[element.fund_id][period] = {
                    1: 0, //aplicações
                    2: 0, //resgates
                    3: 0, //amortizações

                };
            }

            sumTrasactionsFundsMap[element.fund_id][period][element.operation_id] += element.amount;

        });

        // console.log("sumTrasactionsFundsMap: ", sumTrasactionsFundsMap);

        const sumTrasactionsTitlesMap = groupTitleSumTransactions(responseSumTransactions.body.titles, year);

        //formatando saldos
        const fundBalancesMap = {};
        responseBalances.body.fundsBalances.forEach(element => {
            if (!fundBalancesMap[element.fund_id]) {
                fundBalancesMap[element.fund_id] = {};
            }

            fundBalancesMap[element.fund_id][element.period] = {
                balance_now: element.balance,
                period_before: getPeriodBefore(element.period),
            };

        });

        Object.entries(fundBalancesMap).map(([key, periods]) => {

            Object.entries(periods).map(([periodKey, period]) => {

                //console.log('periodBefore: ', periodBefore);
                if (periodKey != periodBefore && periods[period.period_before]) {
                    period.balance_before = periods[period.period_before].balance_now;
                } else {
                    period.balance_before = 0;
                }

            })

            delete periods[periodBefore];
        })

        //Organizando em perídos anuais
        const anualFundsBalancesMap = {};
        Object.entries(_.cloneDeep(fundBalancesMap)).map(([fundId, periods]) => {

            Object.entries(periods).map(([periodKey, period]) => {

                const yearPeriod = getYearPeriod(periodKey);
                if (!anualFundsBalancesMap[yearPeriod]) {
                    anualFundsBalancesMap[yearPeriod] = {};
                }

                if (!anualFundsBalancesMap[yearPeriod][fundId]) {
                    anualFundsBalancesMap[yearPeriod][fundId] = {}
                }

                anualFundsBalancesMap[yearPeriod][fundId][periodKey] = period;

            })

        })


        //Voltando para modelo integrado de período indexando os períodos de cada fundo
        const integralPeriodFundsBalancesMap = {};
        Object.entries(anualFundsBalancesMap).map(([year, fundsByYear]) => {

            Object.entries(fundsByYear).map(([fundId, periodsFundByYear]) => {

                if (!integralPeriodFundsBalancesMap[fundId]) {
                    integralPeriodFundsBalancesMap[fundId] = {
                        indexerPeriodKey: 0
                    };
                }

                Object.entries(periodsFundByYear).map(([periodString, periodRent]) => {

                    const nextIndexerPeriodKey = ++integralPeriodFundsBalancesMap[fundId].indexerPeriodKey;
                    const yearPeriod = getYearPeriod(periodString);
                    integralPeriodFundsBalancesMap[fundId][nextIndexerPeriodKey + "__" + periodString] = {
                        ...periodRent,
                        period: periodString,
                        yearPeriod: yearPeriod,
                    };

                })

            })


        })

        //Mapa com os ids de títulos agrupados por setor
        const titlesSectorsMap = {
            'PUBLICO': { balance_now: 0, balance_before: 0, totalApplicationsAmount: 0, totalRescuesAmount: 0, totalAmortizationAmount: 0, titlesIds: [] },
            'PRIVADO': { balance_now: 0, balance_before: 0, totalApplicationsAmount: 0, totalRescuesAmount: 0, totalAmortizationAmount: 0, titlesIds: [] },
            [GENERIC_ASSET_TITLES_TYPE]: { balance_now: 0, balance_before: 0, totalApplicationsAmount: 0, totalRescuesAmount: 0, totalAmortizationAmount: 0, titlesIds: [] },
        };

        const titleBalancesMap = {};
        responseBalances.body.titlesBalances.forEach(element => {
            if (!titleBalancesMap[element.title_id]) {
                titleBalancesMap[element.title_id] = {
                };
            }

            //CRIANDO MAPA DE IDS REFERENTES AOS SETORES
            if (element.description == GENERIC_ASSET_TITLES_TYPE) {
                if (!titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].titlesIds.includes(element.title_id)) {
                    titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].titlesIds.push(element.title_id);
                }
            } else {
                if (!titlesSectorsMap[element.sector].titlesIds.includes(element.title_id)) {
                    titlesSectorsMap[element.sector].titlesIds.push(element.title_id);
                }
            }

            titleBalancesMap[element.title_id][element.period] = {
                balance_now: element.balance,
                period_before: getPeriodBefore(element.period),
            };

        });
        Object.entries(titleBalancesMap).map(([key, periods]) => {

            Object.entries(periods).map(([periodKey, period]) => {

                if (periodKey != periodBefore) {
                    period.balance_before = periods[period.period_before] ? periods[period.period_before].balance_now : 0;
                }

            })

            delete periods[periodBefore];
        })

        let rentFundsMoneyAcumPortoflioPeriodMap = {
            totalPeriods: 0
        };
        let rentTitlesMoneyAcumPortoflioPeriodMap = {
            totalPeriods: 0
        };

        const fundsBalancesByPeriod = { rentMoneyAcum: 0 };

        Object.entries(fundBalancesMap).map(([fundId, periodsFund]) => {

            if (!periodsFund.rentMoneyAcum) {
                periodsFund.rentMoneyAcum = 0;
            }

            Object.entries(periodsFund).map(([period, periodRent]) => {

                if (period != 'rentMoneyAcum' && !fundsBalancesByPeriod[period]) {
                    fundsBalancesByPeriod[period] = 0;
                }

                if (period != 'rentMoneyAcum') {
                    periodRent.totalApplicationsAmount = 0;
                    periodRent.totalRescuesAmount = 0;
                    periodRent.totalAmortizationAmount = 0;

                    if (sumTrasactionsFundsMap[fundId] && sumTrasactionsFundsMap[fundId][period]) {
                        //Adicionando aplicações ao periodo 
                        periodRent.totalApplicationsAmount = sumTrasactionsFundsMap[fundId][period][1];
                        periodRent.totalRescuesAmount = sumTrasactionsFundsMap[fundId][period][2];
                        periodRent.totalAmortizationAmount = sumTrasactionsFundsMap[fundId][period][3];
                    }

                    periodRent.rentMoney = getMonthFundRentInMoney(periodRent);
                    fundsBalancesByPeriod[period] += periodRent.rentMoney;
                    fundsBalancesByPeriod.rentMoneyAcum += periodRent.rentMoney;

                    periodsFund.rentMoneyAcum += periodRent.rentMoney ? periodRent.rentMoney : 0;

                    if (!rentFundsMoneyAcumPortoflioPeriodMap[period]) {
                        rentFundsMoneyAcumPortoflioPeriodMap[period] = 0;
                    }

                    rentFundsMoneyAcumPortoflioPeriodMap[period] += periodRent.rentMoney ? periodRent.rentMoney : 0;
                    rentFundsMoneyAcumPortoflioPeriodMap.totalPeriods += periodRent.rentMoney ? periodRent.rentMoney : 0;
                }


            })

        })

        const fundsBalancesByIntegralPeriod = { rentMoneyAcum: 0 };
        Object.entries(integralPeriodFundsBalancesMap).map(([fundId, periodsFund]) => {

            if (!periodsFund.rentMoneyAcum) {
                periodsFund.rentMoneyAcum = 0;
            }

            Object.entries(periodsFund).map(([period, periodRent]) => {

                if (period != 'rentMoneyAcum' && !fundsBalancesByIntegralPeriod[period]) {
                    fundsBalancesByIntegralPeriod[period] = 0;
                }

                if (period != 'rentMoneyAcum' && period != 'indexerPeriodKey') {

                    periodRent.totalApplicationsAmount = 0;
                    periodRent.totalRescuesAmount = 0;
                    periodRent.totalAmortizationAmount = 0;

                    if (sumTrasactionsFundsMap[fundId] && sumTrasactionsFundsMap[fundId][periodRent.period]) {
                        //Adicionando aplicações ao periodo 
                        periodRent.totalApplicationsAmount = sumTrasactionsFundsMap[fundId][periodRent.period][1];
                        periodRent.totalRescuesAmount = sumTrasactionsFundsMap[fundId][periodRent.period][2];
                        periodRent.totalAmortizationAmount = sumTrasactionsFundsMap[fundId][periodRent.period][3];
                    }

                    periodRent.rentMoney = getMonthFundRentInMoney(periodRent);
                    fundsBalancesByIntegralPeriod[period] += periodRent.rentMoney;
                    fundsBalancesByIntegralPeriod.rentMoneyAcum += periodRent.rentMoney;

                    periodsFund.rentMoneyAcum += periodRent.rentMoney ? periodRent.rentMoney : 0;

                    if (!rentFundsMoneyAcumPortoflioPeriodMap[period]) {
                        rentFundsMoneyAcumPortoflioPeriodMap[period] = 0;
                    }

                    rentFundsMoneyAcumPortoflioPeriodMap[period] += periodRent.rentMoney ? periodRent.rentMoney : 0;
                    rentFundsMoneyAcumPortoflioPeriodMap.totalPeriods += periodRent.rentMoney ? periodRent.rentMoney : 0;

                    //Atualizando averaQuotas
                    // if (fundId == 454) {
                    //     console.log("PASSANDO AQUI YYY: ", JSON.parse(JSON.stringify(mapAveragePeriodQuotas[fundId])));
                    //     console.log("sumTrasactionsFundsMap: ", sumTrasactionsFundsMap);
                    // }

                    if (mapAveragePeriodQuotas[fundId] && mapAveragePeriodQuotas[fundId].averageQuotasByMonthMap) {

                        const averageQuotasObj = mapAveragePeriodQuotas[fundId].averageQuotasByMonthMap[periodRent.period];
                        if (averageQuotasObj) {

                            periodRent.average_quota_now = averageQuotasObj.average_quota_now;
                            periodRent.quotas_anbima = averageQuotasObj.quotas_anbima;
                            periodRent.is_poupanca = averageQuotasObj.is_poupanca;

                            // if (periodRent.period == '8/2023' && fundId == 454) {
                            //     console.log("periodRent CCC: ", JSON.parse(JSON.stringify(periodRent)));
                            //     console.log("sumTrasactionsFundsMap[fundId]: ", sumTrasactionsFundsMap[fundId]);
                            //     console.log("sumTrasactionsFundsMap[fundId][periodRent.period]: ", sumTrasactionsFundsMap[fundId][periodRent.period]);
                            //     console.log("sumTrasactionsFundsMap[fundId][periodRent.period][3]: ", sumTrasactionsFundsMap[fundId][periodRent.period][3]);
                            // }

                            if (sumTrasactionsFundsMap[fundId]
                                && sumTrasactionsFundsMap[fundId][periodRent.period]
                                && sumTrasactionsFundsMap[fundId][periodRent.period][3]) {

                                periodRent.totalAmortizationAmount = sumTrasactionsFundsMap[fundId][periodRent.period][3]
                                const amortizationAverageQuota = periodRent.average_quota_now[periodRent.average_quota_now.length - 1];
                                if (amortizationAverageQuota?.qtdQuotasOnAmortization)
                                    periodRent.qtdQuotasOnAmortization = amortizationAverageQuota.qtdQuotasOnAmortization;
                            }

                            if (periodRent.is_poupanca) {
                                periodRent.rent = (((periodRent.balance_now + periodRent.totalRescuesAmount) / (periodRent.balance_before + periodRent.totalApplicationsAmount)) - 1) * 100
                            } else {
                                periodRent.rent = getInMonthRentFundInPortfolio(periodRent);
                            }

                            periodsFund.rentMonth = periodRent.rent;
                            periodsFund.currentPeriod = periodRent.period;

                        }
                    }

                    periodsFund.rentMonth = periodRent.rent ? periodRent.rent : 0;
                    periodsFund.currentPeriod = periodRent.period;

                }


            })

        })

        // console.log('fundsBalancesByPeriod: ', fundsBalancesByPeriod);
        console.log('fundsBalancesByIntegralPeriod: ', fundsBalancesByIntegralPeriod);
        // console.log('anualFundsBalancesMap xx: ', anualFundsBalancesMap);
        console.log('integralPeriodFundsBalancesMap: ', integralPeriodFundsBalancesMap);

        //console.log("MAP XXX: ", JSON.stringify(rentMonthByFundMap)

        Object.entries(titleBalancesMap).map(([titleId, periodsTitle]) => {

            if (!periodsTitle.rentMoneyAcum) {
                periodsTitle.rentMoneyAcum = 0;
            }

            Object.entries(periodsTitle).map(([period, periodRent]) => {

                if (period != 'rentMoneyAcum') {
                    periodRent.totalApplicationsAmount = 0;
                    periodRent.totalRescuesAmount = 0;
                    periodRent.totalAmortizationAmount = 0;

                    if (sumTrasactionsTitlesMap[titleId] && sumTrasactionsTitlesMap[titleId][period]) {
                        //Adicionando aplicações ao periodo 
                        periodRent.totalApplicationsAmount = sumTrasactionsTitlesMap[titleId][period][1];
                        periodRent.totalRescuesAmount = sumTrasactionsTitlesMap[titleId][period][2];
                        periodRent.totalAmortizationAmount = sumTrasactionsTitlesMap[titleId][period][3];
                    }

                    periodRent.rentMoney = getSimpleRentMoneyTitle(periodRent);
                    periodsTitle.rentMoneyAcum += periodRent.rentMoney ? periodRent.rentMoney : 0;

                    periodRent.rent = getSimpleRentTitle(periodRent);

                    if (!rentTitlesMoneyAcumPortoflioPeriodMap[period]) {
                        rentTitlesMoneyAcumPortoflioPeriodMap[period] = 0;
                    }

                    rentTitlesMoneyAcumPortoflioPeriodMap[period] += periodRent.rentMoney ? periodRent.rentMoney : 0;
                    rentTitlesMoneyAcumPortoflioPeriodMap.totalPeriods += periodRent.rentMoney ? periodRent.rentMoney : 0;

                    if (titlesSectorsMap['PUBLICO'].titlesIds.includes(+titleId)) {
                        titlesSectorsMap['PUBLICO'].balance_now += periodRent.balance_now;
                        titlesSectorsMap['PUBLICO'].balance_before += periodRent.balance_before;
                        titlesSectorsMap['PUBLICO'].totalApplicationsAmount += periodRent.totalApplicationsAmount;
                        titlesSectorsMap['PUBLICO'].totalRescuesAmount += periodRent.totalRescuesAmount;
                        titlesSectorsMap['PUBLICO'].totalAmortizationAmount += periodRent.totalAmortizationAmount;
                    } else if (titlesSectorsMap['PRIVADO'].titlesIds.includes(+titleId)) {
                        titlesSectorsMap['PRIVADO'].balance_now += periodRent.balance_now;
                        titlesSectorsMap['PRIVADO'].balance_before += periodRent.balance_before;
                        titlesSectorsMap['PRIVADO'].totalApplicationsAmount += periodRent.totalApplicationsAmount;
                        titlesSectorsMap['PRIVADO'].totalRescuesAmount += periodRent.totalRescuesAmount;
                        titlesSectorsMap['PRIVADO'].totalAmortizationAmount += periodRent.totalAmortizationAmount;
                    } else {
                        titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].balance_now += periodRent.balance_now;
                        titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].balance_before += periodRent.balance_before;
                        titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].totalApplicationsAmount += periodRent.totalApplicationsAmount;
                        titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].totalRescuesAmount += periodRent.totalRescuesAmount;
                        titlesSectorsMap[GENERIC_ASSET_TITLES_TYPE].totalAmortizationAmount += periodRent.totalAmortizationAmount;
                    }
                }


            })

        })


        //Rentabilizando Títulos Por Setor e Período
        let titlesRentMoneyAcum = 0;
        Object.entries(titlesSectorsMap).map(([sector, row]) => {

            row.rentMoneyAcum = getSimpleRentMoneyTitle(row);
            row.rentAcum = row.titlesIds && row.titlesIds.length ? getSimpleRentTitle(row) : 0;
            titlesRentMoneyAcum += row.rentMoneyAcum;
        })

        console.log('fundBalancesMap: ', fundBalancesMap);
        console.log('titleBalancesMap: ', titleBalancesMap);
        //console.log('rentFundsMoneyAcumPortoflioPeriodMap: ', rentFundsMoneyAcumPortoflioPeriodMap);
        //console.log('rentTitlesMoneyAcumPortoflioPeriodMap: ', rentTitlesMoneyAcumPortoflioPeriodMap);
        console.log('titlesSectorsMap: ', titlesSectorsMap);

        //******FUNDOS E SUAS RENTABILDIADES EM % POR PERIODO */
        //Obter rentabilidade de cada mês e captalizar essas rentabilidades
        Object.entries(mapAveragePeriodQuotas).map(([fundId, row]) => {

            if (row) {
                row.averageQuotasByMonth.forEach((element, index) => {

                    const objToRent = {
                        ...element,
                        ...fundBalancesMap[fundId][element.period],
                    }

                    if (element.is_poupanca) {
                        element.rent = (((objToRent.balance_now + objToRent.totalRescuesAmount) / (objToRent.balance_before + objToRent.totalApplicationsAmount)) - 1) * 100
                    } else {
                        element.rent = getInMonthRentFundInPortfolio(objToRent);
                    }

                    fundBalancesMap[fundId][element.period].rent = element.rent;
                });
            }


        })

        Object.entries(fundBalancesMap).map(([fundId, periodsFund]) => {

            if (!periodsFund.rentAcum) {
                periodsFund.rentAcum = 1;
            }

            Object.entries(periodsFund).map(([period, periodRent]) => {

                if (period != 'rentMoneyAcum' && period != 'rentAcum'
                    && periodRent.rent != 0 && periodRent.rent != undefined) {

                    periodsFund.rentAcum *= (1 + (((periodRent.rent / 100) + 1) - 1));

                    if (fundId == 3) console.log("ERT ERROR: ", periodsFund.rentAcum, period, periodRent);


                }

            })

            periodsFund.rentAcum = (periodsFund.rentAcum - 1) * 100;

        })

        //Integral mode
        Object.entries(integralPeriodFundsBalancesMap).map(([fundId, periodsFund]) => {

            if (!periodsFund.rentAcum) {
                periodsFund.rentAcum = 1;
            }

            Object.entries(periodsFund).map(([period, periodRent]) => {

                if (period != 'rentMoneyAcum' && period != 'rentAcum'
                    && periodRent.rent != 0 && periodRent.rent != undefined) {

                    periodsFund.rentAcum *= (1 + (((periodRent.rent / 100) + 1) - 1));
                    if (fundId == 3) console.log("ERT ERROR: ", periodsFund.rentAcum, period, periodRent);
                }

            })

            periodsFund.rentAcum = (periodsFund.rentAcum - 1) * 100;

        })

        // console.log("AFTER ALL: ", integralPeriodFundsBalancesMap);
        // console.log("resultsFunds: ", fundBalancesMap);

        return {
            resultFunds: fundBalancesMap,
            fundsBalancesByPeriod: fundsBalancesByPeriod,
            integralFundsBalancesMap: integralPeriodFundsBalancesMap,
            resultTitles: titlesSectorsMap,
            titlesRentMoneyAcum: titlesRentMoneyAcum,
            titleBalancesMap: titleBalancesMap,
        }

    }
}

export async function getDataToProvisonalReport(clientId, startDate, endDate) {

    const IPCA_VALUE = 0.04; //2023 e 2024

    const { month, year } = getDayMonthYearByPtDate(startDate);
    const { monthBefore, yearBefore } = getMonthAndYearBefore(startDate);

    const simpleAssets = await getSimpleNormalizedAssets(clientId, month, year);
    console.log('simpleAssets: ', simpleAssets);

    const cnpjs = [];
    simpleAssets.simpleFunds.forEach(fund => {
        cnpjs.push({ cnpj: fund.cnpj });
    });

    const realFundAssets = organizeAssetsAndTransactions(simpleAssets.assets.filter(el => el.fund_id), []);
    const realAssets = await fillLocalInfosByMonthAndYear(realFundAssets, monthBefore, yearBefore);

    console.log('realAssets: ', realAssets);
    const realTitles = simpleAssets.assets.filter(el => el.title_id)
    calculateRentTitles(realTitles);
    console.log('realTitles: ', realTitles);

    const disponibilityBefore = await doGetDisponilityByMonthAndYear(clientId, monthBefore, yearBefore);

    const responseLastQuota = await getClientLastQuota(clientId, month, year);
    console.log('responseLastQuota: ', responseLastQuota);
    const { quota_value: lastQuotaValue, qtd_quotas: beforeTotalQtdQuotas } = responseLastQuota.body[0];

    const provisonalFundQuotas = await getCotasByListCnpjs(cnpjs, startDate, endDate, true);
    console.log('provisonalFundQuotas: ', provisonalFundQuotas);

    // const provisonalTitlesInfos = await getTitlesInfos(realTitles, startDate, endDate);
    // console.log('provisonalTitlesInfos: ', provisonalTitlesInfos);

    if (provisonalFundQuotas.cotas && Object.keys(provisonalFundQuotas.cotas).length) {

        const entries = Object.entries(provisonalFundQuotas.cotas);
        let referenceQuotaList = null;
        entries.forEach(element => {
            const { serie_historica: quotas } = element[1];
            if (quotas.length) {

                if (referenceQuotaList === null) {
                    referenceQuotaList = [...quotas]
                } else if (quotas.length < referenceQuotaList.length) {
                    referenceQuotaList = [...quotas];
                }
            }
        });

        console.log('referenceQuotaList: ', referenceQuotaList)
        const countQuotas = referenceQuotaList.length;
        const referenceQuotaDate = formatAnbimaDateToPt(referenceQuotaList[countQuotas - 1].data_referencia);
        console.log('referenceQuotaDate: ', referenceQuotaDate);

        realAssets.forEach(fund => {

            if (!fund.provisionalInfos) {

                const iterableQuotas = provisonalFundQuotas.cotas[fund.cnpj].serie_historica.slice(0, countQuotas);

                let provisionalQuotaValue = null;
                if (iterableQuotas.length === 0) {
                    //normalizando quotas anbima
                    fund.quotas_anbima_before = fund.quotas_anbima_before ? getClientJsonQuotas(JSON.parse(fund.quotas_anbima_before), fund.client_id) : [];
                    provisionalQuotaValue = fund.quotas_anbima_before[fund.quotas_anbima_before.length - 1].valor_cota;
                } else {
                    provisionalQuotaValue = iterableQuotas[iterableQuotas.length - 1].valor_cota;
                }
                const initValue = parseFloat(fund.balance_before) / parseFloat(fund.quota_amount_before);
                const provisionalRent = ((provisionalQuotaValue / initValue) - 1) * 100;
                const provisionalBalanceNow = parseFloat(fund.quota_amount_before) * provisionalQuotaValue;
                const provisionalMoneyRent = provisionalBalanceNow - parseFloat(fund.balance_before);

                fund.provisionalInfos = {
                    initQuotaValue: initValue,
                    provisionalQuotaValue: provisionalQuotaValue,
                    provisionalRent: provisionalRent,
                    provisionalMoneyRent: provisionalMoneyRent,
                    provisionalBalanceNow: provisionalBalanceNow,
                }

            }


        });

        const dataToReport = [];
        let totalRentMoney = 0.0;
        let totalRentPercent = 1;
        let totalQuotasBefore = 0;

        const titlesToReport = [];

        let totalBalance = realAssets.reduce((acc, current) => acc + current.provisionalInfos.provisionalBalanceNow, 0);
        let totalBalanceTitles = 0;

        if (realTitles.length) {

            const typeTitlesFlows = {
                // 1. Valor do PU Médio (comdinheiro) vs Quantidade (UNO)

                // Os seguintes títulos seguirão essa regra:
                // NTN-B precificada à Mercado
                // NTN-C precificada à Mercado
                // NTN-F precificada à Mercado
                // LTN precificada à Mercado
                type1: [],

                // 2. Taxa Pré-Fixada

                // Os seguintes títulos seguirão essa regra:
                // CDB
                // LTN
                // LF Taxa Pré
                // NTN-F
                type2: [],

                // 3. IPCA + Taxa Pré-Fixada

                // Os seguintes títulos seguirão essa regra:
                // NTN-B precificada na Curva
                // LF IPCA
                // CVS
                // CDB
                type3: [],

                // 4. IGPM + Taxa Pré-Fixada

                // O seguinte título seguirá essa regra:
                // NTN-C precificada na Curva
                type4: [],

                // 5. SELIC Meta

                // O seguinte título seguirá essa regra:
                // LFT
                type5: [],

                // 6. Pós-fixado sobre o CDI

                // O seguinte título seguirá essa regra:
                // CDB
                type6: [],
            }

            typeTitlesFlows.type1 = realTitles.filter(el =>
                (
                    el.label_title_type === "NTNB"
                    || el.label_title_type === "NTNC"
                    || el.label_title_type === "NTNF"
                    || el.label_title_type === "LTN"
                ) && el.marking === "MERCADO"
            );

            typeTitlesFlows.type2 = realTitles.filter(el =>

                (el.label_title_type === "CDB" && !el.indexer) //Adicionar regra
                || (
                    (el.label_title_type === "LTN" && el.marking === "CURVA")
                    || (el.label_title_type === "NTNF" && el.marking === "CURVA") //Adicionar regra
                )
                || (el.label_title_type === "LF" && el.pre_tax && el.indexer !== "IPCA")

            );

            typeTitlesFlows.type3 = realTitles.filter(el =>

                (el.label_title_type === "CDB" && el.indexer === "IPCA") //Adicionar regra
                || (
                    (el.label_title_type === "NTNB" && el.marking === "CURVA")
                    || (el.label_title_type === "LF" && el.pre_tax && el.indexer === "IPCA")
                )
                || el.label_title_type === "CVS"

            );

            typeTitlesFlows.type4 = realTitles.filter(el =>

                el.label_title_type === "NTNC" && el.marking === "CURVA"

            );

            typeTitlesFlows.type5 = realTitles.filter(el =>

                el.label_title_type === "LFT"

            );

            typeTitlesFlows.type6 = realTitles.filter(el =>

                el.label_title_type === "CDB" && el.indexer === "CDI"

            );

            console.log("type1:", typeTitlesFlows.type1);
            console.log("type2:", typeTitlesFlows.type2);
            console.log("type3:", typeTitlesFlows.type3);
            console.log("type4:", typeTitlesFlows.type4);
            console.log("type5:", typeTitlesFlows.type5);
            console.log("type6:", typeTitlesFlows.type6);

            const {
                type1,
                type2,
                type3,
                type4,
                type5,
                type6,
            } = typeTitlesFlows;


            //Cálculo type1
            if (type1.length) {

                const responsePU_med = await doGetPU_med(type1, startDate, endDate);
                if (!responsePU_med) return;

                const { mapTitleCotas } = responsePU_med;

                type1.forEach(title => {

                    const balanceBefore = parseFloat(title.balance_before);
                    const titleCod = getTitleCod(title, true);
                    const quotaReferenceDate = mapTitleCotas[titleCod].find(el => el.date_quota == referenceQuotaDate);

                    if (quotaReferenceDate) {

                        const { pu_med } = quotaReferenceDate;
                        const balanceNow = title.quota_amount_before * pu_med;
                        totalBalance += balanceNow;
                        totalBalanceTitles += balanceNow;

                        titlesToReport.push({
                            fund_name: getTitleCod(title, true) + " - type1",
                            balanceToDisplay: applyMaskAmount(balanceNow, true),
                            provisionalBalanceNow: balanceNow,
                            balanceBefore: balanceBefore,
                            dados_cadastrais: {
                                disponibilization: '-'
                            },
                            sector: title.sector,
                            article_to_show: '-',
                            rt1: '-',
                            rt2: '-',
                        });
                    }



                });
            }

            //Cálculo type2
            if (type2.length) {
                type2.forEach(title => {

                    const balanceBefore = parseFloat(title.balance_before);
                    const Iq = (Math.pow((1 + (parseFloat(title.pre_tax) / 100)), countQuotas / 252) - 1);
                    const balanceNow = (Iq * balanceBefore) + balanceBefore;
                    totalBalance += balanceNow;
                    totalBalanceTitles += balanceNow;
                    titlesToReport.push({
                        fund_name: getTitleCod(title, true) + " - type2",
                        balanceToDisplay: applyMaskAmount(balanceNow, true),
                        provisionalBalanceNow: balanceNow,
                        balanceBefore: balanceBefore,
                        dados_cadastrais: {
                            disponibilization: '-'
                        },
                        sector: title.sector,
                        article_to_show: '-',
                        rt1: '-',
                        rt2: '-',
                    });

                });
            }

            //Cálculo type3
            if (type3.length) {

                const responseIPCAdp = await doGetIPCAdp(startDate, endDate);

                if (responseIPCAdp[0]) {
                    const referenceIpcaDp = truncateDecimals(responseIPCAdp[0].value, 8)
                    type3.forEach(title => {

                        const balanceBefore = parseFloat(title.balance_before);

                        const IqIPCAdp = (Math.pow((1 + referenceIpcaDp), countQuotas) - 1);
                        const IqPreTax = (Math.pow((1 + (parseFloat(title.pre_tax) / 100)), countQuotas / 252) - 1);

                        const IqFinal = (((1 + IqIPCAdp) * (1 + IqPreTax)) - 1) * 100;

                        const balanceNow = (IqFinal * balanceBefore / 100) + balanceBefore;

                        totalBalance += Number.isNaN(balanceNow) ? 0 : balanceNow;
                        totalBalanceTitles += balanceNow;
                        titlesToReport.push({
                            fund_name: getTitleCod(title, true) + " - type3",
                            balanceToDisplay: applyMaskAmount(balanceNow, true),
                            provisionalBalanceNow: balanceNow,
                            balanceBefore: balanceBefore,
                            dados_cadastrais: {
                                disponibilization: '-'
                            },
                            sector: title.sector,
                            article_to_show: '-',
                            rt1: '-',
                            rt2: '-',
                        });


                    });
                }

            }

            //Cálculo type4
            if (type4.length) {

                const responseIGPM = await doGetIGPM(startDate, endDate);

                if (responseIGPM[0]) {
                    const referenceIGPM = truncateDecimals(responseIGPM[0].value, 8)
                    type4.forEach(title => {

                        // Iq = [(1 + It)^q – 1] x 100
                        // Iq = taxa de juros no período que você quer
                        // It = taxa de juros no período que você tem
                        // q = período que você quer

                        const balanceBefore = parseFloat(title.balance_before);

                        const IqIPCAdp = (Math.pow((1 + referenceIGPM), countQuotas) - 1);
                        const IqPreTax = (Math.pow((1 + (parseFloat(title.pre_tax) / 100)), countQuotas / 252) - 1);

                        const IqFinal = (((1 + IqIPCAdp) * (1 + IqPreTax)) - 1) * 100;

                        const balanceNow = (IqFinal * balanceBefore / 100) + balanceBefore;

                        totalBalance += balanceNow;
                        totalBalanceTitles += balanceNow;
                        titlesToReport.push({
                            fund_name: getTitleCod(title, true) + " - type4",
                            balanceToDisplay: applyMaskAmount(balanceNow, true),
                            provisionalBalanceNow: balanceNow,
                            balanceBefore: balanceBefore,
                            dados_cadastrais: {
                                disponibilization: '-'
                            },
                            sector: title.sector,
                            article_to_show: '-',
                            rt1: '-',
                            rt2: '-',
                        });


                    });
                }

            }

            //Cálculo type5
            if (type5.length) {

                const responseSelicMeta = await doSelicMeta(startDate, endDate);
                if (responseSelicMeta[0]) {

                    const referenceSelicMeta = responseSelicMeta.find(el => el.date == referenceQuotaDate);
                    if (referenceSelicMeta) {

                        type5.forEach(title => {

                            const balanceBefore = parseFloat(title.balance_before);
                            const { value } = referenceSelicMeta;
                            const truncReferenceSelicMetaValue = truncateDecimals(value, 8)
                            const balanceNow = (balanceBefore * truncReferenceSelicMetaValue) + balanceBefore;
                            totalBalance += balanceNow;
                            totalBalanceTitles += balanceNow;

                            titlesToReport.push({
                                fund_name: getTitleCod(title, true) + " - type5",
                                balanceToDisplay: applyMaskAmount(balanceNow, true),
                                provisionalBalanceNow: balanceNow,
                                balanceBefore: balanceBefore,
                                dados_cadastrais: {
                                    disponibilization: '-'
                                },
                                sector: title.sector,
                                article_to_show: '-',
                                rt1: '-',
                                rt2: '-',
                            });


                        });
                    }
                }

            }
            //Cálculo type6
            if (type6.length) {

                const responseCDI = await doGetCDI(startDate, endDate, true, REQ_COMDINHEIRO_TYPE.RELATORIO_PROVISORIO);
                const referenceCDI = responseCDI.find(el => el.date == referenceQuotaDate);
                if (referenceCDI) {
                    type6.forEach(title => {

                        const balanceBefore = parseFloat(title.balance_before);
                        const { value } = referenceCDI;
                        const truncReferenceCDIValue = truncateDecimals(value / 100, 8)
                        const indexerPercent = parseFloat(title.indexer_percent);
                        const finalCDIValue = indexerPercent / 100 * truncReferenceCDIValue;
                        const balanceNow = (balanceBefore * finalCDIValue) + balanceBefore;
                        totalBalance += balanceNow;
                        totalBalanceTitles += balanceNow;

                        titlesToReport.push({
                            fund_name: getTitleCod(title, true) + " - type6",
                            balanceToDisplay: applyMaskAmount(balanceNow, true),
                            provisionalBalanceNow: balanceNow,
                            balanceBefore: balanceBefore,
                            dados_cadastrais: {
                                disponibilization: '-'
                            },
                            sector: title.sector,
                            article_to_show: '-',
                            rt1: '-',
                            rt2: '-',
                        });


                    });

                }

            }

        }

        //Após calcular os saldos rentabilizados dos títulos, considerá-los no total balance e setar as participações        
        console.log('totalBalance: ', totalBalance);
        console.log('titlesToReport: ', titlesToReport);
        titlesToReport.forEach(el => {
            el.participation = applyMaskAmount(el.provisionalBalanceNow / totalBalance * 100) + '%';

            const rent = el.provisionalBalanceNow - el.balanceBefore;

            totalRentMoney += rent;

            el.rt1 = applyMaskAmount(rent, true);
            el.rt2 = applyMaskAmount(((el.provisionalBalanceNow / el.balanceBefore) - 1) * 100) + '%';
        })

        realAssets.forEach(asset => {

            const { provisionalInfos } = asset;
            asset.balanceToDisplay = applyMaskAmount(provisionalInfos.provisionalBalanceNow, true);
            const rentFundMonth = provisionalInfos.provisionalRent;

            const rentMoney = provisionalInfos.provisionalMoneyRent;

            //RENT R$
            asset.rt1 = applyMaskAmount(rentMoney, true)
            //RENT %
            asset.rt2 = applyMaskAmount(rentFundMonth) + "%"

            totalRentMoney += rentMoney;

            //Caso o fundo seja especial ele não possui dados cadatrais, então inicializa-se a disponibilização com vazio pois é um campo utilizado 
            //no portfolio_report e daria null pointer caso dados_cadastrais não existissem e se tentasse acessar o atributo "disponibilization"

            if (asset.dados_cadastrais && asset.dados_cadastrais.disponibilization) {

                asset.dados_cadastrais.disponibilization = disponibilizationDicionary(asset.dados_cadastrais.disponibilization).label;

            } else {
                asset.dados_cadastrais = {
                    disponibilization: "-",
                }
            }

            asset.resolutionName = '4.963';
            asset.article_to_show = asset.device_abbreviation ? asset.device_abbreviation : '-';
            asset.participation = applyMaskAmount(provisionalInfos.provisionalBalanceNow / totalBalance * 100) + '%';


            //Normalizando nome do título para fundo
            if (asset.title_id) {
                asset.fund_name = getTitleCod(asset);
            }

            if (asset.fund_name) {
                asset.fund_name = formatStringToDisplay(asset.fund_name, 6, '...');
            }

            dataToReport.push(asset);
        });

        const publicTitles = [];
        const privateTitles = [];
        const genericTitles = [];

        titlesToReport.forEach(ass => {

            if (ass.label_title_type == GENERIC_ASSET_TITLES_TYPE) {
                genericTitles.push(ass);
            } else if (ass.sector == 'PUBLICO') {
                publicTitles.push(ass);
            } else if (ass.sector == 'PRIVADO') {
                privateTitles.push(ass);
            }

        });

        const publicTitlesBalanceBefore = publicTitles.reduce((acc, current) => acc + current.balanceBefore, 0)
        const publicTitlesBalanceNow = publicTitles.reduce((acc, current) => acc + current.provisionalBalanceNow, 0)
        const publicTitlesRent = publicTitlesBalanceNow - publicTitlesBalanceBefore;

        const privateTitlesBalanceBefore = privateTitles.reduce((acc, current) => acc + current.balanceBefore, 0)
        const privateTitlesBalanceNow = privateTitles.reduce((acc, current) => acc + current.provisionalBalanceNow, 0)
        const privateTitlesRent = privateTitlesBalanceNow - privateTitlesBalanceBefore;

        const genericTitlesBalanceBefore = genericTitles.reduce((acc, current) => acc + current.balanceBefore, 0)
        const genericTitlesBalanceNow = genericTitles.reduce((acc, current) => acc + current.provisionalBalanceNow, 0)
        const genericTitlesRent = genericTitlesBalanceNow - genericTitlesBalanceBefore;

        if (publicTitles.length) {

            dataToReport.push({
                fund_name: PUBLIC_TITLES,
                balanceToDisplay: applyMaskAmount(publicTitlesBalanceNow, true),
                participation: applyMaskAmount(publicTitlesBalanceNow / totalBalance * 100) + '%',
                provisionalBalanceNow: publicTitlesBalanceNow,
                balanceBefore: publicTitlesBalanceBefore,
                dados_cadastrais: {
                    disponibilization: '-'
                },
                sector: "PULICO",
                article_to_show: '-',
                rt1: applyMaskAmount(publicTitlesRent, true),
                rt2: applyMaskAmount(((publicTitlesBalanceNow / publicTitlesBalanceBefore) - 1) * 100) + '%',
            })
        }

        if (privateTitles.length) {
            dataToReport.push({
                fund_name: PRIVATE_TITLES,
                balanceToDisplay: applyMaskAmount(privateTitlesBalanceNow, true),
                participation: applyMaskAmount(privateTitlesBalanceNow / totalBalance * 100) + '%',
                provisionalBalanceNow: privateTitlesBalanceNow,
                balanceBefore: privateTitlesBalanceBefore,
                dados_cadastrais: {
                    disponibilization: '-'
                },
                sector: "PRIVADO",
                article_to_show: '-',
                rt1: applyMaskAmount(privateTitlesRent, true),
                rt2: applyMaskAmount(((privateTitlesBalanceNow / privateTitlesBalanceBefore) - 1) * 100) + '%',
            })
        }

        if (genericTitles.length) {
            dataToReport.push({
                fund_name: GENERIC_ASSETS,
                balanceToDisplay: applyMaskAmount(genericTitlesBalanceNow, true),
                participation: applyMaskAmount(genericTitlesBalanceNow / totalBalance * 100) + '%',
                provisionalBalanceNow: genericTitlesBalanceNow,
                balanceBefore: genericTitlesBalanceBefore,
                dados_cadastrais: {
                    disponibilization: '-'
                },
                sector: GENERIC_ASSETS,
                article_to_show: '-',
                // rt1: applyMaskAmount(genericTitlesRent, true),
                // rt2: applyMaskAmount(((genericTitlesBalanceNow / genericTitlesBalanceBefore) - 1) * 100) + '%',
                rt1: "-",
                rt2: "-",
            })

        }

        //Adicionando linhas de títulos
        // titlesToReport.forEach(element => {
        //     dataToReport.push(element);
        // });
        const newQuotaValue = totalBalance / parseFloat(beforeTotalQtdQuotas);

        const totalPortfolioPeriodRent = applyMaskAmount(((newQuotaValue / parseFloat(lastQuotaValue)) - 1) * 100);

        dataToReport.push({
            fund_name: 'Total investimentos',
            balanceToDisplay: applyMaskAmount(totalBalance, true),
            participation: '100.00%',
            rt1: applyMaskAmount(totalRentMoney, true),
            rt2: totalPortfolioPeriodRent + '%',
            dados_cadastrais: {
                disponibilization: "",
            }
        });

        dataToReport.push({
            fund_name: 'Disponibilidade',
            balanceToDisplay: applyMaskAmount(disponibilityBefore, true),
            participation: '-',
            rt1: '-',
            rt2: '-',
            dados_cadastrais: {
                disponibilization: "",
            }
        });

        dataToReport.push({
            fund_name: 'Total patrimônio',
            balanceToDisplay: applyMaskAmount(totalBalance + disponibilityBefore, true),
            participation: '100.00%',
            rt1: '-',
            rt2: '-',
            dados_cadastrais: {
                disponibilization: "",
            }
        });

        //clean object to stream, retirando subassets e subAssetTitles
        dataToReport.forEach(element => {
            if (element.subAssetsTitles) {
                delete element.subAssetsTitles
            }

            if (element.subAssets) {
                delete element.subAssets
            }

        });

        console.log("dataToReport: ", dataToReport);

        return {
            success: true,
            dataToReport: dataToReport,
            referenceQuotaDate: referenceQuotaDate,
        }

    }

}

export async function doClientDataBaseByYear(clientId, clientLabelName, year) {

    //Obtendo movimentações
    const firstDayYear = getFirstDayYear(year);
    const lastDayYear = getLastPtDayOfYear(year);
    console.log("firstDayYear: ", firstDayYear);
    console.log("lastDayYear: ", lastDayYear);

    ///////////////////////
    //BASE DE MOVIMENTAÇÕES
    ///////////////////////
    const transactionsResponse = await getClientAllTransactionByDateRangeAndRegime(clientId, firstDayYear, lastDayYear, null, true);
    //console.log("transactionsResponse: ", transactionsResponse);


    const regimeMap = {
        1: 'PREVIDENCIÁRIO',
        2: 'FINANCEIRO',
        3: 'TAXA ADMINISTRATIVA'
    }

    const operationMap = {
        1: 'APLICAÇÃO',
        2: 'RESGATE',
        3: 'AMORTIZAÇÃO'
    }

    const fundsIds = [];
    const titlesIds = [];
    const transactions = transactionsResponse.body.rows;
    transactions.forEach(el => {
        if (el.title_id && !titlesIds.includes(el.title_id)) titlesIds.push(el.title_id);
        if (el.fund_id && !fundsIds.includes(el.fund_id)) fundsIds.push(el.fund_id);
    });


    const fundsQuotasResponse = await getLocalQuotasByFundsIdsAndYear(fundsIds, year);
    console.log("fundsQuotasResponse: ", fundsQuotasResponse);
    const fundsQuotas = fundsQuotasResponse.body.rows;
    const mapFundsQuotas = {};
    fundsQuotas.forEach(element => {
        const { fund_id, json_quotas, month, year } = element;
        const quotas = JSON.parse(json_quotas);
        if (!mapFundsQuotas[fund_id]) {
            mapFundsQuotas[fund_id] = {}
        }
        const period = month + "/" + year;
        mapFundsQuotas[fund_id][period] = quotas;
    });

    const mapTitles = {};
    if (titlesIds.length) {
        const titlesResponse = await getTitlesByIds(titlesIds);
        const titles = titlesResponse.body.rows;
        console.log("titles: ", titles)

        titles.forEach(element => {
            mapTitles[element.id] = element;
        });
    }

    const getFundQuotasValue = (transaction, allQuotasFunds) => {
        if (transaction.title_id) {
            return "";
        } else if (transaction.operation_id == 3) {
            return 0;
        }

        const transactionDate = moment.utc(transaction.transaction_date).format("DD/MM/YYYY");
        const anbimaDate = formatPtStringDateToAnbimaDate(transactionDate);
        const spliitedDate = anbimaDate.split('-');
        const periodTransation = parseInt(spliitedDate[1]) + "/" + spliitedDate[0]

        const foundQuota = allQuotasFunds[periodTransation].find(el => el.data_referencia == anbimaDate);

        if (foundQuota) {
            return parseFloat(transaction.amount) / foundQuota.valor_cota;
        }

        return "";

    }

    const formattedTransactions = transactions.map(t => ({
        numberApr: t.number_apr,
        segregation: regimeMap[t.account_regime_id],
        cnpj: t.title_id ? mapTitles[t.title_id].label_title_type + " " + moment.utc(mapTitles[t.title_id].dua_date).format("DD/MM/YYYY") : t.fund_cnpj,
        fundName: t.title_id ? getTitleCod(mapTitles[t.title_id]) : t.fund_name,
        cc: applyMaskAccount(t.account),
        transactionDate: moment.utc(t.transaction_date).format("DD/MM/YYYY"),
        operation: operationMap[t.operation_id],
        quotas: t.title_id ? "" : getFundQuotasValue(t, mapFundsQuotas[t.fund_id]),
        amount: applyMaskAmount(t.amount, true),
        description: t.apr_description,
    }))

    console.log("formattedTransactions: ", formattedTransactions);

    ///////////////////////
    //BASE DE SALDOS FUNDOS
    ///////////////////////

    const responseAssets = await getAllClientAssetsByRegime(clientId, null);
    const responseBv = await getClientInvestmentsBalancesValidationsByYearAndRegime(year, clientId, null);

    const formattedFundsBalancesInYear = formatBalancesAssetsValidationToShow(year,
        responseAssets.body.rows,
        responseBv.body.rows);

    console.log("formattedFundsBalancesInYear: ", formattedFundsBalancesInYear);

    ///////////////////////
    //BASE DE SALDOS CONTAS
    ///////////////////////

    const responseAccs = await getClientAccountsByRegimeAndDate(clientId, null);
    const responseBvAccounts = await getClientAccountsBalancesValidationsByYear(year, clientId);
    const formattedAccountsBalancesInYear = formatBalancesAccountsValidationToShow(year,
        responseAccs.body.rows,
        responseBvAccounts.body.rows)

    console.log("formattedAccountsBalancesInYear: ", formattedAccountsBalancesInYear);

    ///////////////////////
    //BASE DE TITULOS
    ///////////////////////

    const responseTitleAssets = await getClientAssetsTitles(year, clientId, null);
    console.log("RESPONSE TITLES: ", responseTitleAssets);
    const responseBvTitles = await getClientTitlesBalancesValidationsByYearAndRegime(year, clientId, null);
    const formattedTitlesBalancesInYear = formatInfosToShow(year,
        responseTitleAssets.body.rows,
        responseBvTitles.body.rows)

    console.log("formattedTitlesBalancesInYear: ", formattedTitlesBalancesInYear);

    /////////////////////////////
    //GERANDO EXCEL MOVIMENTAÇÕES
    /////////////////////////////
    const wbMovs = XLSX.utils.book_new();
    const fileNameMovs = "Movimentações " + year;
    wbMovs.Props = {
        Title: fileNameMovs,
        Author: "UNO",
    }

    const sheetNameMovs = fileNameMovs;
    wbMovs.SheetNames.push(sheetNameMovs);

    const ws_data_movs = [];

    ws_data_movs.push(['NÚM. APR', 'SEGREGAÇÃO', 'CNPJ', 'FUNDO', 'CC', 'DATA', 'OPERAÇÃO', 'QUANTIDADE DE COTAS', 'VALOR', 'DESCRIÇÃO'])

    formattedTransactions.forEach(transaction => {

        ws_data_movs.push([
            transaction.numberApr,
            transaction.segregation,
            transaction.cnpj,
            transaction.fundName,
            transaction.cc,
            transaction.transactionDate,
            transaction.operation,
            transaction.quotas,
            transaction.amount,
            transaction.description,
        ])

    });

    const ws_movs = XLSX.utils.aoa_to_sheet(ws_data_movs);

    wbMovs.Sheets[sheetNameMovs] = ws_movs;
    const wbout_movs = XLSX.write(wbMovs, { bookType: 'xlsx', type: 'binary' })

    //downloadBlob(new Blob([s2ab(wbout_movs)], { type: "application/octet-stream" }), fileNameMovs + '.xlsx');
    const movsBlob = new Blob([s2ab(wbout_movs)], { type: "application/octet-stream" });

    /////////////////////////////
    //GERANDO EXCEL SALDOS
    /////////////////////////////
    const wbBalances = XLSX.utils.book_new();
    const fileNameBalances = "Ativos e Contas " + year;
    wbBalances.Props = {
        Title: fileNameBalances,
        Author: "UNO",
    }

    wbBalances.SheetNames.push("Ativos");
    wbBalances.SheetNames.push("Contas");

    const ws_data_balances_assets = [];

    ws_data_balances_assets.push(['SEGREGAÇÃO', 'CC', 'CNPJ DO FUNDO', 'NOME DO FUNDO',
        'QUANTIDADE DE COTAS JAN',
        'VALOR JAN',
        'QUANTIDADE DE COTAS FEV',
        'VALOR FEV',
        'QUANTIDADE DE COTAS MAR',
        'VALOR MAR',
        'QUANTIDADE DE COTAS ABR',
        'VALOR ABR',
        'QUANTIDADE DE COTAS MAI',
        'VALOR MAI',
        'QUANTIDADE DE COTAS JUN',
        'VALOR JUN',
        'QUANTIDADE DE COTAS JUL',
        'VALOR JUL',
        'QUANTIDADE DE COTAS AGO',
        'VALOR AGO',
        'QUANTIDADE DE COTAS SET',
        'VALOR SET',
        'QUANTIDADE DE COTAS OUT',
        'VALOR OUT',
        'QUANTIDADE DE COTAS NOV',
        'VALOR NOV',
        'QUANTIDADE DE COTAS DEZ',
        'VALOR DEZ',
    ])

    const { assets } = formattedFundsBalancesInYear;

    Object.entries(assets).map(([key, asset]) => {

        const quotaAmount1 = asset[1].quota_amount != null ? parseFloat(asset[1].quota_amount) : "";
        const quotaAmount2 = asset[2].quota_amount != null ? parseFloat(asset[2].quota_amount) : "";
        const quotaAmount3 = asset[3].quota_amount != null ? parseFloat(asset[3].quota_amount) : "";
        const quotaAmount4 = asset[4].quota_amount != null ? parseFloat(asset[4].quota_amount) : "";
        const quotaAmount5 = asset[5].quota_amount != null ? parseFloat(asset[5].quota_amount) : "";
        const quotaAmount6 = asset[6].quota_amount != null ? parseFloat(asset[6].quota_amount) : "";
        const quotaAmount7 = asset[7].quota_amount != null ? parseFloat(asset[7].quota_amount) : "";
        const quotaAmount8 = asset[8].quota_amount != null ? parseFloat(asset[8].quota_amount) : "";
        const quotaAmount9 = asset[9].quota_amount != null ? parseFloat(asset[9].quota_amount) : "";
        const quotaAmount10 = asset[10].quota_amount != null ? parseFloat(asset[10].quota_amount) : "";
        const quotaAmount11 = asset[11].quota_amount != null ? parseFloat(asset[11].quota_amount) : "";
        const quotaAmount12 = asset[12].quota_amount != null ? parseFloat(asset[12].quota_amount) : "";


        ws_data_balances_assets.push([
            regimeMap[asset.infosAssets.account_regime_id],
            applyMaskAccount(asset.infosAssets.number_account),
            asset.infosAssets.cnpj,
            asset.infosAssets.fund_name,
            quotaAmount1 < 0 ? 0 : quotaAmount1,
            asset[1].balance ? applyMaskAmount(asset[1].balance, true) : "",
            quotaAmount2 < 0 ? 0 : quotaAmount2,
            asset[2].balance ? applyMaskAmount(asset[2].balance, true) : "",
            quotaAmount3 < 0 ? 0 : quotaAmount3,
            asset[3].balance ? applyMaskAmount(asset[3].balance, true) : "",
            quotaAmount4 < 0 ? 0 : quotaAmount4,
            asset[4].balance ? applyMaskAmount(asset[4].balance, true) : "",
            quotaAmount5 < 0 ? 0 : quotaAmount5,
            asset[5].balance ? applyMaskAmount(asset[5].balance, true) : "",
            quotaAmount6 < 0 ? 0 : quotaAmount6,
            asset[6].balance ? applyMaskAmount(asset[6].balance, true) : "",
            quotaAmount7 < 0 ? 0 : quotaAmount7,
            asset[7].balance ? applyMaskAmount(asset[7].balance, true) : "",
            quotaAmount8 < 0 ? 0 : quotaAmount8,
            asset[8].balance ? applyMaskAmount(asset[8].balance, true) : "",
            quotaAmount9 < 0 ? 0 : quotaAmount9,
            asset[9].balance ? applyMaskAmount(asset[9].balance, true) : "",
            quotaAmount10 < 0 ? 0 : quotaAmount10,
            asset[10].balance ? applyMaskAmount(asset[10].balance, true) : "",
            quotaAmount11 < 0 ? 0 : quotaAmount11,
            asset[11].balance ? applyMaskAmount(asset[11].balance, true) : "",
            quotaAmount12 < 0 ? 0 : quotaAmount12,
            asset[12].balance ? applyMaskAmount(asset[12].balance, true) : "",
        ])
    })

    const ws_data_balances_accounts = [];

    ws_data_balances_accounts.push(['SEGREGAÇÃO', 'CC', 'CÓDIGO - NOME DA INSTITUIÇÃO',
        'SALDO JAN',
        'SALDO FEV',
        'SALDO MAR',
        'SALDO ABR',
        'SALDO MAI',
        'SALDO JUN',
        'SALDO JUL',
        'SALDO AGO',
        'SALDO SET',
        'SALDO OUT',
        'SALDO NOv',
        'SALDO DEZ',
    ])

    const { accounts } = formattedAccountsBalancesInYear;

    console.log("accounts: ", accounts);

    Object.entries(accounts).map(([key, account]) => {

        console.log("account: ", account);

        ws_data_balances_accounts.push([
            regimeMap[account.account_regime_id],
            applyMaskAccount(account.infosAccounts.number_account),
            account.infosAccounts.instituition,
            account[1].balance ? applyMaskAmount(account[1].balance, true) : "",
            account[2].balance ? applyMaskAmount(account[2].balance, true) : "",
            account[3].balance ? applyMaskAmount(account[3].balance, true) : "",
            account[4].balance ? applyMaskAmount(account[4].balance, true) : "",
            account[5].balance ? applyMaskAmount(account[5].balance, true) : "",
            account[6].balance ? applyMaskAmount(account[6].balance, true) : "",
            account[7].balance ? applyMaskAmount(account[7].balance, true) : "",
            account[8].balance ? applyMaskAmount(account[8].balance, true) : "",
            account[9].balance ? applyMaskAmount(account[9].balance, true) : "",
            account[10].balance ? applyMaskAmount(account[10].balance, true) : "",
            account[11].balance ? applyMaskAmount(account[11].balance, true) : "",
            account[12].balance ? applyMaskAmount(account[12].balance, true) : "",
        ])
    })

    const ws_balances_assets = XLSX.utils.aoa_to_sheet(ws_data_balances_assets);
    const ws_balances_accounts = XLSX.utils.aoa_to_sheet(ws_data_balances_accounts);

    wbBalances.Sheets["Ativos"] = ws_balances_assets;
    wbBalances.Sheets["Contas"] = ws_balances_accounts;
    const wbout_balances = XLSX.write(wbBalances, { bookType: 'xlsx', type: 'binary' })

    //downloadBlob(new Blob([s2ab(wbout_balances)], { type: "application/octet-stream" }), fileNameBalances + '.xlsx');
    const fundsAndAccountsBalancesBlob = new Blob([s2ab(wbout_balances)], { type: "application/octet-stream" });

    /////////////////////////////
    //GERANDO EXCEL DE TITULOS
    /////////////////////////////
    const wbTitles = XLSX.utils.book_new();
    const fileNameTitles = "Títulos " + year;
    wbTitles.Props = {
        Title: fileNameTitles,
        Author: "UNO",
    }

    wbTitles.SheetNames.push("Dados");
    wbTitles.SheetNames.push("Saldos");

    const ws_data_data_titles = [];

    ws_data_data_titles.push(['TÍTULO', 'COMPRA', 'VENCIMENTO', 'QUANTIDADE',
        'TAXA',
        'MARCAÇÃO',
    ])


    formattedTitlesBalancesInYear.forEach(title => {
        ws_data_data_titles.push([
            title.title_name,
            moment.utc(title.purchase_date).format("DD/MM/YYYY"),
            moment.utc(title.due_date).format("DD/MM/YYYY"),
            title.quantity_purchased,
            title.pre_tax && title.marking != MARKING_MARKET ? applyMaskAmount(title.pre_tax) + "%" : '',
            title.marking ?? "",
        ])

    });

    const ws_data_balances_titles = [];


    ws_data_balances_titles.push(['SEGREGAÇÃO', 'CC', 'TÍTULO', 'VENCIMENTO',
        'QUANTIDADE JAN',
        'VALOR JAN',
        'QUANTIDADE FEV',
        'VALOR FEV',
        'QUANTIDADE MAR',
        'VALOR MAR',
        'QUANTIDADE ABR',
        'VALOR ABR',
        'QUANTIDADE MAI',
        'VALOR MAI',
        'QUANTIDADE JUN',
        'VALOR JUN',
        'QUANTIDADE JUL',
        'VALOR JUL',
        'QUANTIDADE AGO',
        'VALOR AGO',
        'QUANTIDADE SET',
        'VALOR SET',
        'QUANTIDADE OUT',
        'VALOR OUT',
        'QUANTIDADE NOV',
        'VALOR NOV',
        'QUANTIDADE DEZ',
        'VALOR DEZ',
    ])

    formattedTitlesBalancesInYear.forEach(title => {

        const quotaAmount1 = title.yearValidation?.months[1]?.quota_amount != null ? parseFloat(title.yearValidation.months[1].quota_amount) : "";
        const quotaAmount2 = title.yearValidation?.months[2]?.quota_amount != null ? parseFloat(title.yearValidation.months[2].quota_amount) : "";
        const quotaAmount3 = title.yearValidation?.months[3]?.quota_amount != null ? parseFloat(title.yearValidation.months[3].quota_amount) : "";
        const quotaAmount4 = title.yearValidation?.months[4]?.quota_amount != null ? parseFloat(title.yearValidation.months[4].quota_amount) : "";
        const quotaAmount5 = title.yearValidation?.months[5]?.quota_amount != null ? parseFloat(title.yearValidation.months[5].quota_amount) : "";
        const quotaAmount6 = title.yearValidation?.months[6]?.quota_amount != null ? parseFloat(title.yearValidation.months[6].quota_amount) : "";
        const quotaAmount7 = title.yearValidation?.months[7]?.quota_amount != null ? parseFloat(title.yearValidation.months[7].quota_amount) : "";
        const quotaAmount8 = title.yearValidation?.months[8]?.quota_amount != null ? parseFloat(title.yearValidation.months[8].quota_amount) : "";
        const quotaAmount9 = title.yearValidation?.months[9]?.quota_amount != null ? parseFloat(title.yearValidation.months[9].quota_amount) : "";
        const quotaAmount10 = title.yearValidation?.months[10]?.quota_amount != null ? parseFloat(title.yearValidation.months[10].quota_amount) : "";
        const quotaAmount11 = title.yearValidation?.months[11]?.quota_amount != null ? parseFloat(title.yearValidation.months[11].quota_amount) : "";
        const quotaAmount12 = title.yearValidation?.months[12]?.quota_amount != null ? parseFloat(title.yearValidation.months[12].quota_amount) : "";


        ws_data_balances_titles.push([
            regimeMap[title.account_regime_id],
            applyMaskAccount(title.number_account),
            title.title_name,
            moment.utc(title.due_date).format("DD/MM/YYYY"),
            quotaAmount1 < 0 ? 0 : quotaAmount1,
            title.yearValidation?.months[1]?.balance ? applyMaskAmount(title.yearValidation.months[1].balance, true) : "",
            quotaAmount2 < 0 ? 0 : quotaAmount2,
            title.yearValidation?.months[2]?.balance ? applyMaskAmount(title.yearValidation.months[2].balance, true) : "",
            quotaAmount3 < 0 ? 0 : quotaAmount3,
            title.yearValidation?.months[3]?.balance ? applyMaskAmount(title.yearValidation.months[3].balance, true) : "",
            quotaAmount5 < 0 ? 0 : quotaAmount4,
            title.yearValidation?.months[4]?.balance ? applyMaskAmount(title.yearValidation.months[4].balance, true) : "",
            quotaAmount5 < 0 ? 0 : quotaAmount5,
            title.yearValidation?.months[5]?.balance ? applyMaskAmount(title.yearValidation.months[5].balance, true) : "",
            quotaAmount6 < 0 ? 0 : quotaAmount6,
            title.yearValidation?.months[6]?.balance ? applyMaskAmount(title.yearValidation.months[6].balance, true) : "",
            quotaAmount7 < 0 ? 0 : quotaAmount7,
            title.yearValidation?.months[7]?.balance ? applyMaskAmount(title.yearValidation.months[7].balance, true) : "",
            quotaAmount8 < 0 ? 0 : quotaAmount8,
            title.yearValidation?.months[8]?.balance ? applyMaskAmount(title.yearValidation.months[8].balance, true) : "",
            quotaAmount9 < 0 ? 0 : quotaAmount9,
            title.yearValidation?.months[9]?.balance ? applyMaskAmount(title.yearValidation.months[9].balance, true) : "",
            quotaAmount10 < 0 ? 0 : quotaAmount10,
            title.yearValidation?.months[10]?.balance ? applyMaskAmount(title.yearValidation.months[10].balance, true) : "",
            quotaAmount11 < 0 ? 0 : quotaAmount11,
            title.yearValidation?.months[11]?.balance ? applyMaskAmount(title.yearValidation.months[11].balance, true) : "",
            quotaAmount12 < 0 ? 0 : quotaAmount12,
            title.yearValidation?.months[12]?.balance ? applyMaskAmount(title.yearValidation.months[12].balance, true) : "",
        ])
    });

    const ws_data_titles = XLSX.utils.aoa_to_sheet(ws_data_data_titles);
    const ws_balances_titles = XLSX.utils.aoa_to_sheet(ws_data_balances_titles);

    wbTitles.Sheets["Dados"] = ws_data_titles;
    wbTitles.Sheets["Saldos"] = ws_balances_titles;
    const wbout_titles = XLSX.write(wbTitles, { bookType: 'xlsx', type: 'binary' })

    //downloadBlob(new Blob([s2ab(wbout_titles)], { type: "application/octet-stream" }), fileNameTitles + '.xlsx');
    const titlesBlob = new Blob([s2ab(wbout_titles)], { type: "application/octet-stream" });


    // Nome dos arquivos dentro do ZIP
    const fileName1 = fileNameMovs + '.xlsx';
    const fileName2 = fileNameBalances + '.xlsx';
    const fileName3 = fileNameTitles + '.xlsx';

    // Crie uma nova instância de JSZip
    const zip = new JSZip();

    // Adicione os blobs ao ZIP
    zip.file(fileName1, movsBlob);
    zip.file(fileName2, fundsAndAccountsBalancesBlob);
    zip.file(fileName3, titlesBlob);

    // Gera o arquivo ZIP como um blob
    zip.generateAsync({ type: "blob" })
        .then(function (content) {
            // Crie um link para download do ZIP
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = `Base_de_dados_[${clientLabelName}]_` + year + ".zip";
            link.click();
        })
        .catch(function (err) {
            console.error("Erro ao gerar o arquivo ZIP:", err);
        });

}