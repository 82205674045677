import moment from 'moment';
import _, { filter, forEach, isArray } from 'lodash';
import {
    getDetailFund,
    getHistoricSeriesFund,
    getExplicationNote,
    getToken,
    getIndexes
} from "../API/quotas-api"

import {
    saveFundClientRent
} from "../API/client"

import {
    getFundsClientByPeriodRent, getFullFundsClientByPeriodRent, getClientAllAssetsByDateAndRegime
} from "../API/fund"

import {
    getAllTransactionsUntilDateAndRegime, getClientAllTransactionByDateRange, getClientAllTransactionByDateRangeAndRegime
} from "../API/transaction"

import {
    formatPtStringDate,
    formatPtStringDateToAnbimaDate,
    getDayMonthsAgo,
    getDayMonthsAgoWithOneDayMore,
    getFirstDayMonth,
    getLastDayInMonthByMonthAndYear,
    getMonthFundRentInMoney,
    getInMonthRentFundInPortfolio,
    getMonthsUntilPortfolioInit,
    getDayMonthYearByStringDate,
    isAssetInitByMonthAndYear,
    removeWholeRescuesAssets,
    getLastDayMonth,
    getLastMomentDayMonth,
    getFirstBeforeBusinessDay,
    getStartDateAndEndDateByMonthYear,
    stringPtDateToJsDate,
    getFirstPtDayMonth,
    getMonthAndYearBefore,
    getYearPeriod,
    getMonthAndYearPeriod,
    applyMaskAmount
} from '../components/utils/utils'
import { getClientJsonQuotas, getLocalInfosByListFunds } from './FundLocalQuotasController';
import { organizeAssetsAndTransactions, simpleSumBalancesParticipation } from './AssetsController';
import { getInfosAndRiskFundComdinheiroByDate } from '../API/comdinheiro-api';
import { ArticleParams } from '../components/utils/ArticleParams';
import { REQ_COMDINHEIRO_TYPE } from '../API/reqs_comdinheiro';


//Considerando o inicio em 2005
const periodToInitFund = {
    startDateSeries: '2004-12-01',
    endDateSeries: '2004-12-31'
}

function checkAnbimaDateIsAfter(d1, d2) {

    //Ano, mês, dia
    let d1Splitted = d1.toString().split('-');
    let d2Splitted = d2.toString().split('-');

    d1 = new Date(d1Splitted[0], parseInt(d1Splitted[1]) - 1, d1Splitted[2]);
    d2 = new Date(d2Splitted[0], parseInt(d2Splitted[1]) - 1, d2Splitted[2]);

    console.log("D1: ", d1)
    console.log("D2: ", d2)

    if (d1.getTime() > d2.getTime()) {
        return true;
    } else {
        return false;
    }

}

function getTheLastDayCompleteMonth() {

    //Verificando se o dia atual é o último dia do mês
    let test = new Date();
    let month = test.getMonth();

    test.setDate(test.getDate() + 1);
    if (test.getMonth() !== month) {
        //return formatPtStringDateToAnbimaDate(moment.utc(new Date()).format('DD/MM/YYYY'))
        return formatPtStringDateToAnbimaDate(moment(new Date()).format('DD/MM/YYYY'))
    } else {
        // return formatPtStringDateToAnbimaDate(moment.utc(
        //     new Date(parseInt(moment.utc(new Date()).format('YYYY')), parseInt(moment.utc(new Date()).format('MM')) - 1, 0)).format('DD/MM/YYYY')
        // );
        return formatPtStringDateToAnbimaDate(moment(
            new Date(parseInt(moment(new Date()).format('YYYY')), parseInt(moment(new Date()).format('MM')) - 1, 0)).format('DD/MM/YYYY')
        );
    }
}

/**
 * @param {obj} fund o fundo a ser normalizado
 * @param {obj} monthsAgo quando informado o metodo deve trazer a série histórica baseada na quantidade de meses que se informou
 * @param {obj} untilTheLastTotalMonth indica que a série deve ir até o último dia do mês fechado
 * @returns {obj} objFormatado
 */
async function loadFundInfos(fund, monthsAgo, untilTheLastTotalMonth) {

    let response = await getDetailFund(fund.anbima_cod);

    if (response.success) {

        let infos = response.body;
        let responseHistoricSeries = null;

        if (monthsAgo) {

            let lastFundQuota = infos.serie_historica.data_referencia;
            let referenceDate = "";

            //A série deve trazer os dados até o último mês fechado
            if (untilTheLastTotalMonth) {
                referenceDate = getTheLastDayCompleteMonth();
                //Para garantir trazer cotas do último mês
                monthsAgo++;
            } else {
                referenceDate = lastFundQuota
            }

            let initFundDate = infos['dados-cadastrais'].data_primeiro_aporte;

            let splittedReferenceDate = referenceDate.split('-');

            let startDateSeries = null;
            if (untilTheLastTotalMonth) {

                startDateSeries = formatPtStringDateToAnbimaDate(
                    getDayMonthsAgoWithOneDayMore(
                        parseInt(splittedReferenceDate[2]) + '/' +
                        parseInt(splittedReferenceDate[1]) + '/' +
                        parseInt(splittedReferenceDate[0]),
                        monthsAgo)
                );

            } else {
                startDateSeries = formatPtStringDateToAnbimaDate(
                    getDayMonthsAgo(
                        parseInt(splittedReferenceDate[2]) + '/' +
                        parseInt(splittedReferenceDate[1]) + '/' +
                        parseInt(splittedReferenceDate[0]),
                        monthsAgo)
                );
            }

            let endDateSeries = referenceDate;

            console.log("Início do fundo: ", initFundDate);
            console.log("Última cota do fundo: ", lastFundQuota);
            console.log("START SERIES: ", startDateSeries);
            console.log("END SERIES: ", endDateSeries);

            //periodsSeries deve conter os objetos de incio do fundo e últimos meses requeridos pelo monthsAgo
            let periodsSeries = [];

            //Inserindo periodo obtido através do monthsAgo
            periodsSeries.push({
                startDateSeries: startDateSeries,
                endDateSeries: endDateSeries
            })

            //Inserindo periodo a partir da data considerada com inicio dos fundos nesse caso 2004-12-01 e 2004-12-31
            if (checkAnbimaDateIsAfter(initFundDate, periodToInitFund.endDateSeries)) {
                periodsSeries.push({
                    startDateSeries: initFundDate,
                    endDateSeries: initFundDate,
                })
            } else {
                periodsSeries.push(periodToInitFund)
            }


            responseHistoricSeries = await Promise.all(
                periodsSeries.map(async period => {
                    let info = await getHistoricSeriesFund(fund.anbima_cod,
                        period.startDateSeries,
                        period.endDateSeries);

                    if (info.success) {
                        return info.body;
                    } else {

                        console.log("ERRO AO TRAZER SERIES");
                        return false;

                    }

                })
            )

            //console.log("SERIE HISTORICA: ", responseHistoricSeries);

            //Obtendo nota explicativa do fundo
            //let responseExplicationNote = await getExplicationNote(fund.anbima_cod);
            //console.log("Explication note: ", responseExplicationNote);
        }

        //return formatFundInfos(infos, responseHistoricSeries.body.serie_historica);
        return formatFundInfos(infos, responseHistoricSeries);

    } else {

        if (response.body == "RENOVAR_TOKEN") {
            return "RENOVAR_TOKEN";
        } else {
            return {
                error: 'Erro ao obter informaçoes do fundo: ' + fund.anbima_cod,
                cod: response.body
            }
        }
    }

}


function formatFundInfos(anbimaInfos, series) {

    let infosToReturn = {};

    console.log("Anbima Infos: ", anbimaInfos);

    //Obtendo informações gerais de cadastro
    infosToReturn.dados_cadastrais = {

        anbima_cnpj: anbimaInfos['dados-cadastrais'].cnpj_fundo,
        pl_anbima: anbimaInfos['serie_historica'].patrimonio_liquido,

        benchmark: anbimaInfos['dados-cadastrais'].benchmark,
        fund_init: formatPtStringDate(anbimaInfos['dados-cadastrais'].data_primeiro_aporte),
        administrator_name: anbimaInfos['prestadores']['administrador'].nome,
        administrator_cnpj: anbimaInfos['prestadores']['administrador'].cnpj,
        administration_tax: anbimaInfos['taxas']['taxa_administracao'],
        max_administration_tax: anbimaInfos['taxas']['taxa_maxima'],

        performance_tax: anbimaInfos['taxas']['cobra_taxa_performance'],

        min_application: anbimaInfos['movimentacao']['aplicacao_inicial_minima'],
        min_rescue: anbimaInfos['movimentacao']['resgate_minimo'],
        aditional_application: anbimaInfos['movimentacao']['aplicacao_adicional_minima'],
        conversion_application_quota: 'D + ' + anbimaInfos['movimentacao']['prazo_emissao_cotas'],
        conversion_rescue_quota: 'D + ' + anbimaInfos['movimentacao']['prazo_conversao_resgate'],
        min_balance: anbimaInfos['movimentacao']['saldo_minimo_aplicado_ao_fundo'],
        disponibilization: 'D + ' + anbimaInfos['movimentacao']['prazo_pagamento_resgate'],

    }

    //infosToReturn.serie_historica = series;
    if (series) {
        infosToReturn.serie_historica = series[0].serie_historica;
        if (series[1]) {
            infosToReturn.init_serie_historica = series[1].serie_historica;
        }
    }


    //Obtendo gestor
    if (anbimaInfos['prestadores'].gestores && anbimaInfos['prestadores'].gestores.length > 0) {

        for (let i = 0; i < anbimaInfos['prestadores'].gestores.length; i++) {
            const element = anbimaInfos['prestadores'].gestores[i];
            if (element.principal) {

                infosToReturn.dados_cadastrais.manager_name = element.nome
                infosToReturn.dados_cadastrais.manager_cnpj = element.cnpj
            }

        }
    }


    //Obtendo Link do regulamento
    if (anbimaInfos['documentos'] && anbimaInfos['documentos'].length > 0) {

        for (let i = 0; i < anbimaInfos['documentos'].length; i++) {
            const element = anbimaInfos['documentos'][i];
            //console.log("Entrou tipo regulamento: ", element);
            if (element.tipo_documento == "Regulamento") {

                infosToReturn.dados_cadastrais.link_regulamento = element.url;
            }

        }
    }

    //Para tela de análises gráfico de benchmark
    infosToReturn.benchmark_anbima = anbimaInfos['dados-cadastrais'].benchmark;
    infosToReturn.administrator_anbima = anbimaInfos['prestadores']['administrador'].nome;
    infosToReturn.manager_anbima = infosToReturn.dados_cadastrais.manager_name;

    //EXEMPLO DE COMO ESTÁ SENDO RETORNADO O VALOR DE DADOS CADASTRAIS
    // {
    //     administrator_anbima: "CAIXA"
    //     benchmark_anbima: "Não se Aplica"
    //     {
    //         dados_cadastrais:
    //         aditional_application: 0.01
    //         administration_tax: 1.7
    //         administrator_cnpj: "00360305000104"
    //         administrator_name: "CAIXA"
    //         anbima_cnpj: "00834074000123"
    //         benchmark: "Não se Aplica"
    //         conversion_application_quota: "D + 0"
    //         conversion_rescue_quota: "D + 0"
    //         disponibilization: "D + 0"
    //         fund_init: "02/10/1995"
    //         link_regulamento: "https://anbima-bucket-documents-prod.s3.amazonaws.com/FUNDOS/136670/136670_REGULAMENTO_19700101_000.pdf"
    //         manager_cnpj: "00360305000104"
    //         manager_name: "CAIXA"
    //         max_administration_tax: 1.7
    //         min_application: 0.01
    //         min_balance: 0.01
    //         min_rescue: 0.01
    //         pl_anbima: 26169891902.43    
    //     }
    //     manager_anbima: "CAIXA"
    // }

    return infosToReturn;
}

export async function normalizeFundInfos(fund, monthsAgo, untilTheLastTotalMonth) {

    let responseFundInfos = await loadFundInfos(fund, monthsAgo, untilTheLastTotalMonth);

    if (responseFundInfos == "RENOVAR_TOKEN") {

        const tryNewToken = await getToken();

        if (tryNewToken.success) {

            localStorage.setItem("anbimaToken", tryNewToken.body.access_token);
            responseFundInfos = await loadFundInfos(fund, monthsAgo, untilTheLastTotalMonth);

        } else {

            console.log("Falha ao obter token");

        }
    }

    return responseFundInfos;


};


async function processFundInfos(cods) {

    let renewToken = false;
    let anbimaInfos = await Promise.all(
        cods.map(async anbimaCod => {
            let info = await getDetailFund(anbimaCod);

            if (info.success) {
                return {
                    anbimaCod: anbimaCod,
                    infos: formatFundInfos(info.body),
                };
            } else {

                if (info.body == "RENOVAR_TOKEN") {
                    renewToken = true;
                }
                return false;

            }

        })
    )

    if (renewToken) {

        return "RENOVAR_TOKEN";

    } else {

        //Normalizando infos em um objeto
        let objectAnbimaInfos = {};
        if (Array.isArray(anbimaInfos)) {
            anbimaInfos.forEach(element => {
                objectAnbimaInfos[element.anbimaCod] = element.infos
            });
        }

        return objectAnbimaInfos;
    }


}


async function processListFundInfos(cods) {

    let funds404 = []; //fundos não encontrados na base
    let funds429 = []; //fundos que podem ter não sido carregados devido as muitas requisições

    let renewToken = false;
    let anbimaInfos = await Promise.all(
        cods.map(async obj => {

            let info = await getDetailFund(obj.anbimaCod);

            if (info.success) {
                return {
                    anbimaCod: obj.anbimaCod,
                    fundLocalQuotaId: obj.fundLocalQuotaId,
                    fund_id: obj.fund_id,
                    infos: formatFundInfos(info.body),
                };
            } else {

                if (info.body == "RENOVAR_TOKEN") {
                    renewToken = true;
                }

                if (info.body == 429) {
                    funds429.push({
                        anbimaCod: obj.anbimaCod,
                        fundLocalQuotaId: obj.fundLocalQuotaId,
                        fund_id: obj.fund_id,
                    });
                }

                if (info.body == 404) {
                    funds404.push({
                        anbimaCod: obj.anbimaCod,
                        fundLocalQuotaId: obj.fundLocalQuotaId,
                        fund_id: obj.fund_id,
                    });
                }
                return false;

            }

        })
    )

    if (renewToken) {

        return "RENOVAR_TOKEN";

    } else {

        return {
            anbimaInfos: anbimaInfos.filter(el => el.anbimaCod && el.infos), //para evitar trazer os 429
            funds429: funds429,
            funds404: funds404,
        };

    }


}


//Recebe a lista de códigos diferentes e a lista de ativos, atribui as informações no final aos ativos a partir do código anbima
export async function normalizeByListAssets(cods, assets) {

    let responseAnbimaInfos = await processListFundInfos(cods);

    if (responseAnbimaInfos == "RENOVAR_TOKEN") {

        const tryNewToken = await getToken();

        if (tryNewToken.success) {

            localStorage.setItem("anbimaToken", tryNewToken.body.access_token);
            responseAnbimaInfos = await processListFundInfos(cods);

        } else {

            console.log("Falha ao obter token");

        }
    }

    let anbimaInfos = responseAnbimaInfos.anbimaInfos;

    console.log("ANBIMA INFOS: ", anbimaInfos);

    let funds404 = responseAnbimaInfos.funds404;

    if (responseAnbimaInfos.funds429.length > 0) {

        let funds429 = responseAnbimaInfos.funds429;

        while (funds429.length > 0) {

            console.log("Requisitando 429: ", funds429);
            let responseFunds429 = await processListFundInfos(funds429);

            console.log("Novos incluídos: ", responseFunds429.historicSeries);
            responseFunds429.anbimaInfos.forEach(element => {
                anbimaInfos.push(element);
            });

            responseFunds429.funds404.forEach(element => {
                funds404.push(element);
            });

            funds429 = responseFunds429.funds429;
        }

    }

    //Normalizando infos em um objeto
    let objectAnbimaInfos = {};
    let arrayInfosToSave = [];
    if (Array.isArray(anbimaInfos)) {
        anbimaInfos.forEach(element => {
            objectAnbimaInfos[element.anbimaCod] = element.infos
            arrayInfosToSave.push({
                fundLocalQuotaId: element.fundLocalQuotaId,
                fund_id: element.fund_id,
                infos: element.infos,
            })
        });
    }

    console.log("INFOS A SEREM SALVAS LOCALMENTE: ", arrayInfosToSave);

    //normalizando as informações anbima
    let newAssets = [];
    assets.forEach(element => {
        element = {
            ...element,
            ...objectAnbimaInfos[element.anbima_cod],
        };
        newAssets.push(element);
    });

    return {
        assets: newAssets,
        infosToSaveLocal: arrayInfosToSave,
    };

}


//O parâmetro cods segue o formato
// {
//     anbima_cod: "",
//     fund_id: "",
// }
async function loadSeries(cods, startDate, endDate) {

    let renewToken = false;

    let funds404 = []; //fundos não encontrados na base
    let funds429 = []; //fundos que podem ter não sido carregados devido as muitas requisições

    console.log("LOAD SERIES DE: ", cods);

    //let countTimerRequest = 1;
    let historicSeries = await Promise.all(
        cods.map(async obj => {

            if (obj.anbima_cod) {

                let serie = await getHistoricSeriesFund(obj.anbima_cod,
                    formatPtStringDateToAnbimaDate(startDate),
                    formatPtStringDateToAnbimaDate(endDate));

                console.log("RESPONSE SERIE: ", serie);
                if (serie.success) {
                    return {
                        anbima_cod: obj.anbima_cod,
                        fund_id: obj.fund_id,
                        serie_historica: serie.body.serie_historica,
                    };
                } else {

                    if (serie.body == "RENOVAR_TOKEN") {
                        renewToken = true;
                    }

                    if (serie.body == 429) {
                        funds429.push(obj);
                    }

                    if (serie.body == 404) {
                        funds404.push(obj);
                    }

                    return serie.body;

                }

            } else {
                //No caso de não haver código anbima de um fundo
                funds404.push(obj);
                return 404;

            }



        })
    )

    if (renewToken) {

        return "RENOVAR_TOKEN";

    } else {

        return {
            //historicSeries: historicSeries.filter(el => el.anbimaCod), //para evitar trazer os 429
            historicSeries: historicSeries.filter(el => el.fund_id), //para evitar trazer os 429
            funds429: funds429,
            funds404: funds404,
        };
    }


}

//O parâmetro mainKey corresponde a qual deve ser a chave utilizada no mapa de cotas
//antes anbimaCod, agora fundId
export async function getQuotasByListFunds(cods, startDate, endDate, mainKey) {

    /* cods:{
        anbima_cod: XXXX,
        fund_id: YYYY,
    }*/

    let filteredQuotas = {};

    let responseHistoricSeries = await loadSeries(cods, startDate, endDate);

    if (responseHistoricSeries == "RENOVAR_TOKEN") {

        const tryNewToken = await getToken();

        if (tryNewToken.success) {

            localStorage.setItem("anbimaToken", tryNewToken.body.access_token);
            responseHistoricSeries = await loadSeries(cods, startDate, endDate);

        } else {

            console.log("Falha ao obter token");

        }
    }

    let historicSeries = responseHistoricSeries.historicSeries;
    let funds404 = responseHistoricSeries.funds404;

    if (responseHistoricSeries.funds429.length > 0) {

        let funds429 = responseHistoricSeries.funds429;

        while (funds429.length > 0) {

            console.log("Requisitando 429: ", funds429);
            let responseFunds429 = await loadSeries(funds429, startDate, endDate);
            console.log("Novos incluídos: ", responseFunds429.historicSeries);
            responseFunds429.historicSeries.forEach(element => {
                historicSeries.push(element);
            });

            responseFunds429.funds404.forEach(element => {
                funds404.push(element);
            });


            funds429 = responseFunds429.funds429;
        }

    }

    console.log("HISTORIC SERIES: ", historicSeries);
    console.log("FUNDS 404: ", funds404);

    //normalizando serie historica
    if (Array.isArray(historicSeries)) {

        historicSeries.forEach(element => {

            //console.log("HISTORIC SERIE JSON: ", JSON.stringify(element.serie_historica));

            if (element && element.serie_historica) {

                element.serie_historica.reverse();
                //filteredQuotas[element.anbimaCod] = element.serie_historica;
                filteredQuotas[element[mainKey]] = element.serie_historica;

            }

        });
    }

    if (Array.isArray(funds404)) {

        funds404.forEach(element => {

            //filteredQuotas[element.anbima_cod] = "404";
            filteredQuotas[element[mainKey]] = "404";

        });
    }

    return {
        filteredQuotas: filteredQuotas,
        quotasToSaveLocal: historicSeries,
    };



}

export async function getIndices() {


    let responseIndexes = await getIndexes();

    if (responseIndexes == "RENOVAR_TOKEN") {

        const tryNewToken = await getToken();

        if (tryNewToken.success) {

            localStorage.setItem("anbimaToken", tryNewToken.body.access_token);
            let responseIndexes = await getIndexes();

        } else {

            console.log("Falha ao obter token");

        }

    }

    return responseIndexes.body;

}

export function calculateFundRentsInPortfolio(fundLoop) {

    let averageQuota = null;

    //Inicializando quantidade de cota anterior
    fundLoop.quota_amount_before = parseFloat(fundLoop.quota_amount_before)
    fundLoop.qtd_quota = fundLoop.quota_amount_before;

    // 1 - CALCULO RENTABILIDADE DO FUNDO
    // Inicializando a cota média com a avera_quota do mês anterior, que vem do balances_validations.average_quota
    let arrayToAverageQuotas = [];
    //let arrayToAverageQuotasInMonth = [];
    //Caso não haja cota média anterior
    if (!fundLoop.average_quota_before) {

        //Caso a cota média anterior seja diferente de 0 a cota média é o pl do ativo dividido pela quantidade de quotas do ativo 
        // - o pl do ativo é o saldo anterior do ativo
        if (fundLoop.qtd_quota > 0) {

            //Verificar caso em que o PL anterior é 0 pois a cota média valeria 0

            if (fundLoop.quotas_anbima_before && fundLoop.quotas_anbima_before.length >= 1) {

                averageQuota = fundLoop.quotas_anbima_before[fundLoop.quotas_anbima_before.length - 1].valor_cota

            } else {

                averageQuota = fundLoop.init_pl / fundLoop.qtd_quota;

            }


        } else {

            //NEW IMPLEMENT
            //A cota média será inicializada pela primeira aplicação
            averageQuota = 0;

        }

    } else {

        //Caso haja cota média anterior apenas se atribui o valor
        //average_quota_before pode ser um array com mais de uma cota média, atribui-se a última
        if (fundLoop.average_quota_before.length >= 1) {

            averageQuota = fundLoop.average_quota_before[fundLoop.average_quota_before.length - 1].averageQuota;

        }


    }
    // FIM 1 - CALCULO RENTABILIDADE DO FUNDO

    // 2 - CALCULO RENTABILIDADE DO FUNDO
    //Array que vai guardar as quantidades de quotas que serão utilizadas no cálculo da cota média    
    if (averageQuota !== 0 && averageQuota !== null) {

        arrayToAverageQuotas.push({
            //qtdQuotas: parseFloat(fundLoop.qtd_quota), //Quantidade de cotas inicial (mês anterior)
            balanceQuotas: parseFloat(fundLoop.qtd_quota), //Quantidade de cotas inicial (mês anterior)
            averageQuota: parseFloat(averageQuota), //Cota média inicial        
            averageQuotaInMonth: fundLoop.quotas_anbima_before && fundLoop.quotas_anbima_before.length >= 1
                ? fundLoop.quotas_anbima_before[fundLoop.quotas_anbima_before.length - 1].valor_cota : null, //Cota média inicial  
        })

    }
    // FIM 2 - CALCULO RENTABILIDADE DO FUNDO

    //Utilizado para calcular a cota média do fundo no mês: average_quota_in_month
    //console.log('fundLoop: ', fundLoop);
    // arrayToAverageQuotasInMonth.push({
    //     balanceQuotas: parseFloat(fundLoop.qtd_quota), //Quantidade de cotas inicial (mês anterior)
    //     averageQuotaInMonth: fundLoop.quotas_anbima_before && fundLoop.quotas_anbima_before.length >= 1
    //         ? fundLoop.quotas_anbima_before[fundLoop.quotas_anbima_before.length - 1].valor_cota : null, //Cota média inicial  
    // })

    //Para cálculo de rentabilidade em dinheiro
    fundLoop.totalApplicationsAmount = 0;
    fundLoop.totalRescuesAmount = 0;
    fundLoop.totalAmortizationAmount = 0;
    fundLoop.qtdQuotasOnAmortization = null;

    if (fundLoop.quotas_anbima) {
        for (let i = 0; i < fundLoop.quotas_anbima.length; i++) {

            const quotaLoop = fundLoop.quotas_anbima[i];

            const valueQuota = quotaLoop['valor_cota'];
            const dateQuota = quotaLoop['data_referencia'];

            //Verificando se há transações para esse subAsset
            //Filtrando as transações para o dia dessa cota;      
            //Calculando a resultante do dia APLICAÇÕES - RESGATES                        
            let resultTransactionAmount = 0;

            //Caso não tenha havido aplicação nem resgate apenas repete-se a quantidade de cotas que já se obtinha  
            //console.log("QTD QUOTAS ANTES [" + dateQuota + "]: ", parseFloat(fundLoop.qtd_quota));
            //console.log("valueQuota: ", valueQuota);
            fundLoop.pl_pre_app_rescues = parseFloat(fundLoop.qtd_quota) * valueQuota;

            //Calculando quotas a serem somadas a partir do resultado das movimentações
            if (fundLoop.transactions) {

                //let listTransactionsInDay = fundLoop.transactions.filter(el => moment.utc(el.transaction_date).format('YYYY-MM-DD') == dateQuota);
                let listTransactionsInDay = fundLoop.transactions.filter(el => moment.utc(el.transaction_date).format('YYYY-MM-DD') == dateQuota
                    //&& el.operation_id !== 3
                ); //Amortização não é considerada para o saldo e nem para a rentabilidade 

                //Utilizado para fazer a rentabilidade do fundo na carteira
                let applicationsAmount = 0.0;
                let rescuesAmount = 0.0;

                if (listTransactionsInDay) {

                    let indexesApplicationsDay = [];
                    let indexFirstRescueDay = null;
                    listTransactionsInDay.forEach((transactionLoop, index) => {

                        if (transactionLoop.operation_id == 1) { //Aplicação

                            indexesApplicationsDay.push(index);
                            resultTransactionAmount += parseFloat(transactionLoop.amount);

                            //Obtendo quantidade de quotas da movimentação
                            transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                            fundLoop.sumQuotasApplication += transactionLoop.result_qtd_quotas;

                            applicationsAmount += parseFloat(transactionLoop.amount);

                        } else if (transactionLoop.operation_id == 2) {//Resgate

                            if (!indexFirstRescueDay) {
                                indexFirstRescueDay = index;
                            }
                            resultTransactionAmount -= parseFloat(transactionLoop.amount);

                            //Obtendo quantidade de quotas da movimentação
                            transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                            fundLoop.sumQuotasRescues += transactionLoop.result_qtd_quotas;

                            rescuesAmount += parseFloat(transactionLoop.amount);

                        } else if (transactionLoop.operation_id == 3) {//Amortização

                            //Amortização não é considerada para o saldo e nem para a rentabilidade
                            fundLoop.totalAmortizationAmount += parseFloat(transactionLoop.amount);
                            fundLoop.qtdQuotasOnAmortization = fundLoop.qtd_quota;
                        }
                    });

                    // console.log("################");
                    // console.log("DAY ", dateQuota);
                    // console.log("################");
                    // console.log('listTransactionsInDay: ', listTransactionsInDay);
                    let allApplicationsFirst = true;
                    indexesApplicationsDay.forEach(indexApplication => {

                        console.log("COMPARANDO NANA: ", indexApplication + ' > ' + indexFirstRescueDay);
                        if (indexApplication > indexFirstRescueDay) {
                            allApplicationsFirst = false;
                        }

                    });

                    //console.log('allApplicationsFirst: ', allApplicationsFirst);

                    //Quando há aplicação ou resgate converte-se o valor a cotas e soma-se as cotas anteriores 
                    let qtdQuotasPreTransactionsDay = fundLoop.qtd_quota;
                    fundLoop.qtd_quota = (resultTransactionAmount / valueQuota) + parseFloat(fundLoop.qtd_quota);

                    fundLoop.totalApplicationsAmount += applicationsAmount;
                    fundLoop.totalRescuesAmount += rescuesAmount;

                    //Houve transação nesse dia
                    if (listTransactionsInDay.length > 0) {

                        //verificar se antes do possível resgate que zera o fundo no dia houve aplicação anterior                        
                        let applicationsFirstAndZeroFund = false; //Obj no caso do fundo ser completamente zerado

                        let qtdQuotaApplications = (applicationsAmount / valueQuota);
                        let qtdQuotaRescues = (rescuesAmount / valueQuota);

                        if (qtdQuotaApplications > qtdQuotaRescues) {
                            qtdQuotaApplications = qtdQuotaApplications - qtdQuotaRescues;
                            qtdQuotaRescues = 0;
                        } else if (qtdQuotaRescues > qtdQuotaApplications) {
                            qtdQuotaRescues = qtdQuotaRescues - qtdQuotaApplications;
                            qtdQuotaApplications = 0;
                        } else {
                            qtdQuotaApplications = 0;
                            qtdQuotaRescues = 0;
                        }

                        // 3 - CALCULO RENTABILIDADE DO FUNDO
                        // Faz-se o cálculo da cota média averageQuota 
                        let lastRentFund = arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1] : null;
                        //Fundo zerou
                        if (qtdQuotasPreTransactionsDay !== 0 && (qtdQuotaRescues > 0 && (qtdQuotasPreTransactionsDay - qtdQuotaRescues + qtdQuotaApplications) <= 0)) {

                            if (!allApplicationsFirst) {
                                let objRentFundRescues = {
                                    //balanceQuotas: fundLoop.qtd_quota,
                                    dateQuota: dateQuota,
                                    balanceQuotas: 0, //Deve ser a soma da cota anterior mais as cotas de aplicação adicionadas
                                    qtdQuotaApplications: qtdQuotaApplications,
                                    qtdQuotaRescues: qtdQuotaRescues,
                                    valueQuota: valueQuota,
                                    averageQuota: arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1].averageQuota : valueQuota,
                                    averageQuotaInMonth: arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1].averageQuotaInMonth : valueQuota,
                                    operation: 2,
                                };
                                arrayToAverageQuotas.push(objRentFundRescues);
                            } else {
                                applicationsFirstAndZeroFund = true;
                            }
                        }

                        if (qtdQuotaApplications > 0) {

                            //HISTORIC
                            let objRentFundApplications = {
                                //balanceQuotas: fundLoop.qtd_quota,
                                dateQuota: dateQuota,
                                balanceQuotas: qtdQuotasPreTransactionsDay + qtdQuotaApplications, //Deve ser a soma da cota anterior mais as cotas de aplicação adicionadas
                                qtdQuotaApplications: qtdQuotaApplications,
                                qtdQuotaRescues: qtdQuotaRescues,
                                valueQuota: valueQuota,
                                averageQuota: lastRentFund ? lastRentFund.averageQuota : valueQuota,
                                averageQuotaInMonth: lastRentFund ? lastRentFund.averageQuotaInMonth : valueQuota,
                                operation: 1,
                            };

                            //Caso o lastRentFund seja diferente de nulo, realiza-se normalmente a operação de obtenção da cota média histórica
                            if (lastRentFund !== null) {

                                if (objRentFundApplications.balanceQuotas !== 0) {

                                    objRentFundApplications.averageQuota = ((lastRentFund.balanceQuotas * lastRentFund.averageQuota)
                                        + (objRentFundApplications.qtdQuotaApplications * objRentFundApplications.valueQuota)) / objRentFundApplications.balanceQuotas;

                                    objRentFundApplications.averageQuotaInMonth = ((lastRentFund.balanceQuotas * lastRentFund.averageQuotaInMonth)
                                        + (objRentFundApplications.qtdQuotaApplications * objRentFundApplications.valueQuota)) / objRentFundApplications.balanceQuotas;

                                }

                                // console.log('lastRentFund.balanceQuotas: ', lastRentFund.balanceQuotas);
                                // console.log('lastRentFund.averageQuota: ', lastRentFund.averageQuota);
                                // console.log('objRentFund.qtdQuotaApplications: ', objRentFund.qtdQuotaApplications);
                                // console.log('objRentFund.valueQuota: ', objRentFund.valueQuota);
                                // console.log('objRentFund.averageQuota: ', objRentFund.averageQuota);

                            } else {

                                //Cota média deverá ser relativa a primeira aplicação                            
                                objRentFundApplications.averageQuota = objRentFundApplications.valueQuota;
                                objRentFundApplications.averageQuotaInMonth = objRentFundApplications.valueQuota;

                            }

                            arrayToAverageQuotas.push(objRentFundApplications)

                            //FIM HISTORIC

                        }

                        //obejto a ser adiconado depois devido haver aplicação antes do resgate que zerou o fundo
                        if (applicationsFirstAndZeroFund) {
                            let objRentFundRescues = {
                                //balanceQuotas: fundLoop.qtd_quota,
                                dateQuota: dateQuota,
                                balanceQuotas: 0, //Deve ser a soma da cota anterior mais as cotas de aplicação adicionadas
                                qtdQuotaApplications: qtdQuotaApplications,
                                qtdQuotaRescues: qtdQuotaRescues,
                                valueQuota: valueQuota,
                                averageQuota: arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1].averageQuota : valueQuota,
                                averageQuotaInMonth: arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1].averageQuotaInMonth : valueQuota,
                                operation: 2,
                            };
                            arrayToAverageQuotas.push(objRentFundRescues);
                        }

                        if (qtdQuotaRescues > 0 && (qtdQuotasPreTransactionsDay - qtdQuotaRescues) > 0) {

                            let objRentFundRescues = {
                                //balanceQuotas: fundLoop.qtd_quota,
                                dateQuota: dateQuota,
                                balanceQuotas: qtdQuotasPreTransactionsDay + qtdQuotaApplications - qtdQuotaRescues, //Deve ser a soma da cota anterior mais as cotas de aplicação adicionadas
                                qtdQuotaApplications: qtdQuotaApplications,
                                qtdQuotaRescues: qtdQuotaRescues,
                                valueQuota: valueQuota,
                                averageQuota: arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1].averageQuota : valueQuota,
                                averageQuotaInMonth: arrayToAverageQuotas.length >= 1 ? arrayToAverageQuotas[arrayToAverageQuotas.length - 1].averageQuotaInMonth : valueQuota,
                                operation: 2,
                            };

                            arrayToAverageQuotas.push(objRentFundRescues);

                        }

                        //NEW IMPLEMENT
                        // FIM 3 - CALCULO RENTABILIDADE DO FUNDO

                        //console.log("arrayToAverageQuotas: ", arrayToAverageQuotas);

                    }
                }
            }
        }
    }

    // 4 - CALCULO RETABILIDADE FUNDO
    //console.log("4 - CALCULO RETABILIDADE FUNDO / arrayToAverageQuotas: ", arrayToAverageQuotas)
    //console.log("4 - CALCULO RETABILIDADE FUNDO / arrayToAverageQuotasInMonth: ", arrayToAverageQuotasInMonth)

    if (fundLoop.special && arrayToAverageQuotas && arrayToAverageQuotas.length == 0) { //Não houve aplicação
        arrayToAverageQuotas.push({
            balanceQuotas: parseFloat(fundLoop.qtd_quota), //Quantidade de cotas inicial (mês anterior)
            averageQuota: 0, //Cota média inicial        
            averageQuotaInMonth: fundLoop.quotas_anbima_before && fundLoop.quotas_anbima_before.length >= 1
                ? fundLoop.quotas_anbima_before[fundLoop.quotas_anbima_before.length - 1].valor_cota : null, //Cota média inicial  
        })
    }

    // if (fundLoop.cnpj === '14.508.643/0001-55') {
    //     console.log('fundLoopfundLoop: ', fundLoop);
    //     console.log('arrayToAverageQuotas: ', arrayToAverageQuotas);
    // }

    if (arrayToAverageQuotas.length && fundLoop.quotas_anbima && fundLoop.quotas_anbima.length > 0) {

        fundLoop.arrayToAverageQuotas = arrayToAverageQuotas;

        fundLoop.average_quota_now = formatArrayToAverageQuotas(fundLoop);

        //fundLoop.arrayToAverageQuotasInMonth = arrayToAverageQuotasInMonth;
        //Obtendo valor total de rendimento em dinheiro e colocando em atributo do fundo

        fundLoop.rentMoneyInMonth = getMonthFundRentInMoney(fundLoop);
        fundLoop.rentPercentInMonth = getInMonthRentFundInPortfolio(fundLoop);

    } else if (fundLoop.special) {

        fundLoop.rentMoneyInMonth = getMonthFundRentInMoney(fundLoop);
        fundLoop.rentPercentInMonth = getInMonthRentFundInPortfolio(fundLoop);

    }
    // FIM 4 - CALCULO RETABILIDADE FUNDO
}



//Remove as cotas que não precisam ser salvas no banco
export function formatArrayToAverageQuotas(fund) {

    const averageQuotas = [];
    if (fund.arrayToAverageQuotas && fund.arrayToAverageQuotas.length >= 1) {

        const lastAverageQuota = fund.arrayToAverageQuotas[fund.arrayToAverageQuotas.length - 1];

        fund.arrayToAverageQuotas.forEach((element, index) => {
            if (element.balanceQuotas === 0 && index !== fund.arrayToAverageQuotas.length - 1) { //VErifica-se se não é o único averageQuota ou o último visto que já se acrescenta o último obrigatorimaente
                averageQuotas.push({
                    balanceQuotas: element.balanceQuotas,
                    averageQuota: element.averageQuota,
                    averageQuotaInMonth: element.averageQuotaInMonth,
                    dayAverage: element.dateQuota,
                    quotaInDayAverage: element.valueQuota,
                });
            }
        });

        const last = {
            balanceQuotas: lastAverageQuota.balanceQuotas,
            averageQuota: lastAverageQuota.averageQuota,
            averageQuotaInMonth: lastAverageQuota.averageQuotaInMonth,
            dayAverage: lastAverageQuota.dateQuota,
            quotaInDayAverage: lastAverageQuota.valueQuota,
        };

        if (fund.qtdQuotasOnAmortization)
            last.qtdQuotasOnAmortization = fund.qtdQuotasOnAmortization;

        averageQuotas.push(last);
    }

    return averageQuotas;

}


export function validatorFundsClientRents(clientId, month, year, funds) {

    const maxLengthJson = 1100;

    funds.filter(el => !el.title_id).forEach(fund => { //filter para garantir que não sejam considerados os títulos

        const averageQuotas = formatArrayToAverageQuotas(fund);

        const stringifyJsonAverageQuotas = JSON.stringify(averageQuotas);
        const isInvalidLengthJsonAverageQuotas = (stringifyJsonAverageQuotas.length > maxLengthJson);
        const validatedRentFund = {
            fund_id: fund.fund_id,
            client_id: clientId,
            json_average_quotas: stringifyJsonAverageQuotas,
            month: month,
            year: year,
            date_rent: getFirstDayMonth(month, year),
        }


        if (validatedRentFund.fund_id == undefined
            || validatedRentFund.client_id == undefined
            || (validatedRentFund.json_average_quotas == undefined || validatedRentFund.json_average_quotas === '' || isInvalidLengthJsonAverageQuotas)
            || (validatedRentFund.date_rent == undefined || validatedRentFund.date_rent === 'Data inválida')
            || validatedRentFund.month == undefined
            || validatedRentFund.year == undefined

        ) {

            fund.validatedRentFund = false;
            fund.validatedRentFundError = validatedRentFund;

        } else {

            fund.validatedRentFund = validatedRentFund;

        }

        //teste
        //fund.validatedRentFund = false;

    });

}

export async function doSaveFundClientRent(fundClientRent) {

    const responseCreate = await saveFundClientRent(fundClientRent);

    if (responseCreate.success) {
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }

}

function normalizeJsonsFund(funds) {
    funds.forEach(fund => {
        // fund.arrayToAverageQuotas = JSON.parse(fund.average_quota);
        if (fund.average_quota_now) {
            fund.average_quota_now = JSON.parse(fund.average_quota_now);
        }
        //NEW VALUE QUOTA
        fund.quotas_anbima = fund.quotas_anbima_now ? getClientJsonQuotas(JSON.parse(fund.quotas_anbima_now), fund.client_id) : [];
        //fund.quotas_anbima = fund.quotas_anbima_now ? JSON.parse(fund.quotas_anbima_now) : [];
        fund.quotas_anbima_before = fund.quotas_anbima_before ? getClientJsonQuotas(JSON.parse(fund.quotas_anbima_before), fund.client_id) : [];
        //fund.quotas_anbima_before = fund.quotas_anbima_before ? JSON.parse(fund.quotas_anbima_before) : [];
    });
}

// export async function doGetFundsClientByPeriodRent(clientId, startDate, endDate, search) {

//     const response = await getFundsClientByPeriodRent(clientId, startDate, endDate, search)
//     if (response.success) {

//         console.log('getFundsClientByPeriodRent: ', response.body.rows);

//         normalizeJsonsFund(response.body.rows);
//         return response;
//     } else {
//         return response;
//     }
// }

export async function doGetFundsClientByPeriodRent(client,
    startDate,
    endDate,
    segmentId,
    search, period) {

    let promises = [];

    promises.push(new Promise(async (resolve, reject) => {

        const response = await getFundsClientByPeriodRent(client.id, startDate, endDate, segmentId, search)

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    promises.push(new Promise(async (resolve, reject) => {

        const responseTransactions = await getClientAllTransactionByDateRange(client.id, startDate, endDate, null);

        if (responseTransactions.success) {

            resolve(responseTransactions.body);

        } else {

            resolve(false);

        }
    }))

    const reponseAll = await Promise.all(promises);

    console.log("RESPONSE ALL: ", reponseAll);


    if (reponseAll[0] && reponseAll[1]) {

        const funds = reponseAll[0].rows;
        const transactions = reponseAll[1].rows;

        console.log('FULL GET ===');
        console.log('ASSETS: ', _.cloneDeep(funds));
        console.log('TRANSACTIONS: ', transactions);

        const { month, year } = getDayMonthYearByStringDate(endDate);
        const { monthBefore, yearBefore } = getMonthAndYearBefore(startDate);
        const newStartDate = period == 12 ? startDate : getFirstPtDayMonth(monthBefore, yearBefore);
        return {
            success: true,
            data: groupAllAssetsAndTransactions(client.id, funds, transactions, stringPtDateToJsDate(newStartDate), endDate.substring(3), month, year)
        }
    } else {
        return { success: false };
    }
}

function groupAllAssetsAndTransactions(clientId, funds,
    transactions,
    portfolioInit,
    portfolioClosed,
    consultingMonth,
    consultingYear) {

    console.log('Portfolio init: ', portfolioInit);
    console.log('Portfolio closed: ', portfolioClosed);
    const { months } = getMonthsUntilPortfolioInit(portfolioInit, portfolioClosed, true);
    console.log("allDates: ", months);

    const mapAssetsByPeriods = {};
    //Para cada mês informar os ativos
    Object.entries(months).map(([year, arrayMonths]) => {

        mapAssetsByPeriods[year] = {};

        arrayMonths.forEach(month => {

            if (!mapAssetsByPeriods[year][month]) {
                mapAssetsByPeriods[year][month] = {
                    assets: [],
                    transactions: [],
                };
            }

            const assetsInMonth = funds.filter(el => el.month_validation == month && el.year_validation == year);

            assetsInMonth.forEach(element => {
                element.quotas_anbima = element.quotas_anbima ? getClientJsonQuotas(JSON.parse(element.quotas_anbima), clientId) : [];
                element.quotas_anbima_before = element.quotas_anbima_before ? getClientJsonQuotas(JSON.parse(element.quotas_anbima_before), clientId) : [];
            });

            mapAssetsByPeriods[year][month]['assets'] = assetsInMonth;

            transactions.forEach(transaction => {

                const responseDayMonthYear = getDayMonthYearByStringDate(moment.utc(transaction.transaction_date).format('DD/MM/YYYY'));
                if (responseDayMonthYear.month == month && responseDayMonthYear.year == year) {
                    mapAssetsByPeriods[responseDayMonthYear.year][responseDayMonthYear.month]['transactions'].push(transaction);
                }
            });

        });

    });

    console.log('mapAssetsByPeriods CLONE: ', _.cloneDeep(mapAssetsByPeriods));

    //Adicionando informações do mês anterior ao mês atual
    Object.entries(mapAssetsByPeriods).map(([year, months]) => {

        Object.entries(months).map(([month, assetsAndTransactions]) => {

            assetsAndTransactions.assets.forEach(assetLoop => {
                let monthAgo = month - 1;
                let yearAgo = year;
                if (monthAgo == 0) {
                    monthAgo = 12;
                    yearAgo--;
                }
                //Obtendo informações before do fundo
                const beforeBalanceInfos = mapAssetsByPeriods[yearAgo][monthAgo] ? mapAssetsByPeriods[yearAgo][monthAgo]['assets'].filter(el =>
                    el.asset_id == assetLoop.asset_id) : null;

                //console.log('beforeInfos: ', beforeInfos);
                if (beforeBalanceInfos && beforeBalanceInfos.length == 1) {
                    assetLoop.quota_amount_before = beforeBalanceInfos[0].quota_amount_now;
                    assetLoop.balance_before = beforeBalanceInfos[0].balance_now;
                    //assetLoop.quotas_anbima_before = beforeInfos[0].quotas_anbima;
                }
            });

        });
    });

    //Agrupando ativos por fundo
    let initMonthPassed = false;
    Object.entries(mapAssetsByPeriods).map(([year, months]) => {

        Object.entries(months).map(([month, assetsAndTransactions]) => {
            //console.log('assetsAndTransactions: ', assetsAndTransactions)            
            if (initMonthPassed) {
                assetsAndTransactions.funds = organizeAssetsAndTransactions(assetsAndTransactions.assets, assetsAndTransactions.transactions, [], null, null, false);


            } else {
                initMonthPassed = true;
            }

        });
    });

    console.log('mapAssetsByPeriods pos organizeAssetsAndTransactions: ', _.cloneDeep(mapAssetsByPeriods));

    initMonthPassed = false;
    Object.entries(mapAssetsByPeriods).map(([year, months]) => {

        Object.entries(months).map(([month, assetsAndTransactions]) => {
            //console.log('assetsAndTransactions: ', assetsAndTransactions)            
            if (initMonthPassed) {

                assetsAndTransactions.funds.forEach(fundLoop => {
                    calculateFundRentsInPortfolio(fundLoop);

                    let monthNext = parseInt(month) + 1;
                    let yearNext = year;
                    if (monthNext == 13) {
                        monthNext = 1;
                        yearNext++;
                    }

                    // console.log('Next month: ', monthNext);
                    // console.log('Next year: ', yearNext);

                    const afterFund = mapAssetsByPeriods[yearNext] && mapAssetsByPeriods[yearNext][monthNext] ? mapAssetsByPeriods[yearNext][monthNext]['funds'].find(el =>
                        el.fund_id == fundLoop.fund_id) : null;

                    //console.log('afterFund: ', afterFund);
                    if (afterFund) {
                        afterFund.average_quota_before = fundLoop.average_quota_now;
                    }

                });

            } else {
                initMonthPassed = true;
            }

        });
    });

    console.log('mapAssetsByPeriods: ', mapAssetsByPeriods);

    const mapFundsWithRents = {};
    initMonthPassed = false;
    Object.entries(mapAssetsByPeriods).map(([year, months]) => {

        Object.entries(months).map(([month, assetsAndTransactions]) => {
            //console.log('assetsAndTransactions: ', assetsAndTransactions)            
            if (initMonthPassed) {

                assetsAndTransactions.funds.forEach(fundLoop => {

                    //FUNDO SUPERIOR
                    if (!mapFundsWithRents[fundLoop.fund_id]) {
                        mapFundsWithRents[fundLoop.fund_id] = {
                            fund_id: fundLoop.fund_id,
                            local_benchmark_id: fundLoop.local_benchmark_id,
                            fund_name: fundLoop.fund_name,
                            cnpj: fundLoop.cnpj,
                            device_abbreviation: fundLoop.device_abbreviation,
                            rentsFunds: [],
                        };
                    }

                    const consultingFund = mapAssetsByPeriods[consultingYear][consultingMonth]['funds'].find(el => el.fund_id == fundLoop.fund_id)
                    if (consultingFund) {
                        mapFundsWithRents[fundLoop.fund_id].average_quota_now = consultingFund.average_quota_now;
                        mapFundsWithRents[fundLoop.fund_id].quotas_anbima = consultingFund.quotas_anbima;
                    }

                    //FUNDO RENT_FUNDS
                    if (fundLoop.average_quota_now) {
                        mapFundsWithRents[fundLoop.fund_id].rentsFunds.push({
                            aReferenceDate: month + '/' + year,
                            month: month,
                            year: year,
                            quotas_anbima_now: fundLoop.quotas_anbima,
                            quotas_anbima_before: fundLoop.quotas_anbima_before,
                            json_average_quotas: fundLoop.average_quota_now,
                        });
                    }

                });

            } else {
                initMonthPassed = true;
            }

        });
    });

    const arrayFundsRents = [];
    Object.entries(mapFundsWithRents).map(([fundId, row]) => {

        //verificando se o fundo já iniciou
        // if (isAssetInitByMonthAndYear(row.asset_init, consultingMonth, consultingYear)) {
        //     arrayFundsRents.push(row);
        // }

        if (row.rentsFunds && row.rentsFunds.length > 0) {

            arrayFundsRents.push(row);
        }

    });

    console.log('fundWithRents: ', arrayFundsRents);
    return arrayFundsRents;

}


export async function doGetFullFundsClientByPeriodRent(client, month, year, segmentId, search) {

    let promises = [];

    promises.push(new Promise(async (resolve, reject) => {

        const response = await getFullFundsClientByPeriodRent(client.id, month, year, segmentId, search)

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    promises.push(new Promise(async (resolve, reject) => {

        const endDate = getLastDayInMonthByMonthAndYear(month, year);
        const responseTransactions = await getAllTransactionsUntilDateAndRegime(client.id, endDate, null);

        if (responseTransactions.success) {

            resolve(responseTransactions.body);

        } else {

            resolve(false);

        }
    }))

    const reponseAll = await Promise.all(promises);

    console.log("RESPONSE ALL: ", reponseAll);


    if (reponseAll[0] && reponseAll[1]) {

        const funds = reponseAll[0].rows;
        const transactions = reponseAll[1].rows;

        console.log('FULL GET ===');
        console.log('ASSETS: ', _.cloneDeep(funds));
        console.log('TRANSACTIONS: ', transactions);


        return {
            success: true,
            data: groupAllAssetsAndTransactions(client.id, funds, transactions, client.portfolio_init, client.portfolio_closed, month, year)
        }
    } else {
        return { success: false };
    }
}

export function groupFundsBySegmentAndArticle(funds) {

    if (!funds || (funds && funds.length == 0)) {
        return [];
    }

    const mapSegments = {};
    funds.forEach(element => {

        const keySegment = element.segment_id + '_' + element.classe;
        if (!mapSegments[keySegment]) {
            mapSegments[keySegment] = {};
        }

        const device = element.device_abbreviation ? element.device_abbreviation : element.device;
        if (!mapSegments[keySegment][device]) {
            mapSegments[keySegment][device] = [];
        }

        mapSegments[keySegment][device].push(element);
    });

    const grouppedFunds = [];
    Object.entries(mapSegments).map(([keySegment, rowSegment]) => {

        grouppedFunds.push({
            labelSegment: keySegment.substring(2)
        });

        Object.entries(rowSegment).map(([keyArticle, rowArticle]) => {

            grouppedFunds.push({
                labelArticle: keyArticle
            });

            rowArticle.forEach(fund => {

                grouppedFunds.push(fund);

            });

        });

    });

    console.log('MAP FINAL SEGMENTS: ', grouppedFunds);

    return grouppedFunds;

}

export async function getSimpleNormalizedFunds(clientId, month, year, accountRegimeId) {

    var firstDay = new Date(year, parseInt(month) - 1, 1);
    var lastDay = new Date(year, parseInt(month), 0);

    const response = await getClientAllAssetsByDateAndRegime(clientId,
        moment.utc(firstDay).format('DD/MM/YYYY'),
        moment.utc(lastDay).format('DD/MM/YYYY'),
        accountRegimeId);

    if (response.success) {

        const fundAssets = response.body.rows;

        const { startDate, endDate } = getStartDateAndEndDateByMonthYear(month, year);

        console.log('startDate, endDate: ', startDate, endDate);

        const responseTransactions = await getClientAllTransactionByDateRangeAndRegime(clientId, startDate, endDate, accountRegimeId);
        if (responseTransactions.success) {

            console.log('responseTransactions: ', responseTransactions);

            const transactions = responseTransactions.body.rows;

            const realAssets = organizeAssetsAndTransactions(fundAssets, transactions);

            //Removendo ativos que foram resgatados totalmente
            const responseWholeRescueAssets = removeWholeRescuesAssets(realAssets);
            const finalSimpleAssets = responseWholeRescueAssets.newAssets;

            let responseSimpleSum = simpleSumBalancesParticipation(finalSimpleAssets);

            finalSimpleAssets.forEach(element => {
                element.quotas_anbima = element.quotas_anbima ? JSON.parse(element.quotas_anbima) : [];
                element.quotas_anbima_before = element.quotas_anbima_before ? JSON.parse(element.quotas_anbima_before) : [];
                calculateFundRentsInPortfolio(element);
            });

            return {
                success: true,
                assets: finalSimpleAssets,
                totalBalanceNow: responseSimpleSum.totalBalanceNow,
                totalBalanceBefore: responseSimpleSum.totalBalanceBefore
            };
        }



    } else {

        return {
            success: true,
            body: "ERRO AO TRAZER ATIVOS NORMALIZADOS",
        };

    }

}

export async function getFundsDemonstrative(clientId, month, year) {


    const responseFundsAssets = await getSimpleNormalizedFunds(clientId, month, year, null);
    if (responseFundsAssets.success) {

        const funds = responseFundsAssets.assets;
        const cnpjs = [];
        const benchmarks = [];
        funds.forEach(element => {
            if (!cnpjs.includes(element.cnpj)) {

                cnpjs.push(element.cnpj);
            }
            if (!benchmarks.includes(element.benchmark_comdinheiro)) {
                benchmarks.push({
                    comdinheiro_name: element.benchmark_comdinheiro
                });
            }
        });


        const referenceDate = getFirstBeforeBusinessDay(getLastMomentDayMonth(month, year).format('DD/MM/YYYY'));
        const responseInfosAndRisk = await getInfosAndRiskFundComdinheiroByDate(referenceDate, cnpjs, benchmarks, REQ_COMDINHEIRO_TYPE.DEMONSTRATIVO);
        console.log('responseInfosAndRisk: ', responseInfosAndRisk);

        if (responseInfosAndRisk.success) {

            const infosAndRisksByCnpj = responseInfosAndRisk.body;
            funds.forEach(fund => {

                fund.dados_cadastrais = infosAndRisksByCnpj[fund.cnpj].dados_cadastrais;
                fund.risco_retorno = infosAndRisksByCnpj[fund.cnpj].risco_retorno;
                //console.log('fund.benchmark_comdinheiro.toString().toLowerCase(): ',);
                if (fund.benchmark && fund.benchmark_comdinheiro && fund.benchmark_comdinheiro) {

                    fund.risco_retorno_benchmark = infosAndRisksByCnpj[fund.benchmark_comdinheiro.toString().toLowerCase()].risco_retorno;
                }
            });

            return {
                success: true,
                funds: funds,
            }
        }


    }

    return {
        success: false,
    }


}


//Obter o último valor de cota no mês
//Não necessariamente é a última cota do mês pois o fundo pode ter sido liquidado antes do término do mês, 
//então obter a última cota que não seja zero
export function getRealLastValueQuotaMonth(fund) {
    if (fund && fund.quotas_anbima.length) {

        let lastValueQuota = 0;
        for (let index = 0; index < fund.quotas_anbima.length; index++) {

            const quotaValueLoop = fund.quotas_anbima[index].valor_cota;
            if (quotaValueLoop == 0) {
                break;
            }
            lastValueQuota = quotaValueLoop;

        }

        return lastValueQuota;

    } else {
        return 0;
    }

}

export function formatMyFundsToShow(integralFundsBalancesMap,
    currentPeriod,
    mapFundsInfos,
    periodReport) { //array com os números que equivalem aos meses de período Ex: [3,6,12]


    const currentYearPeriod = getYearPeriod(currentPeriod);

    const formatteds = [];

    ///////////////// 
    //REPORT/////////
    ///////////////// 


    Object.entries(integralFundsBalancesMap).map(([fundId, periods]) => {
        // console.log('fundId: ', fundId);
        // console.log('periodsperiods: ', periods);

        const fundFormatted = {
            fundId: fundId,
            rentMonth: null,
            rentYear: null,
            rent12m: null,
            rent24m: null,
            rentHistoric: periods.rentAcum,
        }

        const fundsArrayPeriods = [];
        const normalizedPeriods = {};
        Object.entries(periods).map(([periodString, data]) => {
            //console.log("DARA DATA: ", data);

            if (data.rent) {
                if (periodReport == 3 || periodReport == 6 || periodReport == 'ano') {
                    console.log('ALTERANDO KEY');
                    normalizedPeriods[periodString.substring(3)] = data;
                }
                fundsArrayPeriods.push(data);
            }
        })

        if (fundsArrayPeriods && fundsArrayPeriods.length) {
            fundFormatted.balance_now = fundsArrayPeriods[fundsArrayPeriods.length - 1].balance_now;
        }

        let countPeriods = 1;
        let captalization = 1;
        //console.log('fundsArrayPeriods: ', fundsArrayPeriods);
        const periodsIn12m24m = [];
        fundsArrayPeriods.reverse().forEach(periodRent => {

            if (countPeriods == 1 && periodRent.period == currentPeriod) {
                fundFormatted.rentMonth = periodRent.rent;
            }

            captalization *= (1 + (((periodRent.rent / 100) + 1) - 1));
            periodsIn12m24m.push(periodRent.period)
            if (countPeriods == 3) {
                fundFormatted.rent3m = (captalization - 1) * 100;
                periodsIn12m24m.push('fim_3m')
            }
            if (countPeriods == 6) {
                fundFormatted.rent6m = (captalization - 1) * 100;
                periodsIn12m24m.push('fim_6m')
            }
            if (countPeriods == 12) {
                fundFormatted.rent12m = (captalization - 1) * 100;
                periodsIn12m24m.push('fim_12m')
            }
            if (countPeriods == 24) {
                fundFormatted.rent24m = (captalization - 1) * 100;
                periodsIn12m24m.push('fim_24m')
            }
            countPeriods++;
        });

        //Calculando ano
        let captalizationYear = 1;
        const fundsArrayInCurrentYear = fundsArrayPeriods.filter(el => el.yearPeriod == currentYearPeriod);
        fundsArrayInCurrentYear.forEach(periodRent => {
            captalizationYear *= (1 + (((periodRent.rent / 100) + 1) - 1));
        })
        fundFormatted.rentYear = (captalizationYear - 1) * 100;

        if (periodReport == 3) {


            const { month, year } = getMonthAndYearPeriod(currentPeriod);

            const tri_m1 = (month - 2) + '/' + year;
            const tri_m2 = (month - 1) + '/' + year;
            const tri_m3 = currentPeriod;

            // console.log('fundsArrayInCurrentYearfundsArrayInCurrentYear: ', fundsArrayInCurrentYear);
            // console.log('normalizedPeriods: ', normalizedPeriods);

            const triArrayPeriods = [tri_m1, tri_m2, tri_m3];

            triArrayPeriods.forEach((tri_m, index) => {
                if (normalizedPeriods[tri_m]) {
                    fundFormatted['rentM' + (index + 1)] = normalizedPeriods[tri_m].rent;
                }
            });


        } else if (periodReport == 6) {

            const { month, year } = getMonthAndYearPeriod(currentPeriod);

            const sem_m1 = (month - 5) + '/' + year;
            const sem_m2 = (month - 4) + '/' + year;
            const sem_m3 = (month - 3) + '/' + year;
            const sem_m4 = (month - 2) + '/' + year;
            const sem_m5 = (month - 1) + '/' + year;
            const sem_m6 = currentPeriod;

            // console.log('fundsArrayInCurrentYearfundsArrayInCurrentYear: ', fundsArrayInCurrentYear);
            // console.log('normalizedPeriods: ', normalizedPeriods);

            const semesterArrayPeriodsTri1 = [sem_m1, sem_m2, sem_m3];
            //console.log('semesterArrayPeriodsTri1: ', semesterArrayPeriodsTri1);
            const semesterArrayPeriodsTri2 = [sem_m4, sem_m5, sem_m6];


            //Capitalizações do semestre por trimestre
            fundFormatted['rentTri1'] = 1;
            fundFormatted['rentTri2'] = 1;
            semesterArrayPeriodsTri1.forEach((sem_m, index) => {
                if (normalizedPeriods[sem_m]) {
                    fundFormatted['rentTri1'] *= (1 + (((normalizedPeriods[sem_m].rent / 100) + 1) - 1));
                }
            });

            fundFormatted['rentTri1'] = (fundFormatted['rentTri1'] - 1) * 100;

            semesterArrayPeriodsTri2.forEach((sem_m, index) => {
                if (normalizedPeriods[sem_m]) {
                    fundFormatted['rentTri2'] *= (1 + (((normalizedPeriods[sem_m].rent / 100) + 1) - 1));
                }
            });

            fundFormatted['rentTri2'] = (fundFormatted['rentTri2'] - 1) * 100;

        } else if (periodReport == 'ano') {

            const month = 12;
            const year = getYearPeriod(currentPeriod);

            const m1 = (month - 11) + '/' + year;
            const m2 = (month - 10) + '/' + year;
            const m3 = (month - 9) + '/' + year;
            const m4 = (month - 8) + '/' + year;
            const m5 = (month - 7) + '/' + year;
            const m6 = (month - 6) + '/' + year;
            const m7 = (month - 5) + '/' + year;
            const m8 = (month - 4) + '/' + year;
            const m9 = (month - 3) + '/' + year;
            const m10 = (month - 2) + '/' + year;
            const m11 = (month - 1) + '/' + year;
            const m12 = month + '/' + year;

            //Capitalizações do ano por semestre
            fundFormatted['rentSem1'] = 1;
            fundFormatted['rentSem2'] = 1;

            const semester1 = [m1, m2, m3, m4, m5, m6];
            const semester2 = [m7, m8, m9, m10, m11, m12];

            // console.log('fundsArrayInCurrentYearfundsArrayInCurrentYear: ', fundsArrayInCurrentYear);
            // console.log('normalizedPeriods: ', normalizedPeriods);
            // console.log('semester1: ', semester1);
            // console.log('semester2: ', semester2);

            semester1.forEach((m, index) => {
                if (normalizedPeriods[m]) {
                    fundFormatted['rentSem1'] *= (1 + (((normalizedPeriods[m].rent / 100) + 1) - 1));
                }
            });

            fundFormatted['rentSem1'] = (fundFormatted['rentSem1'] - 1) * 100;

            semester2.forEach((m, index) => {
                console.log("SEM 2: ", m);
                console.log("normalizedPeriods[m]: ", normalizedPeriods[m]);
                if (normalizedPeriods[m]) {
                    fundFormatted['rentSem2'] *= (1 + (((normalizedPeriods[m].rent / 100) + 1) - 1));
                }
            });

            fundFormatted['rentSem2'] = (fundFormatted['rentSem2'] - 1) * 100;

        }

        //console.log('periodsIn12m24m: ', periodsIn12m24m);

        formatteds.push(fundFormatted);
    })

    if (mapFundsInfos) {

        formatteds.forEach(element => {
            //console.log('element: ', element);
            if (mapFundsInfos[element.fundId]) {
                element.fund_name = mapFundsInfos[element.fundId].fund_name;
                element.cnpj = mapFundsInfos[element.fundId].cnpj;
                element.benchmark = mapFundsInfos[element.fundId].benchmark;
                element.benchmark_comdinheiro = mapFundsInfos[element.fundId].benchmark_comdinheiro;
                element.device_abbreviation = mapFundsInfos[element.fundId].device_abbreviation;
            }

        });
    }

    console.log('formattedsformatteds: ', formatteds);

    return formatteds;

}

export function getCodeToComdinheiroQuotas(fund) {

    if (fund.ticker)
        return fund.ticker;
    let code = fund.cnpj;
    if (fund.suffix) {
        code += '_' + fund.suffix;
    }
    return code;
}

