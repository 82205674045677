import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//Controllers

import { headerSearch, tabs, unoTheme } from '../../../../../assets/styles/unoTheme';


import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { isEmpty } from 'lodash';
import moment from 'moment';
import allActions from '../../../../../actions';
import { getVaRs } from '../../../../../API/comdinheiro-api';
import { getFundsInfosByFundIds, getSettledFundsIdsByFundsIdsAndReferenceDate } from '../../../../../API/fund';
import { formatMyFundsToShow } from '../../../../../controllers/FundController';
import { rentsByPeriod } from '../../../../../controllers/ReportController';
import DefaultScrollWithHeaderContent from '../../../../utils/DefaultScrollWithHeaderContent';
import MyTable from '../../../../utils/MyTable';
import { applyMaskAmount, applyMaskAmountFreeDecimals, getDayMonthYearByDate, getFirstBeforeBusinessDay, getFirstDayMonth, getLastDayInMonthByMonthAndYear, getNovember, getYearsWithNovembers, portfolioClosedToDate } from '../../../../utils/utils';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { LossProvisionAPI } from '../../../../../API/loss_provision';
import UnoButton from '../../../../utils/UnoButton';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    },
    tabs: tabs,

    demonstrativeContainer: {
        overflowY: 'scroll',
        width: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',

        '& .demonstrativeFund': {

            marginBottom: '32px',

            '& .fundName': {
                color: unoTheme.defaultTextColor,
                paddingBottom: '16px',
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '18px',

            },

            '& .demonstrativeTopInfos': {
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '16px',

                '& .demonstrativeTopInfosLeft': {
                    paddingRight: '16px',
                },
                '& .demonstrativeTopInfosRight': {
                    borderLeft: 'solid 1px ' + unoTheme.mainColor,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }
            }
        }
    },
    tableBlade: {

        tableLayout: 'fixed',


        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '4px 4px',
                fontWeight: "bold",
                color: '#1C2228',
                wordWrap: 'break-word',
            },

            '& .MuiTableCell-root:first-child': {
                paddingLeft: '16px',
                borderRight: 'solid 1.5px #E4E7EB'
            },
            '& > *': {
                borderBottom: 'unset',
            },
        },

        '& .MuiTableBody-root': {

            '& .MuiTableRow-root': {
                borderRadius: '10px',
                '&:nth-of-type(odd)': {
                    backgroundColor: unoTheme.secondaryTuplaTable,
                    //backgroundColor: 'red',
                },

                '&:nth-of-type(even)': {
                    backgroundColor: unoTheme.mainColor,
                    color: 'white',

                    '& .MuiTableCell-root': {
                        color: 'white',
                    },
                    //backgroundColor: 'red',
                },

                '& > *': {
                    borderBottom: 'unset',
                },
            },

            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '12px 4px',
                color: '#768191',
            },

            '& .MuiTableCell-root:first-child': {
                borderRight: 'solid 1.5px #E4E7EB',
                paddingLeft: '16px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },


    },

    gridPaperTable: {
        borderRadius: '10px',
    },

    ellipsis: {
        whiteSpace: "nowrap", /* Impede a quebra de linha */
        overflow: "hidden", /* Garante que o texto extra seja ocultado */
        textOverflow: "ellipsis",
    },

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    flexCenterDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }


});


export default function LossProvisionTab(props) {

    const { selectedYearLossProvision,
        setOptionsYearsLossProvision } = props;

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const [settledFundsIds, setSettledFundsIds] = useState([]);
    const [fundsFormatteds, setFundsFormatteds] = useState([]);

    const [theNovember, setTheNovember] = useState();

    const [enableGenerateLossProvisionYear, setEnableGenerateLossProvisionYear] = useState(false);

    const [formatteedYearsLoss, setFormatteedYearsLoss] = useState();

    const [innerLoad, setInnerLoad] = useState({
        emptyMessage: "",
        loadMessage: "Carregando provisões...", size: "small"
    });

    async function loadFundsYear(client, optionYear) {

        const lastNovember = getNovember(optionYear);
        console.log("lastNovember ", lastNovember);
        const { month, year } = getDayMonthYearByDate(lastNovember);

        setTheNovember(moment.utc(lastNovember).format("MM/YYYY"));

        const currentPeriod = month + '/' + year;
        const startDate = moment.utc(client.portfolio_init).format('DD/MM/YYYY');
        const endDate = getLastDayInMonthByMonthAndYear(month, year);

        const responseRents = await rentsByPeriod(client.id, startDate, endDate, "", 0);

        if (responseRents.integralFundsBalancesMap) {

            if (Object.keys(responseRents.integralFundsBalancesMap).length) {

                const fundsIds = Object.keys(responseRents.integralFundsBalancesMap);

                const responseSettledFundsIds = await getSettledFundsIdsByFundsIdsAndReferenceDate(fundsIds, getFirstDayMonth(month, year));

                let setteldsIds = [];
                if (responseSettledFundsIds?.success) {

                    setteldsIds = responseSettledFundsIds.body.rows.map(el => el.id + "");
                    setSettledFundsIds(setteldsIds);
                }

                const responseFundsInfos = await getFundsInfosByFundIds(fundsIds);

                if (responseFundsInfos.success) {
                    //transformar em map
                    const mapFundsInfos = {};
                    const justCnpjs = [];
                    responseFundsInfos.body.rows.forEach(el => {
                        mapFundsInfos[el.id] = el;
                        justCnpjs.push(el.cnpj);
                    })


                    const responseVaRs = await getVaRs(getFirstBeforeBusinessDay(endDate), justCnpjs);

                    if (isEmpty(responseRents.integralFundsBalancesMap)) {
                        setInnerLoad({
                            emptyMessage: "Não existem fundos",
                        });
                    } else {
                        setInnerLoad(null);
                    };

                    const preFormatted = formatMyFundsToShow(responseRents.integralFundsBalancesMap, currentPeriod, mapFundsInfos);

                    if (responseVaRs?.success) {

                        const { body } = responseVaRs;
                        let loss = 0;
                        preFormatted.forEach(el => {

                            if (body[el.cnpj] != null) {
                                const factor = body[el.cnpj] * Math.sqrt(252);
                                el.factor = factor;
                                if (el.balance_now != null) {
                                    el.provision = el.balance_now * factor;
                                    loss += el.provision;
                                }
                                el.var_252_756 = factor;
                            }
                        })

                    }

                    const responseSaveLossProvisionYear = await LossProvisionAPI.saveLossProvisionYear(client.id,
                        selectedYearLossProvision,
                        JSON.stringify(preFormatted.filter(el => !setteldsIds.includes(el.fundId))));

                } else {
                    setInnerLoad(null);

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: "Falha ao trazer fundos do cliente",
                        type: 'error',
                    }))
                }
            } else {
                setInnerLoad({ emptyMessage: "Não existem fundos" })

            }



        } else {

            setInnerLoad(null);

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao trazer fundos do cliente",
                type: 'error',
            }))
        }
    }

    const useRowStyles = makeStyles({
        root: {
            cursor: 'pointer',
            borderBottom: 'unset',
            '& > *': {
                borderBottom: 'unset',
            },
            '& .MuiTableCell-sizeSmall:last-child': {
                paddingRight: '24px',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },

            '&:hover': {
                background: unoTheme.hoverTable + ' !important',
            }


        },
        oddRow: {
            backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            '& .MuiTableRow-root.maybeOdd': {
                background: `${unoTheme.secondaryTuplaTable}`,
            },
        },
        noRadiusBottom: {
            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px !important',
                borderBottomLeftRadius: '0px !important',
                //backgroundColor: `${unoTheme.secondaryTuplaTable}`,
            },
            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px !important',
                borderBottomRightRadius: '0px !important',
                //backgroundColor: `${unoTheme.secondaryTuplaTable}`,
            },
        },
        green: {
            color: unoTheme.greenMoney,
        },
        red: {
            color: unoTheme.errorColor,
        },
        blue: {
            color: "blue",
        },
        ok: {
            color: unoTheme.greenMoney,
            cursor: 'pointer',

            fontSize: '1.2em',

        },
        pendent: {
            color: unoTheme.warningColor,
            cursor: 'pointer',
            fontSize: '1.2em',

        },
        hiddenIcon: {
            visibility: 'hidden'
        },
        arrowTableIcon: {
            background: unoTheme.mainColor,
            color: 'white',
            borderRadius: '4px',
            height: '22px',
            width: '22px',

            '&:hover': {
                color: 'white',
                background: unoTheme.hoverMainButtonColor
            }
        },
        tableFixed: {
            tableLayout: 'fixed',
        },
        borderOnOpen: {
            '& > *': {
                borderBottom: 'unset',
            },
        },

        innerTableRow: {

            '& .MuiTableBody-root tr': {

                cursor: 'default !important',
                pointerEvents: 'none',
            }
        }

    });

    const classesRow = useRowStyles();

    const [opensAccordions, setOpensAccordions] = useState([])

    function handleOpen(e, open, index) {

        console.log('HANDLE OPEN: ', open);
        opensAccordions[index] = open;

        let newOpensAccordions = [...opensAccordions]

        newOpensAccordions.forEach((el, i) => {
            newOpensAccordions[i] = false;
        })
        newOpensAccordions[index] = open
        setOpensAccordions(newOpensAccordions);
        e.stopPropagation();

    }

    useEffect(() => {

        console.log("opensAccordions: ", opensAccordions);
        const openedIndex = opensAccordions.findIndex(el => el == true);
        console.log("openedIndex: ", openedIndex)


    }, [opensAccordions])

    function Rows(rows) {
        //const { row, index } = props;

        //console.log("ROWS TO SHOW: ", rows);
        let rowsToShow = [];

        rows.forEach((row, index) => {

            const { loss_provision } = row;

            const totalLoss = loss_provision.reduce((sum, item) => sum + (item.provision ? item.provision : 0), 0);

            // console.log("ROW: ", row);
            // console.log("TOTAL ROW: ", totalRow);
            rowsToShow.push(
                <TableRow key={'loss-provision-year-' + index}
                    className={[
                        index % 2 != 1 ? classesRow.oddRow : "",
                        classesRow.root,
                        opensAccordions[index] && index % 2 != 1 ? classesRow.noRadiusBottom : ""
                    ].join(' ')}
                    onClick={(e) => null}>
                    <TableCell align="center">

                        <IconButton aria-label="expand row" size="small"
                            className={[classesRow.arrowTableIcon].join(' ')}
                            onClick={(e) => handleOpen(e, !opensAccordions[index], index)}>
                            {opensAccordions[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                    </TableCell>
                    <TableCell align="left">{row.year}</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="right"></TableCell>


                </TableRow>)
            rowsToShow.push(
                <TableRow
                    key={'funds-in-year-loss-' + index}
                    className={[classesRow.innerTableRow, index % 2 == 0 ? classesRow.oddRow : "", opensAccordions[index] ? "" : classesRow.borderOnOpen].join(' ')}>
                    <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse in={opensAccordions[index]} timeout="auto">
                            <Box>

                                <Table size="small"
                                    className={classesRow.tableFixed}>

                                    <colgroup>
                                        <col width="5%" />
                                        <col width="35%" />
                                        <col width="25%" />
                                        <col width="15%" />
                                        <col width="10%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <TableBody>
                                        {row.loss_provision && row.loss_provision.map((row, index) => (

                                            <TableRow
                                                key={'client-fund-' + index}
                                            >
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="left">{row.fund_name}</TableCell>
                                                <TableCell align="center">{row.cnpj}</TableCell>
                                                <TableCell align="right" className={classes.green}>{row.balance_now != null ? applyMaskAmount(row.balance_now, true) : "-"}</TableCell>
                                                <TableCell align="center" style={{ color: "blue" }}>{row.var_252_756 != null ? applyMaskAmountFreeDecimals(row.var_252_756, null, 6) + "%" : "-"}</TableCell>
                                                <TableCell align="right" style={{ color: "orangered" }}>{row.provision != null ? applyMaskAmount(row.provision, true) : "-"}</TableCell>

                                            </TableRow>

                                        ))}
                                    </TableBody>

                                    <TableFooter className={classes.myTableFooter}>
                                        <TableRow className='totalLinePL' key={'footer-loss-provisional-' + index}>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="right">TOTAL</TableCell>
                                            <TableCell align="right"><span className={'spanTotal'}>{totalLoss != null ?
                                                applyMaskAmount(totalLoss, true) : '-'}</span></TableCell>
                                        </TableRow>
                                    </TableFooter>

                                </Table>

                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )
        });


        return rowsToShow;
    }

    const formatYearsLoss = (yearsLoss) => {

        return yearsLoss.map(element => ({
            ...element,
            loss_provision: JSON.parse(element.loss_provision),
        }));
    }

    const loadYearsLossProvision = async (clientId) => {

        const responseYearsLoss = await LossProvisionAPI.getYearsLossProvision(clientId);
        if (responseYearsLoss.success) {

            if (responseYearsLoss.body?.rows?.length == 0) {
                setEnableGenerateLossProvisionYear(true);
                setInnerLoad(null);
            } else {
                if (!responseYearsLoss.body.rows.find(el => el.year === selectedYearLossProvision)) {
                    setEnableGenerateLossProvisionYear(true);
                    setInnerLoad(null);
                } else {

                    const formatted = formatYearsLoss(responseYearsLoss.body.rows);
                    setFormatteedYearsLoss([formatted[0], formatted[0], formatted[0]]);
                    setInnerLoad(null);

                    console.log("formatteedYearsLoss: ", formatteedYearsLoss);
                }
            }

            console.log("responseYearsLoss: ", responseYearsLoss);

        }
        //loadFundsYear(clientOn.client);
    }

    const loadOptionsYearsToLossProvision = (client) => {


        console.log("client.portfolio_closed: ", client.portfolio_closed);
        const portfolioClosedDate = portfolioClosedToDate(client.portfolio_closed);
        const startDate = new Date(2024, 0, 1);

        console.log("startDate: ", startDate);
        console.log("portfolioClosedDate: ", portfolioClosedDate);
        const yearsWithNovembers = getYearsWithNovembers(startDate, portfolioClosedDate);

        setOptionsYearsLossProvision(yearsWithNovembers);

        if (yearsWithNovembers.length === 0) {
            setInnerLoad({
                emptyMessage: "A carteira deve possuir informação lançada pelo menos a partir de Nov/2024",
            })
        }

        console.log("yearsWithNovembers: ", yearsWithNovembers);

    }

    useEffect(() => {
        if (clientOn?.client?.id && selectedYearLossProvision) {

            loadYearsLossProvision(clientOn.client.id);
        }
    }, [clientOn?.client?.id, selectedYearLossProvision])

    useEffect(() => {
        if (clientOn?.client?.id) {

            loadOptionsYearsToLossProvision(clientOn.client);
        }
    }, [clientOn?.client?.id])

    return (
        <div className={classes.root}>

            <DefaultScrollWithHeaderContent innerLoad={innerLoad}>

                {
                    enableGenerateLossProvisionYear ?

                        <div className={classes.flexCenterDiv}>

                            <UnoButton
                                onClick={() => loadFundsYear(clientOn.client, selectedYearLossProvision)}
                                style={{ marginRight: '16px' }}
                                size={'small'}
                                disabled={!enableGenerateLossProvisionYear}
                                type='primary'>
                                {"Gerar provisão de perdas " + (selectedYearLossProvision ? ("[" + selectedYearLossProvision + "]") : "")}
                            </UnoButton>



                        </div>

                        :

                        <MyTable stickyHeader
                            size="small">
                            <colgroup>
                                <col width="5%" />
                                <col width="35%" />
                                <col width="25%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="left">Fundo</TableCell>
                                    <TableCell align="center">CNPJ</TableCell>
                                    <TableCell align="right">Saldo {theNovember}</TableCell>
                                    <TableCell align="center">VaR <span style={{ fontSize: "8px" }}>252,756</span></TableCell>
                                    <TableCell align="right">Provisão</TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                clientOn?.client ?
                                    <TableBody>
                                        {formatteedYearsLoss?.length ? Rows(formatteedYearsLoss) : null}
                                    </TableBody>
                                    : null
                            }
                        </MyTable>
                }

            </DefaultScrollWithHeaderContent>

        </div>
    );
}



