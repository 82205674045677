import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import MyAutoComplete from "../../utils/MyAutoComplete";
import MySwitch from '../../utils/MySwitch';
import MyTextField from '../../utils/MyTextField';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

//API
import {
    listLegislationArticlesBySegment,
    listLegislationSegments
} from '../../../API/legislation_article';

import {
    getLocalBenchmarks
} from '../../../API/local_benchmaks';


import {
    createFund,
    deleteFund,
    updateFund,
    updateFundArticles,
    updateInfosCarteiraComdinheiroByCNPJ
} from '../../../API/fund';

//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { tabs, unoTheme } from "../../../assets/styles/unoTheme";
import MyDatePicker from '../../utils/MyDatePicker';
import MySelect from '../../utils/MySelect';
import UnoButton from '../../utils/UnoButton';

import { getIndexSuffixComdinheiro, getSuffixesComdinheiro } from '../../utils/suffixesComdinheiro';

import {
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    formatAnbimaDateToPt,
    formatPtStringDateToAnbimaDate,
    getDayMonthYearByPtDate,
    getFirstBeforeBusinessDay,
    getLastDayMonth,
    getMonthName,
    getTheLastDayCompleteMonthPt,
    isValidCnpj,
    parseCurrencyByFloat,
    parseFloatByPtCurrency
} from '../../utils/utils';

import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmActionRightContent from './ConfirmActionRightContent';

//Controllers
import {
    doGetAllLocalQuotasByFundId,
    doSaveOrUpdateLocalQuotas,
    formatFundLocalQuotasMonthYear
} from '../../../controllers/FundLocalQuotasController';

import {
    getCotasByListCnpjs, getInfosByListCnpjs
} from '../../../controllers/ComdinheiroController';

import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import moment from 'moment';
import { listAllClients } from '../../../API/client';
import { DistributorsAPI } from '../../../API/distributors';
import { updateInfosLocalQuotasByFundId } from '../../../API/fund_local_quotas';
import { getCodeToComdinheiroQuotas } from '../../../controllers/FundController';
import ComdinheiroInfosFund from '../../utils/ComdinheiroInfosFund';
import { MyTab, MyTabs } from '../../utils/MyTabs';
import FundArticlesWrapper from '../../utils/FundArticlesWrapper/FundArticlesWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    tabs: tabs,

    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
            padding: "0px 24px",
        }
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 0.5px #f4f4f4',

        background: unoTheme.menuContent,

        '& .closeIcon': {
            padding: theme.spacing(3),
        }
    },

    main: {
        //height: 'calc(100% - 96px)',
        overflowY: 'scroll',
        height: 'calc(100vh - 71px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        },

        '& .topQuotas': {
            display: 'flex',
            alignItems: 'center',
            '& div': {
                display: 'flex',
                alignItems: 'center',
            }
        }

    },

    mainQuotas: {
        padding: theme.spacing(0),
        overflowY: 'unset !important',

        height: 'calc(100vh - 95px) !important',

        '& .MuiPaper-root': {
            height: 'calc(100vh - 192px) !important',
        },

        '& .topQuotas': {
            padding: '10px 16px',
            borderBottom: 'solid 1px #dddddd',
            justifyContent: 'space-between'
        },

        '& .bottom-quotas': {
            height: '38px',
            padding: '12px 24px',
            borderTop: 'solid 1px #dddddd'
        },

        '& .edit-quota': {
            height: 'calc(100vh - 72px)',
            padding: theme.spacing(3),
            position: 'absolute',
            //height: '100%',
            width: '100%',
            zIndex: '2',
            background: "rgba(255, 255, 255, 0.95)",
            overflowY: 'scroll',

            '& .buttons-edit': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',

                '& .main-actions': {
                    display: 'flex',
                    alignItems: 'center',
                }
            },

            '& .edit-quota-inputs': {
                marginTop: '-90px',

                '& .MuiTextField-root': {
                    marginTop: '16px',
                    marginBottom: '16px',
                },




            }
        },

        '& .add-client-fund-distributor': {
            height: 'calc(100vh - 72px)',
            padding: theme.spacing(3),
            position: 'absolute',
            //height: '100%',
            width: '100%',
            zIndex: '2',
            background: "rgba(255, 255, 255, 0.95)",
            overflowY: 'scroll',
        }

    },

    myDatePicker: {
        marginBottom: '16px',
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },

    tabsAsset: {
        background: unoTheme.hoverTable,
        '& .MuiButtonBase-root': {
            height: '71px',
        }
    },

    rightDivBackdrop: {
        zIndex: 3,
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.85,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    infosFundContent: {
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

}));

export default function NewFund(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const [allClients, setAllClients] = useState(null);
    //const activeClients = useSelector(state => state.activeClients)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState("");
    const [searchedAnbimaCod, setSearchedAnbimaCod] = useState(null);


    const [suffixesComdinheiro,] = useState(getSuffixesComdinheiro());

    const [confirmingAction, setConfirmingAction] = useState(false);

    const [inputAnbimaCod, setInputAnbimaCod] = useState("");
    const [inputNameFund, setInputNameFund] = useState("");
    const [inputTickerFund, setInputTickerFund] = useState("");
    const [inputInitDateFund, setInputInitDateFund] = useState(null);
    const [inputEndDateFund, setInputEndDateFund] = useState(null);
    const [inputLackLiquidityDateFund, setInputLackLiquidityDateFund] = useState(null);
    const [inputCnpjFund, setInputCnpjFund] = useState("");
    const [inputLiquidityFund, setInputLiquidityFund] = useState("");
    const [inputBenchmarkFund, setInputBenchmarkFund] = useState("");
    const [inputDescriptionFund, setInputDescriptionFund] = useState("");

    //MUTIPLAS RESOLUÇÕES

    //V1
    const [segmentsV1, setSegmentsV1] = useState([]);
    const [articlesV1, setArticlesV1] = useState([]);
    const [inputLegislationSegmentFundV1, setInputLegislationSegmentFundV1] = useState(undefined);
    const [disableArticleV1, setDisableArticleV1] = useState(true);
    const [inputArticleFundV1, setInputArticleFundV1] = useState(undefined);
    //V2
    const [segmentsV2, setSegmentsV2] = useState([]);
    const [articlesV2, setArticlesV2] = useState([]);
    const [inputLegislationSegmentFundV2, setInputLegislationSegmentFundV2] = useState(undefined);
    const [disableArticleV2, setDisableArticleV2] = useState(true);
    const [inputArticleFundV2, setInputArticleFundV2] = useState(undefined);

    const [inputSpecialFund, setInputSpecialFund] = useState(false);
    const changeSpecialFund = (event) => {
        setInputSpecialFund(event.target.checked);
    };

    const [inputNoInfosFund, setInputNoInfosFund] = useState(false);
    const changeNoInfosFund = (event) => {
        setInputNoInfosFund(event.target.checked);
    };

    const [inputPoupFund, setInputPoupFund] = useState(false);
    const changePoupFund = (event) => {
        setInputPoupFund(event.target.checked);
    };

    const [inputSuffixFund, setInputSuffixFund] = useState(0);
    function handleSuffixFund(event) {

        setInputSuffixFund(event.target.value)
    }

    //distributors    
    const [distributors, setDistributors] = useState([]);
    const [inputDistributorFund, setInputDistributorFund] = useState(null);

    const [localBenchmarks, setLocalBenchmarks] = useState([]);
    const [inputLocalBenchmarkFund, setInputLocalBenchmarkFund] = useState(null);
    function handleLocalBenchmarkFund(event) {

        setInputLocalBenchmarkFund(event.target.value)
    }


    const [submitted, setSubmitted] = useState(false);

    function handleLegislationSegmentFundV1(event) {

        setDisableArticleV1(true);
        setInputLegislationSegmentFundV1(event.target.value);
        loadArticles(segmentsV1[event.target.value].id, 1);

    }

    function handleLegislationSegmentFundV2(event) {

        setDisableArticleV2(true);
        setInputLegislationSegmentFundV2(event.target.value);
        loadArticles(segmentsV2[event.target.value].id, 2);

    }

    //Tab de saldos do ano ou descrição do ativo
    const [tabValue, setTabValue] = useState(0); //detail
    function handleChangeTabValue(event, value) {
        setTabValue(value)
    }

    //Tab de cotas
    const [monthSelected, setMonthSelected] = useState(0);
    const [yearSelected, setYearSelected] = useState(0);

    const [monthsInYearQuotas, setMonthsInYearQuotas] = useState([]);
    const [yearsWithMonths, setYearsWithMonths] = useState([]);
    const [yearsQuotas, setYearsQuotas] = useState([]);

    //Cotas
    const [fundLocalQuotas, setFundLocalQuotas] = useState(null);
    //Infos
    const [fundLocalInfos, setFundLocalInfos] = useState(null);
    const [confirmUpdateAllInfos, setConfirmUpdateAllInfos] = useState(false);

    const [edittingQuota, setEdittingQuota] = useState(false);
    const [edittingOldQuota, setEdittingOldQuota] = useState(false);
    const [oldQuota, setOldQuota] = useState(false);
    const [newJsonQuotas, setNewJsonQuotas] = useState(false);
    const [confirmOverrideQuota, setConfirmOverrideQuota] = useState(false);
    const [confirmRemoveQuota, setConfirmRemoveQuota] = useState(false);
    const [confirmUpdateAllQuotas, setConfirmUpdateAllQuotas] = useState(false);
    const [confirmUpdateTickerFundQuotas, setConfirmUpdateTickerFundQuotas] = useState(false);

    const [inputDateNewQuota, setInputDateNewQuota] = useState(new Date());
    const [inputValueNewQuota, setInputValueNewQuota] = useState("");
    const [inputValueNewPlQuota, setInputValueNewPlQuota] = useState("");

    //Exceções de distribuidor por cliente
    const [clientFundDistributors, setClientFundDistributors] = useState([]);
    const [addingClientFundDistributor, setAddingClientFundDistributor] = useState(false);

    //Client Cotas
    const [multipleInputsClientQuotas, setMultipleInputsClientQuotas] = useState({
        focusOnId: '',
        items: []
    });

    //Period articles 
    const [periodArticles, setPeriodArticles] = useState();

    async function loadAllClients() {
        setOpenBackdrop(true);

        const responseAllClients = await listAllClients();
        if (responseAllClients.success) {
            setAllClients(responseAllClients.body.rows);
            setOpenBackdrop(false);
            return responseAllClients.body.rows;
        } else {
            setOpenBackdrop(false);
            return [];
        }


    }

    async function addInputClientQuota() {

        if (allClients == null) {

            await loadAllClients();

        }

        console.log('multipleInputsClientQuotas: ', multipleInputsClientQuotas);
        const newMultipleInputsClientQuotas = { ...multipleInputsClientQuotas };
        newMultipleInputsClientQuotas.items.push({
            clientQuota: null,
            valueQuota: '',
        });
        setMultipleInputsClientQuotas(newMultipleInputsClientQuotas);
    }

    function changeInputClientQuota(attr, newValue, index) {
        const newMultipleInputsClientQuotas = { ...multipleInputsClientQuotas };

        if (newMultipleInputsClientQuotas.items[index]) {
            newMultipleInputsClientQuotas.items[index][attr] = newValue;
            newMultipleInputsClientQuotas.focusOnId = attr + '-' + index;

            setMultipleInputsClientQuotas(newMultipleInputsClientQuotas);
        }

    }

    function removeInputClientQuota(indexToRemove) {


        const newItems = [];
        multipleInputsClientQuotas.items.forEach((item, index) => {
            if (index != indexToRemove) {
                newItems.push(item);
            }
        });

        multipleInputsClientQuotas.items = [...newItems];

        const newMultipleInputsClientQuotas = { ...multipleInputsClientQuotas };
        setMultipleInputsClientQuotas(newMultipleInputsClientQuotas);

    }

    function InputClientQuota(props) {

        const { row, index } = props;

        const [selectedClient, setSelectedClient] = useState(row.clientQuota);
        const [inputValueNewQuotaClient, setInputValueNewQuotaClient] = useState(row.valueQuota);

        const handleChangeClient = (event, newValue) => {

            changeInputClientQuota('clientQuota', newValue, index);
            setSelectedClient(newValue)
        };

        const handleChangeQuotaClient = (newValue) => {
            changeInputClientQuota('valueQuota', newValue, index);
            setInputValueNewQuotaClient(newValue)
        };

        const handleRemoveQuotaClient = (index) => {
            removeInputClientQuota(index);
        };


        return (


            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6}>
                    {
                        allClients ?
                            <MyAutoComplete
                                id={'clientQuota-' + index}
                                options={allClients}
                                autoComplete
                                customPadding={'6px 16px'}
                                customHeight={'25px'}
                                value={
                                    selectedClient
                                }
                                onChange={(event, newValue) => {
                                    handleChangeClient(event, newValue)
                                }}
                                outerlabel={'Selecione o cliente'}
                                placeholder={"Selecione o cliente"}
                                getOptionLabel={(option) => option.label_name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option) => (
                                    <React.Fragment key={'autocomplete-' + option.id}>
                                        <span>{option.label_name}</span>
                                    </React.Fragment>
                                )}
                            />
                            : null
                    }

                </Grid>
                <Grid item lg={5} md={5} sm={5}>
                    <MyTextField
                        id={'valueQuota-' + index}
                        // defaultValue={inputValueNewQuotaClient}
                        // onBlur={(value) => handleChangeQuotaClient(value)}
                        value={inputValueNewQuotaClient}
                        onChange={(value) => handleChangeQuotaClient(value)}
                        align="right"
                        outerlabel={'Valor da cota'}
                        placeholder="Ex: 2,345343"
                        validator={inputValueNewQuotaClient == "" && submmitedEditQuota ? true : undefined}
                        helperText={"Insira um valor da cota"}
                        type="text"
                        fullWidth />
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
                    <UnoButton
                        style={{ marginTop: '22px', marginLeft: '0px' }}
                        isIconButton
                        onClick={() => handleRemoveQuotaClient(index)}
                        size='small'
                        myIcon={<DeleteIcon />}
                        type={'default'}>
                        Remover
                    </UnoButton>
                </Grid>
            </Grid>

        )
    }
    //FIM CLIENT COTAS

    //Client fund distributor

    const [newClientFundDistributor, setNewClientFundDistributor] = useState({
        distributor: null,
        client: null,
        fundId: null,
    })

    function InputClientFundDistributor() {

        return (


            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6}>
                    {
                        allClients ?
                            <MyAutoComplete
                                id={'clientFundDistributor'}
                                options={allClients}
                                autoComplete
                                customPadding={'6px 16px'}
                                customHeight={'25px'}
                                value={
                                    newClientFundDistributor.client
                                }
                                onChange={(event, newValue) => {
                                    setNewClientFundDistributor((prev) => ({
                                        ...prev,
                                        client: newValue
                                    }))
                                }}
                                outerlabel={'Selecione o cliente'}
                                placeholder={"Selecione o cliente"}
                                getOptionLabel={(option) => option.label_name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                validator={!newClientFundDistributor.client && submittedNewClientFundDistributor ? true : false}
                                helperText={"Insira o cliente"}
                                renderOption={(option) => (
                                    <React.Fragment key={'autocomplete-client-distributor-' + option.id}>
                                        <span>{option.label_name}</span>
                                    </React.Fragment>
                                )}
                            />
                            : null
                    }

                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                    {
                        distributors ?
                            <MyAutoComplete
                                onChange={(event, newValue) => {
                                    setNewClientFundDistributor((prev) => ({
                                        ...prev,
                                        distributor: newValue
                                    }))
                                }}
                                options={distributors}
                                autoComplete
                                outerlabel={"Distribuidor do fundo"}
                                label={'Distribuidor do fundo'}
                                filterOptions={(options, state) => {
                                    if (!options) return [];
                                    return options.filter(element => element.cnpj
                                        .includes(state.inputValue)
                                        || element.name.toLowerCase().includes(state.inputValue.toLowerCase()));
                                }}
                                value={newClientFundDistributor.distributor}
                                getOptionLabel={(option) => option.name}
                                validator={newClientFundDistributor.distributor == null && submittedNewClientFundDistributor ? true : false}
                                helperText={"Informe o distribuidor"}
                                renderOption={(option) => (
                                    <React.Fragment key={'autocomplete-client-fund-distributor-fund-' + option.id}>
                                        <span className={classes.bold}>{option.name}</span>
                                    </React.Fragment>
                                )}
                            />
                            : null
                    }

                </Grid>
            </Grid>

        )
    }

    function handleChangeMonthQuotas(month) {

        console.log("MONTH SELECTED: ", month);
        setMonthSelected(month);
    }

    function handleChangeYearQuotas(year) {
        console.log("YEAR SELECTED: ", year);
        setYearSelected(year);
        setMonthsInYearQuotas(yearsWithMonths[yearsQuotas[year]]);
        setMonthSelected(0);

    }

    async function loadArticles(segmentId, resolutionVersion) {

        console.log("Listando: ", segmentId);

        let responseArticles = await listLegislationArticlesBySegment(segmentId);

        console.log("Aticles: ", responseArticles);

        if (responseArticles.success) {

            if (resolutionVersion == 1) {
                setArticlesV1(responseArticles.body.rows);
                setDisableArticleV1(false);
            } else {
                setArticlesV2(responseArticles.body.rows);
                setDisableArticleV2(false);
            }

            //setOpenBackdrop(false);
            return responseArticles.body.rows;

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao listar artigos de resolução"
            }))
            //setOpenBackdrop(false);
            return [];
        }



    }

    function initInfos(data, benchmarks, dists) {

        setInputNameFund(data.fund_name);
        setInputTickerFund(data.ticker);
        setInputInitDateFund(data.uno_date_quota_init ? moment.utc(data.uno_date_quota_init) : null);
        setInputEndDateFund(data.uno_date_quota_end ? moment.utc(data.uno_date_quota_end) : null);
        setInputLackLiquidityDateFund(data.lack_liquidity_date ? moment.utc(data.lack_liquidity_date) : null);
        setInputAnbimaCod(data.anbima_cod);
        setInputCnpjFund(data.cnpj);
        setInputLiquidityFund(data.liquidity);
        setInputBenchmarkFund(data.benchmark);
        setInputDescriptionFund(data.description);
        setInputDistributorFund(dists.find(el => el.id === data.distributor_id));

        console.log("DATA: ", data);

        const localBenchmarksIndex = benchmarks.findIndex(el => el.id === data.local_benchmark_id);
        setInputLocalBenchmarkFund(localBenchmarksIndex != -1 ? localBenchmarksIndex : null);
        setInputSpecialFund(data.special);
        setInputNoInfosFund(data.no_infos_api);
        setInputPoupFund(data.is_poupanca);
        setInputSuffixFund(getIndexSuffixComdinheiro(data.suffix));


    }


    async function loadInfos(data, update) {

        const responseLocalBenchmarks = await getLocalBenchmarks();
        if (responseLocalBenchmarks.success) {

            setLocalBenchmarks(responseLocalBenchmarks.body.rows)

            const responseDistributors = await DistributorsAPI.getAll();
            if (responseDistributors.success) {

                const dists = responseDistributors.body.rows
                setDistributors(dists);

                if (update) {
                    initInfos(data, responseLocalBenchmarks.body.rows, dists);
                }

                setOpenBackdrop(false);

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Falha ao listar distribuidores"
                }))

                setOpenBackdrop(false);
            }


        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao listar segmentos de legislação"
            }))

            setOpenBackdrop(false);
        }





    }

    const [validatedMessagesPeriodArticles, setValidatedMessagesPeriodArticles] = useState({});

    const isValidPeriodsArticles = useCallback(() => {

        let isValid = true;
        const messages = {};
        let lastEndPeriod = null;
        const hipoteticLastDate = moment("9999-12-31", "YYYY-MM-DD");
        periodArticles.forEach((element, index) => {

            const { article_id,
                begin_period,
                end_period,
            } = element;

            messages[index] = [];
            if (!article_id) {
                messages[index].push("O artigo e os períodos devem estar selecionados!")
                isValid = false;
            }

            if (!begin_period) {
                messages[index].push("O início do período deve estar selecionado!")
                isValid = false;
            }

            if (index != 0 && moment(begin_period).isBefore(lastEndPeriod ? moment(lastEndPeriod) : hipoteticLastDate)) {
                messages[index].push("Os períodos devem ser encadeados, o início do enquadramento subsequente deve ser posterior ao fim do período anterior")
                isValid = false;
            }

            if (end_period == null && index != (periodArticles.length - 1)) {
                messages[index].push("Apenas o último enquadramento pode ter fim de período em aberto")
                isValid = false;
            }

            lastEndPeriod = end_period;

            //Checando datas
            if (end_period && moment(begin_period).isSameOrAfter(moment(end_period))) {
                messages[index].push("A data do fim do período deve ser posterior a data de início!")
                isValid = false;
            }
        });

        console.log("MESSAGESSS: ", messages);
        return {
            isValid: isValid,
            messages: messages,
        };

    }, [periodArticles])

    async function doSaveFund(event) {

        event.preventDefault();
        setSubmitted(true);

        setOpenBackdrop(true);

        console.log("Period articles: ", periodArticles);

        //Checando enquadramentos no novo modelo
        const { isValid, messages } = isValidPeriodsArticles(periodArticles);
        setValidatedMessagesPeriodArticles(messages);
        if (!isValid) {
            setOpenBackdrop(false);
            return
        }

        if (inputLocalBenchmarkFund != null
            && inputDistributorFund != null
            && localBenchmarks[inputLocalBenchmarkFund] != null) {

            if (inputLackLiquidityDateFund && !inputLiquidityFund) {
                setOpenBackdrop(false);
                return
            }

            let fund = {
                legislation_article_id: null,
                legislation_article_id_V2: null,
                special: inputSpecialFund,
                no_infos_api: inputNoInfosFund,
                is_poupanca: inputPoupFund,
                uno_date_quota_init: inputInitDateFund ? moment(inputInitDateFund).format("DD/MM/YYYY") : null,
                uno_date_quota_end: inputEndDateFund ? moment(inputEndDateFund).format("DD/MM/YYYY") : null,
                anbima_cod: inputAnbimaCod == "" ? null : inputAnbimaCod,
                cnpj: inputCnpjFund,
                liquidity: inputLiquidityFund,
                lack_liquidity_date: inputLackLiquidityDateFund ? moment.utc(inputLackLiquidityDateFund).format("DD/MM/YYYY") : null,
                //benchmark: inputBenchmarkFund, substituido por tabela de benchmarks
                local_benchmark_id: localBenchmarks[inputLocalBenchmarkFund].id,
                fund_name: inputNameFund,
                ticker: inputTickerFund,
                description: inputDescriptionFund,
                //caso seja a primeira opção [0] SEM SUFIXO deve-se enviar null para o banco
                //caso seja outra opção manda-se a string referente
                suffix: inputSuffixFund != 0 ? suffixesComdinheiro[inputSuffixFund] : null,
                distributor_id: inputDistributorFund.id,
            }


            //Incluir código anbima

            console.log("FUND TO SAVE: ", fund);

            if (isValidCnpj(fund.cnpj)
                && fund.fund_name != "") {

                if (content.update) {

                    //Adicionando o id no objeto a ser atualizado a partir das informações da tela anterior
                    fund.id = content.data.id;

                    setSubmitted(false);

                    let response = await updateFund(fund);

                    if (response.success) {

                        //Atualizando enquadramentos
                        const responseSave = await updateFundArticles(fund.id, periodArticles);

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: "Fundo atualizado com sucesso"

                        }));

                        dispatch(allActions.rightContentActions.update())

                    } else {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao atualizar o fundo: " + (response.body.message.includes('duplicate key') ? 'Já existe fundo cadastrado com este COD ANBIMA' : response.body.message)

                        }));
                    }

                } else {


                    setSubmitted(false);

                    const response = await createFund(fund);

                    if (response.success) {

                        const { fund_id } = response.body;

                        //Atualizando enquadramentos
                        const responseSave = await updateFundArticles(fund_id, periodArticles);

                        const code = getCodeToComdinheiroQuotas(fund);

                        const lastDayMonth = getTheLastDayCompleteMonthPt();
                        const { month, year } = getDayMonthYearByPtDate(lastDayMonth);

                        updateAllQuotas(
                            parseInt(month),
                            parseInt(year),
                            fund_id,
                            code,
                            fund.ticker
                        );

                        await updateInfosCarteiraComdinheiroByCNPJ(fund.cnpj);

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: "Fundo cadastrado com sucesso"

                        }));

                        dispatch(allActions.rightContentActions.update())

                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao cadastrar o fundo: " + response.body.message

                        }));
                    }

                }
            }

        }

        setOpenBackdrop(false);
    }

    async function doDeleteFund() {

        setOpenBackdrop(true);

        let response = await deleteFund(content.data.id);
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Fundo excluído com sucesso!"
            }))


        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao excluir fundo!"
            }))

        }

        setOpenBackdrop(false);
        dispatch(allActions.rightContentActions.update())
    }

    async function loadClientFundDistributors(fundId) {

        const response = await DistributorsAPI.getClientFundDistributors(fundId);

        console.log("response load x: ", response);

        if (response.success) {
            setClientFundDistributors(response.body.rows);
        }


    }

    async function loadLocalQuotas(fundId) {


        console.log("LOAD LOCAL QUOTAS");
        let responseLocalQuotas = await doGetAllLocalQuotasByFundId(fundId);

        if (responseLocalQuotas.success && responseLocalQuotas.body && responseLocalQuotas.body.length > 0) {

            let formatted = formatFundLocalQuotasMonthYear(responseLocalQuotas.body);

            console.log("QUOTAS: ", formatted);

            setFundLocalQuotas(formatted.data);
            setFundLocalInfos(formatted.dataInfos);

            if (yearsQuotas.length == 0) {
                setYearsQuotas(formatted.years);
                setYearsWithMonths(formatted.yearsWithMonths);
                setMonthsInYearQuotas(formatted.yearsWithMonths[formatted.years[0]]);
                console.log("SETANDO");
                console.log(monthSelected);
                console.log(yearSelected);

                setYearSelected(yearSelected);
                setMonthSelected(monthSelected);
            }




        }

        setOpenBackdrop(false);
        setMessageBackdrop('');


    }

    function doCancelEdittingQuota() {
        setMultipleInputsClientQuotas({
            focusOnId: '',
            items: [],
        })
        setEdittingQuota(false)
    }

    function cancelConfirmOverrideQuota() {
        setConfirmOverrideQuota(false);
        setEdittingQuota(true);
        setOpenBackdrop(false);
    }

    function doConfirmOverrideQuota() {
        setConfirmOverrideQuota(false);
        saveNewQuota(newJsonQuotas);
    }

    function cancelConfirmRemoveCota() {
        setConfirmRemoveQuota(false);
        setEdittingQuota(true);
        setOpenBackdrop(false);
    }

    function doConfirmRemoveQuota() {
        setConfirmRemoveQuota(false);
        removeQuota();
    }

    function cancelConfirmUpdateAllQuotas() {
        setConfirmUpdateAllQuotas(false);
        setOpenBackdrop(false);
    }

    function cancelConfirmUpdateTickerFundQuotas() {
        setConfirmUpdateTickerFundQuotas(false);
        setOpenBackdrop(false);
    }

    const [submittedNewClientFundDistributor, setSubmittedNewClientFundDistributor] = useState(false);

    async function doAddClientFundDistributor() {

        setSubmittedNewClientFundDistributor(false);
        setNewClientFundDistributor({
            distributor: null,
            client: null,
            fundId: content.data.id,
        })
        if (allClients == null) {

            await loadAllClients();

        }
        setAddingClientFundDistributor(true);


    }

    function doCancelAddingClientFundDistributor() {
        setAddingClientFundDistributor(false);
    }

    async function doCreateClientFundDistributor() {

        setSubmittedNewClientFundDistributor(true);
        setOpenBackdrop(true);

        console.log("New data: ", newClientFundDistributor);
        if (newClientFundDistributor.client
            && newClientFundDistributor.distributor
            && newClientFundDistributor.fundId) {

            const response = await DistributorsAPI.createClientFundDistributor({
                distributor_id: newClientFundDistributor.distributor.id,
                client_id: newClientFundDistributor.client.id,
                fund_id: newClientFundDistributor.fundId,
            });

            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Exceção cadastrada com sucesso"
                }))
                setAddingClientFundDistributor(false);
                loadClientFundDistributors(newClientFundDistributor.fundId);
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Falha ao cadastrar exceção"
                }))
            }
        }

        setOpenBackdrop(false);

    }

    async function doDeleteClientFundDistributor(row) {

        const response = await DistributorsAPI.deleteClientFundDistributor(row.id);
        if (response.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Exceção removida com sucesso."
            }))
            loadClientFundDistributors(newClientFundDistributor.fundId);
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao remover exceção."
            }))

        }

    }

    function checkCanUpdateCotas() {

        const showingQuotas = fundLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]];
        console.log("showingQuotas: ", showingQuotas);

        let canUpdate = true;
        showingQuotas.forEach(element => {
            const keys = Object.keys(element);
            keys.forEach(key => {
                if (key.includes('valor_cota_')) {
                    console.log('key: ', key);
                    canUpdate = false;
                }
            });
        });

        return canUpdate;
    }

    function doConfirmUpdateTickerFundQuotas() {
        setConfirmUpdateTickerFundQuotas(false);
        const code = getCodeToComdinheiroQuotas(content.data);
        updateAllQuotas(
            parseInt(monthsInYearQuotas[monthSelected]),
            parseInt(yearsQuotas[yearSelected]),
            content.data.id,
            code, true
        );
    }

    function doConfirmUpdateAllQuotas() {
        setConfirmUpdateAllQuotas(false);

        if (
            //isDevENV || 
            checkCanUpdateCotas()) {

            const code = getCodeToComdinheiroQuotas(content.data);
            //const code = content.data.cnpj;
            updateAllQuotas(
                parseInt(monthsInYearQuotas[monthSelected]),
                parseInt(yearsQuotas[yearSelected]),
                content.data.id,
                code,
                content.data.ticker
            );
        } else if (content.data.ticker) {
            console.log("TICKER FUND");
            setConfirmUpdateTickerFundQuotas(true);
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Não é possível atualizar as cotas pois existem cotas específicas cadastradas para clientes"
            }));
        }


    }

    function cancelConfirmUpdateAllInfos() {
        setConfirmUpdateAllInfos(false);
        setOpenBackdrop(false);
    }

    async function doConfirmUpdateAllInfos(fund) {

        setOpenBackdrop(true);
        setMessageBackdrop('Atualizando informações...');

        const reqMonth = monthsInYearQuotas[monthSelected];
        const reqYear = yearsQuotas[yearSelected];
        setConfirmUpdateAllInfos(false);
        console.log("REQ MONTH: ", reqMonth);
        console.log("REQ YEAR: ", reqYear);
        const response = await getInfosByListCnpjs([fund.cnpj], getFirstBeforeBusinessDay(moment.utc(getLastDayMonth(reqMonth, reqYear)).format('DD/MM/YYYY')));

        const responseUpdate = await updateInfosLocalQuotasByFundId(fund.id, response.infos[fund.cnpj], reqMonth, reqYear);
        if (responseUpdate.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Informações atualizadas com sucesso!"
            }))
            loadLocalQuotas(fund.id)
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Falha ao atualizar informações!"
            }))
            setOpenBackdrop(false);
            setMessageBackdrop('');
        }

    }

    function formatJsonQuotasToSave(newQuota, jsonQuotas) {

        console.log("New cota: ", newQuota);
        console.log("json: ", jsonQuotas);

        //verificar se já existe cota salva para essa data
        let alreadyQuota = false;
        //saber se a cota já foi adicionada
        let alreadyAdded = false;

        let newJsonQuotas = [];
        jsonQuotas.forEach(quota => {

            let dayQuota = quota.data_referencia.split('-')[2];
            let dayNewQuota = newQuota.data_referencia.split('-')[2];

            if (dayQuota != dayNewQuota && !alreadyAdded) {

                if (dayNewQuota < dayQuota) {
                    alreadyAdded = true;
                    newJsonQuotas.push(newQuota);
                }

            }

            if (quota.data_referencia == newQuota.data_referencia) {
                //existe cota
                alreadyQuota = true;

            }

            newJsonQuotas.push(quota);
        });

        if (alreadyQuota) {

            setEdittingQuota(false);
            setOpenBackdrop(false);
            setConfirmOverrideQuota(true);

            //Guardando informações para serem usadas caso confirme-se a mudança de valores
            setNewJsonQuotas({
                month: parseInt(monthsInYearQuotas[monthSelected]),
                year: yearsQuotas[yearSelected],
                jsonQuotas: newJsonQuotas,
            });

        } else {

            //Caso entre aqui é porque a quota é em um dia depois do último dia que já existia localmente
            //Adiciona-se ao fim da lista para que seja salva
            if (!alreadyAdded) {
                newJsonQuotas.push(newQuota);
            }

            console.log("newJsonQuotas: ", newJsonQuotas);
            return newJsonQuotas;

        }

    }

    function formatJsonQuotasToUpdate(newQuota, jsonQuotas) {

        console.log("New cota: ", newQuota);
        console.log("json: ", jsonQuotas);

        let newJsonQuotas = [];
        jsonQuotas.forEach(quota => {

            if (quota.data_referencia == newQuota.data_referencia) {

                //trzendo os dados atualziados da tela
                quota = {};
                quota = { ...newQuota }
                console.log("QUOTA: ", quota);
                // quota.valor_cota = newQuota.valor_cota;
                // quota.patrimonio_liquido = newQuota.patrimonio_liquido;
                newJsonQuotas.push(quota);

            } else {

                newJsonQuotas.push(quota);

            }


        });

        console.log('JSON TO SAVE: ', _.cloneDeep(newJsonQuotas))
        return newJsonQuotas;

    }

    function formatJsonQuotasToRemove(dateToRemove, jsonQuotas) {


        let newJsonQuotas = [];
        jsonQuotas.forEach(quota => {

            if (quota.data_referencia != dateToRemove) {
                newJsonQuotas.push(quota);
            }

        });

        return newJsonQuotas;
    }

    const [submmitedEditQuota, setSubmittedEditQuota] = useState(false);

    function doEdittingQuota() {

        setEdittingOldQuota(false);
        //Inicializando picker de data com o mês e anos que estão selecionados
        console.log("monthSelected: ", monthSelected);
        console.log("yearSelected: ", yearSelected);
        let firstDay = null;
        if (yearSelected != null
            && monthSelected != null
            && yearsQuotas != null && yearsQuotas[yearSelected] != null
            && monthsInYearQuotas != null && monthsInYearQuotas[monthSelected] != null) {

            firstDay = new Date(parseInt(yearsQuotas[yearSelected]), parseInt(monthsInYearQuotas[monthSelected]) - 1, 1);

        } else {

            firstDay = new Date();

        }

        console.log("firstDay: ", firstDay);
        setInputDateNewQuota(firstDay);

        setInputValueNewQuota("");
        setInputValueNewPlQuota("");

        setMultipleInputsClientQuotas({
            focusOnId: '',
            items: [],
        });

        setEdittingQuota(true);
    }

    function getMultipleClientQuotasByRow(row, localAllClients) {

        const items = [];
        if (row) {
            Object.entries(row).map(([key, valueQuota]) => {

                console.log('key: ', key)
                if (key.includes('valor_cota_')) {

                    const clientId = parseInt(key.substring(11));
                    console.log('clientId: ', clientId);

                    const client = localAllClients.find(el => el.id == clientId);
                    items.push({
                        clientQuota: client,
                        valueQuota: parseCurrencyByFloat(valueQuota),
                    })

                }
            })
        }

        return {
            focusOnId: '',
            items: items,
        }

    }

    async function editSingleQuota(row) {

        console.log("EDITTING SINGLE COTA: ", row);

        let localAllClients = [];
        if (allClients == null) {

            localAllClients = await loadAllClients();

        } else {
            localAllClients = allClients;
        }

        setEdittingOldQuota(true);
        let piecesDate = row.data_referencia.split('-');
        setInputDateNewQuota(new Date(parseInt(piecesDate[0]), parseInt(piecesDate[1]) - 1, piecesDate[2]));
        setInputValueNewQuota(row.valor_cota ? parseCurrencyByFloat(row.valor_cota) : '');
        setInputValueNewPlQuota(applyMaskAmountFreeDecimals(row.patrimonio_liquido, false, 10));

        if (row && localAllClients && localAllClients.length > 0) {

            setMultipleInputsClientQuotas(getMultipleClientQuotasByRow(row, localAllClients));
        }
        setEdittingQuota(true);

    }

    function formatDateToKey(formattedDate) {

        let pieces = formattedDate.substring(3).split('/');
        let keyToJson = parseInt(pieces[0]) + "/" + pieces[1];
        console.log("Date: ", keyToJson);

        return keyToJson;
    }

    function prepareToRemoveQuota() {

        console.log("Remove cot");
        setConfirmRemoveQuota(true);

    }

    function updatePreJsonQuotasWithMultipleQuotasClient(preJsonToSave) {
        if (multipleInputsClientQuotas
            && multipleInputsClientQuotas.items
            && multipleInputsClientQuotas.items.length > 0) {

            multipleInputsClientQuotas.items.forEach(item => {
                //verificando se os campos foram informados
                if (item.valueQuota != '' && item.clientQuota != null) {

                    preJsonToSave['valor_cota_' + item.clientQuota.id] = parseFloatByPtCurrency(item.valueQuota);
                }
            });

            return true;

        }

        return false;
    }

    function doUpdateOneQuota(date, value, pl) {
        setSubmittedEditQuota(true);

        let formattedDate = moment(date).format('DD/MM/YYYY');
        let keyToJson = formatDateToKey(formattedDate);

        if (value != null && value != "" && fundLocalQuotas[keyToJson]) {

            setOpenBackdrop(true);
            setMessageBackdrop("Atualizando cota...");

            let anbimaDate = formatPtStringDateToAnbimaDate(formattedDate);

            let preJsonToSave = {
                data_referencia: anbimaDate,
                valor_cota: parseFloatByPtCurrency(value),
                patrimonio_liquido: parseFloatByPtCurrency(pl),
                usuario_id: currentUser.user.id,
            };


            //Verificar salvar cotas para clientes
            const withClientsQuota = updatePreJsonQuotasWithMultipleQuotasClient(preJsonToSave);

            console.log("PRE JSON TO SAVE: ", preJsonToSave);

            let jsonToSave = formatJsonQuotasToUpdate(preJsonToSave, fundLocalQuotas[keyToJson]);

            console.log("JSON TO SAVE: ", jsonToSave);

            if (jsonToSave) {

                //Salvar json
                saveNewQuota({
                    month: parseInt(keyToJson.split('/')[0]),
                    year: parseInt(keyToJson.split('/')[1]),
                    jsonQuotas: jsonToSave,
                    withClientsQuota: withClientsQuota,
                });
            }

        }
    }

    function doSaveOneQuota(date, value, pl) {

        console.log('multipleInputsClientQuotas: ', multipleInputsClientQuotas);

        setSubmittedEditQuota(true);
        console.log("Date: ", date);
        console.log("value: ", value);
        console.log("pl: ", pl);

        let formattedDate = moment.utc(date).format('DD/MM/YYYY');
        let keyToJson = formatDateToKey(formattedDate);
        //console.log("MARCACAO DE ERRO: ", fundLocalQuotas[keyToJson]);

        //if (value != null && value != "" && fundLocalQuotas[keyToJson]) {
        if (value != null && value != "") {

            setOpenBackdrop(true);
            setMessageBackdrop("Incluindo cota...");

            let anbimaDate = formatPtStringDateToAnbimaDate(formattedDate);

            let preJsonToSave = {
                data_referencia: anbimaDate,
                valor_cota: parseFloatByPtCurrency(value),
                patrimonio_liquido: pl != '' && pl != null ? parseFloatByPtCurrency(pl) : 0,
                usuario_id: currentUser.user.id,
            }

            //Verificar salvar cotas para clientes
            updatePreJsonQuotasWithMultipleQuotasClient(preJsonToSave);

            let jsonToSave = formatJsonQuotasToSave(preJsonToSave,
                fundLocalQuotas && fundLocalQuotas[keyToJson] ? fundLocalQuotas[keyToJson] : []);

            console.log("JSON TO SAVE: ", jsonToSave);

            if (jsonToSave) {
                //Salvar json
                saveNewQuota({
                    month: parseInt(keyToJson.split('/')[0]),
                    year: parseInt(keyToJson.split('/')[1]),
                    jsonQuotas: jsonToSave,
                    //Caso ainda não tenha cota salva. deixa-se passar o save de uma primeira cota
                    withClientsQuota: fundLocalQuotas && fundLocalQuotas[keyToJson] && fundLocalQuotas[keyToJson].length == 0 ? true : false
                });
            }

        }

    }

    async function saveNewQuota(finalToSave) {

        setEdittingQuota(false);
        setOpenBackdrop(true);
        setMessageBackdrop('Incluindo cota...');

        //modelo requsitado pelo método doSaveOrUpdateLocalQuotas        

        let date = new Date(finalToSave.year, finalToSave.month - 1, 1);

        console.log("DATE QUOTAS: ", date);


        let response = await doSaveOrUpdateLocalQuotas(content.data.id, finalToSave.jsonQuotas, date, finalToSave.month, finalToSave.year, finalToSave.withClientsQuota)
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: 'Cotas locais atualizadas com sucesso',
                type: 'success'
            }))

            loadLocalQuotas(content.data.id);


        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: response.body.message ? response.body.message : 'Falha ao atualizar cotas locais',
                type: 'error'
            }))
            setOpenBackdrop(false);
            setMessageBackdrop('');
        }

        setSubmittedEditQuota(false);

    }

    async function removeQuota() {

        setEdittingQuota(false);
        setOpenBackdrop(true);
        setMessageBackdrop('Removendo cota...');


        let formattedDate = moment(inputDateNewQuota).format('DD/MM/YYYY');
        let keyToJson = formatDateToKey(formattedDate);

        let newJsonToSave = formatJsonQuotasToRemove(
            formatPtStringDateToAnbimaDate(formattedDate),
            fundLocalQuotas[keyToJson]
        );

        let month = parseInt(monthsInYearQuotas[monthSelected]);
        let year = parseInt(yearsQuotas[yearSelected]);
        var date = new Date(year, parseInt(month) - 1, 1);

        let response = await doSaveOrUpdateLocalQuotas(content.data.id, newJsonToSave, date, month, year)
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: 'Cotas locais atualizadas com sucesso',
                type: 'success'
            }))

            loadLocalQuotas(content.data.id);


        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: response.body.message ? response.body.message : 'Falha ao atualizar cotas locais',
                type: 'error'
            }))
            setOpenBackdrop(false);
            setMessageBackdrop('');
        }

        setSubmittedEditQuota(false);

    }

    async function updateAllQuotas(month, year, fundId, cnpj, isTickerFund) {

        setOpenBackdrop(true);
        setMessageBackdrop('Atualizando cotas...');

        let startDate = moment(new Date(year, parseInt(month) - 1, 1)).format("DD/MM/YYYY");
        let endDate = moment(new Date(year, parseInt(month), 0)).format("DD/MM/YYYY");

        let responseGetCotas = await getCotasByListCnpjs([{
            cnpj: cnpj,
            fund_id: fundId,
        }], startDate, endDate);

        if (responseGetCotas.success) {

            //Obteve as cotas             
            let infos = responseGetCotas.cotas[cnpj];
            // infos:
            // {
            //     fund_id: ?,
            //     serie_hstorica: []
            // }        
            console.log("infos: ", infos);
            if (isTickerFund) {
                //verificar se existe informação de patrimonio a ser mantida
                //pois essa informação não aparece na consulta por ticker
                const currentQuotas = fundLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]];
                console.log("currentQuotas: ", currentQuotas);
                const quotasWithPatrimony = currentQuotas.filter(el => el.patrimonio_liquido);
                if (quotasWithPatrimony.length) {
                    infos.serie_historica.forEach(quota => {

                        const quotaPatrimonyOnDate = quotasWithPatrimony.find(el => el.data_referencia == quota.data_referencia)
                        if (quotaPatrimonyOnDate && !quota.patrimonio_liquido) {
                            quota.patrimonio_liquido = quotaPatrimonyOnDate.patrimonio_liquido;
                        }
                    });
                }
            }

            let reponseUpdateCotas = await doSaveOrUpdateLocalQuotas(infos.fund_id, infos.serie_historica, startDate, month, year);
            if (reponseUpdateCotas.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Cotas atualizadas com sucesso!"
                }))

                loadLocalQuotas(fundId);

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: reponseUpdateCotas.body.message ? reponseUpdateCotas.body.message : "Falha ao atualizar cotas!"
                }))
                setOpenBackdrop(false);
                setMessageBackdrop('');
            }

        } else {

            //Falha ao obter as cotas
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter as cotas da API!"
            }))

        }

        setOpenBackdrop(false);

    }

    const [firstEffect, setFirstEffect] = useState(true);

    useEffect(() => {

        if (firstEffect) {

            setOpenBackdrop(true);

            setFirstEffect(false);
            console.log("CONTENT: ", content);

            setTimeout(() => {
                loadInfos(content.data, content.update);
            }, 500)
        }

        if (multipleInputsClientQuotas && multipleInputsClientQuotas.items && multipleInputsClientQuotas.items.length > 0) {

            //console.log('multipleInputsClientQuotas: ', multipleInputsClientQuotas);
            const domElement = document.getElementById(multipleInputsClientQuotas.focusOnId);
            if (domElement && !multipleInputsClientQuotas.focusOnId.includes('clientQuota')) {
                //console.log('domElement: ', domElement);
                domElement.focus();
            }

        }


    }, [multipleInputsClientQuotas])

    return (
        <div className={classes.root}>

            {
                !confirmingAction
                    && !confirmOverrideQuota
                    && !confirmRemoveQuota
                    && !confirmUpdateAllQuotas
                    && !confirmUpdateAllInfos
                    && !confirmUpdateTickerFundQuotas
                    ?
                    <>
                        <Paper style={{ height: '72px' }}>
                            <div className={classes.header}>

                                <Paper square className={classes.tabsAsset} elevation={0}>
                                    <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                                        <MyTabs
                                            value={tabValue}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={handleChangeTabValue}
                                        >

                                            <MyTab label={'Detalhes'} value={0} />
                                            {
                                                content && content.data && content.data.id ?
                                                    <MyTab label={"Cotas"} value={1} onClick={() => !fundLocalQuotas ? loadLocalQuotas(content.data.id) : null} />
                                                    : null
                                            }
                                            {
                                                content && content.data && content.data.id ?
                                                    <MyTab label={"Infos"} value={2} onClick={() => !fundLocalQuotas ? loadLocalQuotas(content.data.id) : null} />
                                                    : null
                                            }


                                            <MyTab label={'Dist. por Cliente'} value={3} onClick={() => loadClientFundDistributors(content.data.id)} />

                                        </MyTabs>


                                    </div>
                                </Paper>

                                <IconButton className="closeIcon" onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                    <CloseIcon />
                                </IconButton>

                            </div>
                        </Paper>
                        {
                            tabValue == 0 &&
                            <div className={classes.main}>


                                <Grid container spacing={2}>

                                    <Grid item xs={12} lg={9} md={9}>

                                        <MyTextField
                                            labelclassname={classes.noMarginTop}
                                            value={inputNameFund}
                                            // defaultValue={inputAssetArticle}
                                            onChange={(value) => setInputNameFund(value)}
                                            variant="outlined"
                                            margin="dense"
                                            outerlabel={'Nome do fundo'}
                                            type="text"
                                            validator={inputNameFund == "" && submitted}
                                            placeholder="Insira o nome do fundo"
                                            helperText="O nome do fundo deve ser preenchido"
                                            fullWidth />
                                    </Grid>

                                    <Grid item xs={12} lg={3} md={3}>

                                        <MyTextField
                                            labelclassname={classes.noMarginTop}
                                            value={inputTickerFund}
                                            // defaultValue={inputAssetArticle}
                                            onChange={(value) => setInputTickerFund(value)}
                                            outerlabel={'Ticker'}
                                            placeholder=""
                                            fullWidth />
                                    </Grid>



                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <MyAutoComplete
                                            onChange={(event, newValue) => {
                                                setInputDistributorFund(newValue);
                                            }}
                                            options={distributors}
                                            autoComplete
                                            outerlabel={"Distribuidor do fundo"}
                                            label={'Distribuidor do fundo'}
                                            filterOptions={(options, state) => {
                                                if (!options) return [];
                                                return options.filter(element => element.cnpj
                                                    .includes(state.inputValue)
                                                    || element.name.toLowerCase().includes(state.inputValue.toLowerCase()));
                                            }}
                                            value={inputDistributorFund ? inputDistributorFund : null}
                                            getOptionLabel={(option) => option.name}
                                            validator={inputDistributorFund == null && submitted ? true : false}
                                            helperText={"Informe o distribuidor do fundo"}
                                            renderOption={(option) => (
                                                <React.Fragment key={'autocomplete-distributor-fund-' + option.id}>
                                                    <span className={classes.bold}>{option.name}</span>
                                                </React.Fragment>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                        <MyTextField
                                            labelclassname={classes.noMarginTop}
                                            value={inputCnpjFund}
                                            onChange={(value) => setInputCnpjFund(value)}
                                            variant="outlined"
                                            margin="dense"
                                            outerlabel={'CNPJ'}
                                            placeholder="Insira o CNPJ"
                                            validator={!isValidCnpj(inputCnpjFund) && submitted}
                                            helperText="Informe um CNPJ válido"
                                            mask={["cnpj"]}
                                            type="text"
                                            fullWidth />
                                    </Grid>


                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                        <MyDatePicker
                                            //className={classes.myDatePicker}
                                            defaultValue={null}
                                            margin="dense"
                                            outerlabel="Início do fundo"
                                            format="DD/MM/YYYY"
                                            value={inputInitDateFund}
                                            onChange={(value) => setInputInitDateFund(value)}
                                            KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                        <MyDatePicker
                                            //className={classes.myDatePicker}
                                            defaultValue={null}
                                            margin="dense"
                                            outerlabel="Fim do fundo"
                                            format="DD/MM/YYYY"
                                            value={inputEndDateFund}
                                            onChange={(value) => setInputEndDateFund(value)}
                                            KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={12} xs={12}>
                                        <MySwitch
                                            outerlabel={'Especial?'}
                                            checked={inputSpecialFund}
                                            onChange={changeSpecialFund}
                                            color="primary" />
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={12} xs={12}>
                                        <MySwitch
                                            outerlabel={'S/INFOS?'}
                                            checked={inputNoInfosFund}
                                            onChange={changeNoInfosFund}
                                            color="primary" />
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={12} xs={12}>
                                        <MySwitch
                                            outerlabel={'POUP?'}
                                            checked={inputPoupFund}
                                            onChange={changePoupFund}
                                            color="primary" />
                                    </Grid>

                                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>

                                            <MyTextField
                                                disabled={inputSpecialFund}
                                                labelclassname={classes.noMarginTop}
                                                value={inputAnbimaCod}
                                                onChange={(value) => setInputAnbimaCod(value)}
                                                variant="outlined"
                                                margin="dense"
                                                outerlabel={'Cód. Anbima'}
                                                placeholder="Insira o código"
                                                type="text"
                                                fullWidth />
                                        </Grid> */}

                                    <Grid item lg={6} md={6} sm={12} xs={12}>

                                        <MySelect
                                            initblank={!inputLocalBenchmarkFund}
                                            outerlabel={'Benchmark'}
                                            value={inputLocalBenchmarkFund}
                                            onChange={handleLocalBenchmarkFund}
                                            options={localBenchmarks.map((row, index) => (
                                                <option key={'benchmark-select-' + index} value={index}>{row.name}</option>
                                            ))}
                                            helperText={"Insira o benchmark"}
                                            validator={inputLocalBenchmarkFund == undefined && submitted ? true : undefined}
                                        />

                                        {/* <MyTextField
                                                labelclassname={classes.noMarginTop}
                                                value={inputBenchmarkFund}
                                                onChange={(value) => setInputBenchmarkFund(value)}
                                                variant="outlined"
                                                margin="dense"
                                                outerlabel={'Benchmark'}
                                                placeholder="Insira o benchmark"
                                                type="text"
                                                fullWidth /> */}
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                        <MyTextField
                                            labelclassname={classes.noMarginTop}
                                            value={inputLiquidityFund}
                                            onChange={(value) => setInputLiquidityFund(value)}
                                            outerlabel={'LIQUIDEZ (Ex: D+3)'}
                                            validator={inputLackLiquidityDateFund
                                                && inputLiquidityFund === ""
                                                && submitted ? true : undefined}
                                            placeholder="Insira a liquidez"
                                            helperText={"Informe a liquidez"}
                                            fullWidth />
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                        <MyDatePicker
                                            //className={classes.myDatePicker}
                                            defaultValue={null}
                                            margin="dense"
                                            outerlabel="Carência(Liquidez)"
                                            format="DD/MM/YYYY"
                                            value={inputLackLiquidityDateFund}
                                            onChange={(value) => setInputLackLiquidityDateFund(value)}
                                            KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                        <MySelect
                                            outerlabel={'Sufixo'}
                                            value={inputSuffixFund}
                                            onChange={handleSuffixFund}
                                            options={suffixesComdinheiro.map((row, index) => (
                                                <option key={'suffix-select-' + index} value={index}>{row}</option>
                                            ))}
                                        />

                                    </Grid>


                                    {/* Versão 1 */}

                                    {/* <Grid item lg={5} md={5} sm={12} xs={12}>
                                        <MySelect
                                            initblank
                                            outerlabel={'Seg. da legislação (3.922/2010)'}
                                            value={inputLegislationSegmentFundV1}
                                            onChange={handleLegislationSegmentFundV1}
                                            options={segmentsV1.map((row, index) => (
                                                <option key={'segment-select-v1' + index} value={index}>{row.description}</option>
                                            ))}
                                            helperText={"Insira o segmento (3.922/2010)"}
                                            validator={inputLegislationSegmentFundV1 == undefined && submitted ? true : undefined}
                                        />
                                    </Grid>

                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                        <MySelect
                                            initblank
                                            outerlabel={'Art. de resolução (3.922/2010)'}
                                            value={inputArticleFundV1}
                                            disabled={inputLegislationSegmentFundV1 == undefined || disableArticleV1}
                                            onChange={(event) => setInputArticleFundV1(event.target.value)}
                                            options={articlesV1.map((row, index) => (
                                                <option key={'article-select-v1' + index} value={index}>{row.device}</option>
                                            ))}
                                            helperText={"Insira o artigo (3.922/2010)"}
                                            validator={inputArticleFundV1 == undefined && submitted ? true : undefined}
                                        />
                                    </Grid> */}

                                    {/* Versão 2 */}

                                    {/* <Grid item lg={5} md={5} sm={12} xs={12}>
                                        <MySelect
                                            initblank
                                            outerlabel={'Seg. da legislação (4.963/2021)'}
                                            value={inputLegislationSegmentFundV2}
                                            onChange={handleLegislationSegmentFundV2}
                                            options={segmentsV2.map((row, index) => (
                                                <option key={'segment-select-v2' + index} value={index}>{row.description}</option>
                                            ))}
                                            helperText={"Insira o segmento (4.963/2021)"}
                                            validator={inputLegislationSegmentFundV2 == undefined && submitted ? true : undefined}
                                        />
                                    </Grid>

                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                        <MySelect
                                            initblank
                                            outerlabel={'Art. de resolução (4.963/2021)'}
                                            value={inputArticleFundV2}
                                            disabled={inputLegislationSegmentFundV2 == undefined || disableArticleV2}
                                            onChange={(event) => setInputArticleFundV2(event.target.value)}
                                            options={articlesV2.map((row, index) => (
                                                <option key={'article-select-v2' + index} value={index}>{row.device}</option>
                                            ))}
                                            helperText={"Insira o artigo (4.963/2021)"}
                                            validator={inputArticleFundV2 == undefined && submitted ? true : undefined}
                                        />
                                    </Grid> */}

                                    {/* Enquadramentos por período */}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FundArticlesWrapper
                                            fundId={content?.data?.id}
                                            articlesNodesCallback={(articles) => setPeriodArticles(articles)}
                                            submitted={submitted}
                                            validatedMessages={validatedMessagesPeriodArticles} />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <MyTextField
                                            labelclassname={classes.noMarginTop}
                                            value={inputDescriptionFund}
                                            onChange={(value) => setInputDescriptionFund(value)}
                                            variant="outlined"
                                            multiline={true}
                                            rows={8}
                                            rowsMax={8}
                                            margin="dense"
                                            outerlabel={'Resumo do fundo'}
                                            placeholder="Insira o resumo do fundo"
                                            type="text"
                                            fullWidth />
                                    </Grid>


                                </Grid>

                                <br />
                                <div className={classes.bottom}>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <UnoButton
                                            className={
                                                !content.update ? classes.hiddenIcon : null}
                                            onClick={() => setConfirmingAction(true)}
                                            type='errorClean'>
                                            {"Excluir fundo"}
                                        </UnoButton>
                                        {/* {
                                                !searchedAnbimaCod ?
                                                    <UnoButton
                                                        style={{ marginLeft: '16px' }}
                                                        className={
                                                            !content.update ? classes.hiddenIcon : null}
                                                        onClick={() => searchAnbimaCod()}
                                                        type='primaryClean'>
                                                        {"Buscar ANBIMA COD"}
                                                    </UnoButton>
                                                    :
                                                    <>
                                                        {
                                                            searchedAnbimaCod == "NOT_FOUND" ?
                                                                <Typography style={{ marginLeft: '16px', color: unoTheme.mainColor }}>
                                                                    {"Código não encontrado"}
                                                                </Typography>
                                                                :
                                                                <Typography style={{ marginLeft: '16px', color: unoTheme.mainColor }}>
                                                                    {'Código: ' + searchedAnbimaCod}
                                                                </Typography>
                                                        }

                                                    </>

                                            } */}

                                    </div>



                                    <UnoButton
                                        type={
                                            content && content.update ? 'success' : 'primary'
                                        }
                                        float={'right'}
                                        onClick={(e) => doSaveFund(e)}>
                                        {
                                            content && content.update ? 'Atualizar fundo' : 'Salvar fundo'
                                        }
                                    </UnoButton>

                                </div>


                            </div>
                        }


                        {
                            tabValue == 1 &&
                            <div className={[classes.main, classes.mainQuotas].join(' ')}>

                                {
                                    fundLocalQuotas ?
                                        <div className="topQuotas">

                                            <Typography variant={'body1'}>
                                                {'Selecione o mês e o ano >'}
                                            </Typography>

                                            <div>
                                                <MySelect
                                                    style={{ marginLeft: '16px', marginRight: '16px' }}
                                                    value={monthSelected}
                                                    onChange={(e) => handleChangeMonthQuotas(e.target.value)}
                                                    options={monthsInYearQuotas.map((row, index) => (
                                                        <option key={'month-local-quota-' + index} value={index}>{getMonthName(row)}</option>
                                                    ))}
                                                />
                                                <MySelect
                                                    value={yearSelected}
                                                    onChange={(e) => handleChangeYearQuotas(e.target.value)}
                                                    options={yearsQuotas.map((row, index) => (
                                                        <option key={'year-local-quota-' + index} value={index}>{row}</option>
                                                    ))}
                                                />
                                            </div>
                                        </div> : null
                                }

                                {
                                    fundLocalQuotas ?

                                        <MyTable
                                            stickyHeader
                                            size="small"
                                            selectable="true">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Data</TableCell>
                                                    <TableCell align="right">Valor Cota</TableCell>
                                                    <TableCell align="right">PL</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {fundLocalQuotas
                                                    && fundLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]]
                                                    ? fundLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]].map((row, index) => (

                                                        <TableRow key={"row-quota-local-index-" + index} onClick={() => editSingleQuota(row)}>
                                                            <TableCell align="left">{formatAnbimaDateToPt(row['data_referencia'])}</TableCell>
                                                            <TableCell align="right" style={{ color: unoTheme.greenMoney }}>{applyMaskAmountFreeDecimals(row['valor_cota'], true, 10)}</TableCell>
                                                            <TableCell align="right">{row['patrimonio_liquido'] ? applyMaskAmount(row['patrimonio_liquido'], true) : '-'}</TableCell>
                                                        </TableRow>

                                                    )) : null}
                                            </TableBody>

                                        </MyTable>

                                        :

                                        <Typography variant={'body1'} style={{ padding: '32px', textAlign: 'center' }}>
                                            {'Nenhuma cota local foi salva para esse fundo até agora'}
                                        </Typography>
                                }



                                <div class="bottom-quotas">

                                    {
                                        fundLocalQuotas ?
                                            <UnoButton
                                                size='small'
                                                type={'success'}
                                                float={'right'}
                                                onClick={() => setConfirmUpdateAllQuotas(true)}>{'Atualizar todas'}
                                            </UnoButton> : null
                                    }



                                    <UnoButton
                                        className={classes.spacingRight2}
                                        size='small'
                                        type={'primary'}
                                        float={'right'}
                                        startIcon={<AddIcon />}
                                        onClick={() => doEdittingQuota()}>{'Adicionar cota'}
                                    </UnoButton>
                                </div>



                                {
                                    edittingQuota ?

                                        <div class='edit-quota'>
                                            <Grid container spacing={2}>
                                                <Grid item lg={5} md={5} sm={5}>
                                                    <MyDatePicker
                                                        disabled={edittingOldQuota}
                                                        className={classes.myDatePicker}
                                                        margin="dense"
                                                        outerlabel="Data"
                                                        format="DD/MM/YYYY"
                                                        value={inputDateNewQuota}
                                                        onChange={(value) => setInputDateNewQuota(value)} />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3}>
                                                    <MyTextField
                                                        value={inputValueNewQuota}
                                                        onChange={(value) => setInputValueNewQuota(value)}
                                                        variant="outlined"
                                                        margin="dense"
                                                        align="right"
                                                        outerlabel={'Valor da cota'}
                                                        placeholder="Ex: 2,345343"
                                                        validator={inputValueNewQuota == "" && submmitedEditQuota ? true : undefined}
                                                        helperText={"Insira um valor da cota"}
                                                        type="text"
                                                        fullWidth />
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4}>
                                                    <MyTextField
                                                        value={inputValueNewPlQuota}
                                                        onChange={(value) => setInputValueNewPlQuota(value)}
                                                        variant="outlined"
                                                        margin="dense"
                                                        align="right"
                                                        outerlabel={'Patrimônio líquido'}
                                                        placeholder="0.00"
                                                        mask={['currency']}
                                                        type="text"
                                                        fullWidth />
                                                </Grid>
                                            </Grid>

                                            {multipleInputsClientQuotas && multipleInputsClientQuotas.items && multipleInputsClientQuotas.items.map((row, index) => (
                                                <InputClientQuota row={row} index={index} />
                                            ))}

                                            <div class='buttons-edit'>

                                                <UnoButton
                                                    style={{ marginTop: '16px' }}
                                                    size='small'
                                                    onClick={() => doCancelEdittingQuota()}>{'cancelar'}
                                                </UnoButton>

                                                <div class='main-actions'>
                                                    <UnoButton
                                                        style={{ marginTop: '16px', marginRight: '16px' }}
                                                        size='small'
                                                        type={'warning'}
                                                        startIcon={<AddIcon />}
                                                        onClick={() => addInputClientQuota()}>{'cota para cliente'}
                                                    </UnoButton>

                                                    {
                                                        edittingOldQuota ?

                                                            <UnoButton
                                                                style={{ marginTop: '16px' }}
                                                                size='small'
                                                                type={'success'}
                                                                onClick={() => doUpdateOneQuota(inputDateNewQuota, inputValueNewQuota, inputValueNewPlQuota)}>{'Atualizar cota'}
                                                            </UnoButton>

                                                            :

                                                            <UnoButton
                                                                style={{ marginTop: '16px' }}
                                                                size='small'
                                                                type={'primary'}
                                                                onClick={() => doSaveOneQuota(inputDateNewQuota, inputValueNewQuota, inputValueNewPlQuota)}>{'Salvar cota'}
                                                            </UnoButton>

                                                    }
                                                </div>

                                            </div>

                                            {
                                                edittingOldQuota ?

                                                    <UnoButton
                                                        style={{ marginTop: '16px', width: '100%' }}
                                                        size='small'
                                                        type={'error'}
                                                        onClick={() => prepareToRemoveQuota()}>{'Excluir cota'}
                                                    </UnoButton> : null
                                            }
                                        </div>

                                        : null
                                }


                            </div>

                        }

                        {
                            tabValue == 2 &&

                            <div className={[classes.main, classes.mainQuotas].join(' ')}>

                                {
                                    fundLocalQuotas ?
                                        <div className="topQuotas">

                                            <Typography variant={'body1'}>
                                                {'Selecione o mês e o ano >'}
                                            </Typography>

                                            <div>
                                                <MySelect
                                                    style={{ marginLeft: '16px', marginRight: '16px' }}
                                                    value={monthSelected}
                                                    onChange={(e) => handleChangeMonthQuotas(e.target.value)}
                                                    options={monthsInYearQuotas.map((row, index) => (
                                                        <option key={'month-local-info-' + index} value={index}>{getMonthName(row)}</option>
                                                    ))}
                                                />
                                                <MySelect
                                                    value={yearSelected}
                                                    onChange={(e) => handleChangeYearQuotas(e.target.value)}
                                                    options={yearsQuotas.map((row, index) => (
                                                        <option key={'year-local-quota-' + index} value={index}>{row}</option>
                                                    ))}
                                                />
                                            </div>
                                        </div> : null
                                }

                                <div className={classes.infosFundContent}>

                                    {
                                        content
                                            && content.data
                                            && fundLocalInfos
                                            && fundLocalInfos[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]] ?

                                            <ComdinheiroInfosFund fund={{
                                                ...content.data,
                                                ...fundLocalInfos[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]],
                                            }}
                                            />
                                            :
                                            <Typography variant='body1'>Sem informações para esse mês</Typography>

                                    }
                                </div>

                                <div class="bottom-quotas">

                                    {
                                        fundLocalQuotas ?
                                            <UnoButton
                                                size='small'
                                                type={'success'}
                                                float={'right'}
                                                onClick={() => setConfirmUpdateAllInfos(true)}>{'Atualizar informações'}
                                            </UnoButton> : null
                                    }

                                </div>

                            </div>
                        }


                        {/* Distribuidores por cliente */}
                        {
                            tabValue == 3 &&
                            <div className={[classes.main, classes.mainQuotas].join(' ')}>

                                {
                                    clientFundDistributors.length ?

                                        <MyTable
                                            selectable="true"
                                            stickyHeader
                                            size="small">
                                            <colgroup>
                                                <col width="30%" />
                                                <col width="60%" />
                                                <col width="10%" />
                                            </colgroup>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tableTh} align="left">Cliente</TableCell>
                                                    <TableCell className={classes.tableTh} align="left">Distribuidor</TableCell>
                                                    <TableCell className={classes.tableTh} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {clientFundDistributors.map((row) => (

                                                    <TableRow key={"distributor-fund-cliente-" + row.id} onClick={() => null}>

                                                        <TableCell align="left">{row.client_label_name}</TableCell>
                                                        <TableCell align="left">{row.distributor_name}</TableCell>
                                                        <TableCell align="center" className="withIcons">
                                                            <IconButton onClick={() => doDeleteClientFundDistributor(row)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </MyTable>
                                        : <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>Nenhuma exceção cadastrada</div>
                                }

                                <div class="bottom-quotas">
                                    <UnoButton
                                        className={classes.spacingRight2}
                                        size='small'
                                        type={'primary'}
                                        float={'right'}
                                        startIcon={<AddIcon />}
                                        onClick={() => doAddClientFundDistributor()}>{'Adicionar exceção'}
                                    </UnoButton>
                                </div>

                                {
                                    addingClientFundDistributor ?

                                        <div class='add-client-fund-distributor'>

                                            <InputClientFundDistributor />

                                            <div class='buttons-edit'>

                                                <UnoButton
                                                    style={{ marginTop: '16px' }}
                                                    size='small'
                                                    onClick={() => doCancelAddingClientFundDistributor()}>{'cancelar'}
                                                </UnoButton>

                                                <UnoButton
                                                    type={'primary'}
                                                    style={{ marginLeft: "16px", marginTop: '16px' }}
                                                    size='small'
                                                    onClick={() => doCreateClientFundDistributor()}>{'Criar exceção'}
                                                </UnoButton>


                                            </div>

                                        </div>

                                        : null
                                }
                            </div>

                        }
                    </>

                    :

                    <>
                        {
                            confirmOverrideQuota ?

                                <ConfirmActionRightContent
                                    message={`Já existe cota para esse dia, tem certeza que quer substituí-lo?`}
                                    handleCancel={() => cancelConfirmOverrideQuota()}
                                    handleConfirm={() => doConfirmOverrideQuota()}
                                /> : null

                        }
                        {
                            confirmingAction ?
                                <ConfirmActionRightContent
                                    message={`Tem certeza que quer excluir esse fundo?`}
                                    handleCancel={() => setConfirmingAction(false)}
                                    handleConfirm={() => doDeleteFund()}
                                /> : null
                        }

                        {
                            confirmRemoveQuota ?
                                <ConfirmActionRightContent
                                    message={`Tem certeza que quer excluir essa cota?`}
                                    handleCancel={() => cancelConfirmRemoveCota(false)}
                                    handleConfirm={() => doConfirmRemoveQuota()}
                                /> : null
                        }

                        {
                            confirmUpdateAllQuotas ?

                                <ConfirmActionRightContent
                                    message={`Atualizar todas as cotas de ${getMonthName(monthsInYearQuotas[monthSelected]) + "/" + yearsQuotas[yearSelected]}?`}
                                    handleCancel={() => cancelConfirmUpdateAllQuotas()}
                                    handleConfirm={() => doConfirmUpdateAllQuotas()}
                                /> : null

                        }

                        {
                            confirmUpdateTickerFundQuotas ?

                                <ConfirmActionRightContent
                                    message={`Já existem cotas de clientes para esse fundo, no período ${getMonthName(monthsInYearQuotas[monthSelected]) + "/" + yearsQuotas[yearSelected]}, deseja atualizá-las mesmo assim?
                                    (Os dados de cotas dos clientes serão perdidos)`}
                                    handleCancel={() => cancelConfirmUpdateTickerFundQuotas()}
                                    handleConfirm={() => doConfirmUpdateTickerFundQuotas()}
                                /> : null

                        }

                        {
                            confirmUpdateAllInfos ?

                                <ConfirmActionRightContent
                                    message={`Atualizar as informações de ${getMonthName(monthsInYearQuotas[monthSelected]) + "/" + yearsQuotas[yearSelected]}?`}
                                    handleCancel={() => cancelConfirmUpdateAllInfos()}
                                    handleConfirm={() => doConfirmUpdateAllInfos(content && content.data)}
                                /> : null

                        }

                    </>
            }

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                        {
                            messageBackdrop != "" ?
                                <Typography variant={'body1'} style={{ marginTop: '16px' }}>
                                    {messageBackdrop}
                                </Typography>
                                : null
                        }

                    </div> : null
            }
        </div >
    );
}