import API from './config'

export const REQ_COMDINHEIRO_TYPE = {
    LAMINA: 'lamina',
    RISCO: 'risco',
    DEMONSTRATIVO: 'demonstrativo',
    COLETA_SALDOS: 'coleta_saldos',
    COMPOSICAO_FUNDO: 'composicao_fundo',
    COMPARADOR_FUNDOS: 'comparador_fundos',
    RELATORIO_PROVISORIO: 'relatorio_provisorio',
    CONTABIL: 'contabil',
}

export const ReqsComdinheiroAPI = {


    async logReq(type) {

        let config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post('/reqs_comdinheiro/logReq', {
                type: type,
            }, config)

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            console.log("ERROR");

            return {
                success: false,
                body: error.response ? error.response.data : 'NO_DATA_ERROR'
            };
        }
    }

}

